@import "../../styles/common.scss";

@media (min-width: 320px) {
  .spacer {
    margin-top: 10px;
  }

  .forgotPasswordDiv {
    align-self: flex-end;
  }

  .link {
    color: #fd5334;
  }

  .login-input {
    min-width: 315px !important;
    min-height: 52px !important;
  }

  input[type="password"] {
    box-shadow: none !important;
  }

  .button-primary {
    width: 315px;
    min-height: 52px;
    font-size: 16px;
    border: none;
    padding: 15px 10px;
    background-color: #478bf8;
  }

  .notRegisteredDiv {
    // position: relative;
    // top: 475px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-input-number-group-addon {
    border-radius: 10px 0 0 10px !important;
    box-shadow: 0px 3px 10px #0000000f !important;
    border: none !important;
    color: black !important;
  }

  .form-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include main-body-container-320;
    .login-card-container {
      margin: auto;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      .login-welcome {
        @include airbnbCereal-book;
        font-size: 15px;
        letter-spacing: 1.2px;
        // line-height: 19px;
        color: #7e97b8;
        text-align: center;
        margin: 5px 0;
      }
      .inputContainer {
        width: 100%;
        display: flex;
        margin-top: 10px;
        // margin-bottom: 80px;
        flex-direction: column;
        align-items: center;
        .textInput {
          display: flex;
          width: 100%;
          max-width: 400px;
          padding: 0 35px;
          .ant-input-number-input-wrap {
            width: 100%;
          }
          .ant-input-number-handler-wrap {
            display: none;
          }
          .ant-radio-button-wrapper:hover {
            color: #063e88;
            font-weight: 500;
          }
          .ant-radio-button-wrapper-checked {
            color: white !important;
            background-color: #7e97b8 !important;
            border: #7e97b8 !important;
            z-index: 0;
          }
        }
        .verify-input .OTP-form .otpFields {
          width: 100%;
          margin: 5px;
          border: 2px solid;
          border-radius: 10px;
          border-color: #7e97b8;
          input:focus {
            outline: none;
          }
        }
        label[for="userRegistrationForm_phone"] {
          font-weight: 700 !important;
        }
      }
      .inputContainer {
        margin: 0 auto 35px;
        width: 100%;
        max-width: 630px;
        .ant-form-item {
          margin-bottom: 20px;
        }
        .phoneFormat {
          margin: 0;
          margin: -20px 5px 0px 5px;
          font-size: 12px;
        }
        .textInput {
          width: 100%;
          max-width: 600px;

          label,
          .ant-input-number,
          .ant-select-selector,
          input {
            display: inline-flex;
            align-items: center;
            height: 40px;
            width: 100%;
            border-radius: $base-border-radius-400;
            @include airbnbCereal-book;
            color: $black-primary;
            font-size: 12px;
            letter-spacing: 1.2px;
            line-height: 16px;

            &::placeholder {
              @include airbnbCereal-book;
              color: $gray-dark;
              font-size: 12px;
            }
          }

          .user-registration-select {
            @include airbnbCereal-bold;
            font-size: 12px;
            letter-spacing: 1.2px;
            line-height: 16px;

            .user-registration-select__control {
              border-radius: $base-border-radius-400;
              .user-registration-select__input-container {
                height: 34px;
              }
            }
          }

          .ant-input-number {
            input {
              font-size: 12px;
              color: $black-primary;
            }
          }

          .ant-form-item-control-input-content {
            .ant-radio-group {
              overflow: hidden !important;
              label {
                margin-top: 10px;
                width: calc(25% - 4px);
                height: 40px;
              }
            }
          }
        }
        .verify-heading {
          .regular-text {
            @include airbnbCereal-book;
            height: 40px;
            font-size: 12px;
            letter-spacing: 1px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
          }
        }
        .verify-input {
          width: 90%;
          margin: 0 auto;
          max-width: 600px;
          padding: 0 27px;
          .OTP-form {
            margin: 5px 0 15px 0;
          }
          .verify-now-btn {
            margin: 15px 0 10px 0;
            width: 50%;
          }
        }
      }

      .logo {
        display: flex;
        justify-content: center;
        padding: 5px 0 17px 0;
        img {
          margin-left: auto;
          margin-right: auto;
          width: 40%;
          max-width: 100px;
        }
      }
    }
    .get-opt-btn {
      width: 50%;
      place-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (min-width: 768px) {
  .login-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    @include main-body-container-768;
    .login-card-container {
      // width: 65%;
      margin: auto;
      background-color: white;
      .login-welcome {
        font-size: 17px;
      }

      .logo img {
        width: 25%;
        max-width: 90px;
      }
      .inputContainer {
        margin: 0 auto 35px;
        width: 100%;
        max-width: 630px;

        .ant-form-item {
          margin-bottom: 20px;
        }
        .phoneFormat {
          margin: 0;
          margin: -20px 5px 0px 5px;
          font-size: 12px;
        }
        .textInput {
          width: 100%;
          max-width: 600px;

          label,
          .ant-input-number,
          .ant-select-selector,
          input {
            display: inline-flex;
            align-items: center;
            height: 50px;
            width: 100%;
            border-radius: $base-border-radius-1920;
            @include airbnbCereal-book;
            color: $black-primary;
            font-size: 20px;
            letter-spacing: 2px;
            line-height: 26px;

            &::placeholder {
              @include airbnbCereal-book;
              color: $gray-dark;
              font-size: 20px;
            }
          }

          .user-registration-select {
            @include airbnbCereal-bold;
            font-size: 18px;
            letter-spacing: 2px;
            line-height: 26px;
            .user-registration-select__control {
              border-radius: $base-border-radius-1920;
              .user-registration-select__input-container {
                height: 50px;
              }
            }
          }

          .ant-input-number {
            input {
              font-size: 18px;
              color: $black-primary;
            }
          }

          .ant-form-item-control-input-content {
            .ant-radio-group {
              overflow: hidden !important;
              label {
                margin-top: 10px;
                width: calc(25% - 4px);
                height: 50px;
              }
            }
          }
        }
        .verify-heading {
          .regular-text {
            @include airbnbCereal-book;
            height: 50px;
            font-size: 14px;
            letter-spacing: 1px;
            margin-bottom: 20px;
          }
        }
        .verify-input {
          width: 90%;
          margin: 0 auto;
          max-width: 600px;
          padding: 0 27px;
          .OTP-form {
            margin: 5px 0 15px 0;
          }
          .verify-now-btn {
            margin: 15px 0 27px 0;
            width: 100%;
          }
        }
      }
    }

    .get-opt-btn {
      @include airbnbCereal-book;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 2px;
      height: 50px;
      width: 100% !important;
      border-radius: $base-border-radius-1920;
    }
  }
}

@media (min-width: 1024px) {
  .login-wrapper {
    @include main-body-container-1024;

    .login-card-container {
      width: 100%;
      margin: auto;
      padding: 30px 0;
      .inputContainer {
        margin: 0 auto 35px;
        width: 100%;
        max-width: 630px;

        .textInput {
          width: 100%;
          max-width: 550px;

          label,
          .ant-input-number,
          .ant-select-selector,
          input {
            display: inline-flex;
            align-items: center;
            height: 42px;
            width: 100%;
            border-radius: $base-border-radius-1920;
            @include airbnbCereal-book;
            color: $black-primary;
            font-size: 14px;
            letter-spacing: 2px;
            line-height: 26px;

            &::placeholder {
              @include airbnbCereal-book;
              color: $gray-dark;
              font-size: 14px;
            }
            .ant-select-selection-placeholder {
              @include airbnbCereal-book;
              color: $gray-dark;
              font-size: inherit;
            }
          }

          .user-registration-select {
            @include airbnbCereal-bold;
            font-size: 14px;
            letter-spacing: 2px;
            line-height: 26px;
            .user-registration-select__control {
              .user-registration-select__input-container {
                height: 38px;
              }
            }
          }

          .ant-input-number {
            input {
              font-size: 14px;
              color: $black-primary;
            }
          }

          .ant-form-item-control-input-content {
            .ant-radio-group {
              overflow: hidden;
              label {
                margin-top: 10px;
                width: calc(25% - 4px);
                height: 42px;
              }
            }
          }
        }
        .verify-heading {
          .regular-text {
            height: 42px;
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 27px;
          }
        }
        .verify-input {
          width: 90%;
          margin: 0 auto;
          max-width: 600px;
          padding: 0 47px;
          .OTP-form {
            margin: -10px 0 25px 0;
          }
          .verify-now-btn {
            margin: -8px 0 27px 0;
          }
        }
      }
      .logo img {
        width: 30% !important;
        max-width: 80px;
      }
      .login-welcome {
        font-size: 18px;
      }
    }
    .get-opt-btn {
      font-size: 14px;
    }
  }
}

@media (min-width: 1336px) {
  .login-wrapper {
    .login-card-container {
      .login-welcome {
        font-size: 20px;
      }
      .logo img {
        width: 30% !important;
        max-width: 90px;
      }
      .inputContainer {
        margin: 0 auto 35px;
        width: 100%;
        max-width: 630px;
        .phoneFormat {
          font-size: 15px;
        }
        .textInput {
          width: 100%;
          max-width: 600px;

          label,
          .ant-input-number,
          .ant-select-selector,
          input {
            display: inline-flex;
            align-items: center;
            height: 45px;
            width: 100%;
            border-radius: $base-border-radius-1920;
            @include airbnbCereal-book;
            color: $black-primary;
            font-size: 14px;
            letter-spacing: 2px;
            line-height: 26px;

            &::placeholder {
              @include airbnbCereal-book;
              color: $gray-dark;
              font-size: 14px;
            }
          }

          .user-registration-select {
            @include airbnbCereal-bold;
            font-size: 14px;
            letter-spacing: 2px;
            line-height: 26px;
            .user-registration-select__control {
              .user-registration-select__input-container {
                height: 41px;
              }
            }
          }

          .ant-input-number {
            input {
              font-size: 14px;
              color: $black-primary;
            }
          }

          .ant-form-item-control-input-content {
            .ant-radio-group {
              overflow: hidden;
              label {
                font-size: 14px;
                margin-top: 10px;
                width: calc(25% - 4px);
                height: 45px;
              }
            }
          }
        }

        .verify-heading {
          .regular-text {
            height: 40px;
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 27px;
            display: flex;
            align-items: center;
          }
        }
        .verify-input {
          width: 90%;
          margin: 0 auto;
          max-width: 600px;
          padding: 0 24px;
          .OTP-form {
            margin: 0px 0 15px 0;
          }
          .verify-now-btn {
            margin: 0px 0 27px 0;
          }
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  .login-wrapper {
    @include main-body-container-1440;
    .login-card-container {
      .login-welcome {
        font-size: 25px;
      }
      .logo img {
        width: 30% !important;
        max-width: 110px;
      }
      .inputContainer {
        margin: 0 auto 35px;
        width: 100%;
        max-width: 630px;

        .textInput {
          width: 100%;
          max-width: 550px;

          label,
          .ant-input-number,
          .ant-select-selector,
          input {
            display: inline-flex;
            align-items: center;
            height: 42px;
            width: 100%;
            border-radius: $base-border-radius-1920;
            @include airbnbCereal-book;
            color: $black-primary;
            font-size: 18px;
            letter-spacing: 2px;
            line-height: 26px;

            &::placeholder {
              @include airbnbCereal-book;
              color: $gray-dark;
              font-size: 18px;
            }
          }
          .ant-form-item-control-input-content {
            .ant-radio-group {
              label {
                font-size: 16px;
              }
            }
          }
          .user-registration-select {
            @include airbnbCereal-bold;
            font-size: 16px;
            letter-spacing: 2px;
            line-height: 20px;
            .user-registration-select__control {
              border-radius: $base-border-radius-1920;
              .user-registration-select__input-container {
                height: 34px;
              }
            }
          }

          .ant-input-number {
            input {
              font-size: 16px;
              color: $black-primary;
            }
          }

          .ant-form-item-control-input-content {
            .ant-radio-group {
              overflow: hidden;
              label {
                margin-top: 10px;
                width: calc(25% - 4px);
                height: 45px;
              }
            }
          }
        }
        .verify-heading {
          .regular-text {
            height: 45px;
            font-size: 16px;
            margin-top: 0px;
            margin-bottom: 27px;
          }
        }
        .verify-input {
          width: 90%;
          margin: 0 auto;
          max-width: 600px;
          padding: 0 24px;
          .OTP-form {
            margin: 0px 0 15px 0;
          }
          .verify-now-btn {
            margin: 0px 0 27px 0;
          }
        }
      }
    }
    .get-opt-btn {
      font-size: 16px;
    }
  }
}

@media (min-width: 1920px) {
  .login-wrapper {
    @include main-body-container-1920;
    .login-card-container {
      .login-welcome {
        font-size: 29px;
      }
      .logo img {
        width: 30% !important;
        max-width: 130px;
      }
      .inputContainer {
        margin: 0 auto 15px;
        width: 100%;
        max-width: 630px;
        .login-welcome {
          @include airbnbCereal-book;
          font-size: 29px;
          letter-spacing: 2.32px;
          line-height: 37px;
        }
        .textInput {
          width: 100%;
          max-width: 600px;

          label,
          .ant-input-number,
          .ant-select-selector,
          .ant-select-selector input {
            display: inline-flex;
            align-items: center;
            height: 50px;
            width: 100%;
            border-radius: $base-border-radius-1920;
            @include airbnbCereal-book;
            color: $black-primary;
            font-size: 20px;
            letter-spacing: 2px;
            line-height: 26px;
            &::placeholder {
              @include airbnbCereal-book;
              color: $gray-dark;
              font-size: 20px;
            }
          }
          .ant-form-item-control-input-content {
            .ant-radio-group {
              label {
                font-size: 18px;
              }
            }
          }
          .user-registration-select {
            @include airbnbCereal-bold;
            font-size: 18px;
            letter-spacing: 2px;
            line-height: 26px;
            .user-registration-select__control {
              .user-registration-select__input-container {
                height: 50px;
              }
            }
          }

          .ant-input-number {
            input {
              font-size: 18px;
              color: $black-primary;
            }
          }

          .ant-form-item-control-input-content {
            .ant-radio-group {
              overflow: hidden;
              label {
                margin-top: 10px;
                width: calc(25% - 4px);
                height: 50px;
              }
            }
          }
        }
        .verify-heading {
          .regular-text {
            height: 50px;
            font-size: 20px;
            margin-top: 2px;
            margin-bottom: 25px;
          }
        }
        .verify-input {
          width: 90%;
          margin: 0 auto;
          max-width: 600px;
          padding: 0 24px;
          .OTP-form {
            margin: 2px 0 18px 0;
          }
          .verify-now-btn {
            margin: 7px 0 27px 0;
          }
        }
      }
    }
    .get-opt-btn {
      font-size: 18px;
    }
  }
}

.login-register {
  text-align: "center";
  text-decoration: "underline";
  margin-top: "10px";
  margin-bottom: "0px";
  font-size: "15px";
  color: "#478bf8";
}

.age-form {
  text-align: center;
}

.form-select {
  .css-13cymwt-control {
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    border-bottom: 1px solid #ccc !important;
    border-radius: 0px !important;
  }
}
