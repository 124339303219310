@import "./variables";
@import "./colors";

@font-face {
    font-family: "AirbnbCereal-Bold";
    src: url("../assets/fonts/AirbnbCereal_W_Bd.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "AirbnbCereal-Book";
    src: url("../assets/fonts/AirbnbCereal_W_Bk.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "AirbnbCereal-Black";
    src: url("../assets/fonts/AirbnbCereal_W_Blk.otf") format("opentype");
    font-weight: 900;
}

@font-face {
    font-family: "AirbnbCereal-Light";
    src: url("../assets/fonts/AirbnbCereal_W_Lt.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "AirbnbCereal-Medium";
    src: url("../assets/fonts/AirbnbCereal_W_Md.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "AirbnbCereal-ExtraBold";
    src: url("../assets/fonts/AirbnbCereal_W_XBd.otf") format("opentype");
    font-weight: 800;
}

///////////////////////////////////

@mixin airbnbCereal-bold {
    font-family: "AirbnbCereal-Bold", sans-serif;
    font-weight: 700;
}
@mixin airbnbCereal-book {
    font-family: "AirbnbCereal-Book", sans-serif;
    font-weight: 400;
}
@mixin airbnbCereal-black {
    font-family: "AirbnbCereal-Black", sans-serif;
    font-weight: 900;
}
@mixin airbnbCereal-light {
    font-family: "AirbnbCereal-Light", sans-serif;
    font-weight: 300;
}
@mixin airbnbCereal-medium {
    font-family: "AirbnbCereal-Medium", sans-serif;
    font-weight: 500;
}
@mixin airbnbCereal-extra-bold {
    font-family: "AirbnbCereal-ExtraBold", sans-serif;
    font-weight: 800;
}

///////////////////////////////////

@mixin h1-font-320 {
    // h1 in body
    @include airbnbCereal-bold;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 1.5px;
    color: $black-secondary;
}

@mixin h1-font-768 {
    // h1 in body
    @include h1-font-320;
    font-size: 16px;
}

@mixin h1-font-1024 {
    // h1 in body
    @include airbnbCereal-bold;
    font-size: 17px;
    line-height: 19px;
    letter-spacing: 1.5px;
    color: $black-secondary;
}

@mixin h1-font-1366 {
    // h1 in body
    @include h1-font-1024;
    font-size: 18px;
}

@mixin h1-font-1440 {
    // h1 in body
    @include airbnbCereal-bold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 1.6px;
    color: $black-primary;
}

@mixin h1-font-1920 {
    // h1 in nav bar
    @include h1-font-1440;
}

///////////////////////////////////

@mixin heading-font-320 {
    // headings in modals
    @include airbnbCereal-medium;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 1.44px;
    color: $black-secondary;
}

@mixin primary-heading-font-1920 {
    // headings in modals
    @include airbnbCereal-extra-bold;
    font-size: 1.2rem;
    line-height: 1.55rem;
    letter-spacing: 0.095rem;
    color: $black-secondary;
}

@mixin secondary-heading-font-1920 {
    // headings in modals
    @include airbnbCereal-bold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 1.6px;
}

///////////////////////////////////

@mixin sub-heading-font-320 {
    // landing page "Our Business Partners"
    @include airbnbCereal-medium;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1.12px;
    color: $black-secondary;
}

@mixin sub-heading-font-768 {
    // landing page "Our Business Partners"
    @include airbnbCereal-book;
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 26px;
    color: $black-primary;
}

@mixin sub-heading-font-1024 {
    // landing page "Our Business Partners"
    @include airbnbCereal-book;
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 26px;
    color: $black-primary;
}
@mixin sub-heading-font-1366 {
    // landing page "Our Business Partners"
    @include airbnbCereal-book;
    font-size: 15px;
    letter-spacing: 2px;
    line-height: 26px;
    color: $black-primary;
}
@mixin sub-heading-font-1440 {
    // landing page "Our Business Partners"
    @include airbnbCereal-book;
    font-size: 17px;
    letter-spacing: 2px;
    line-height: 26px;
    color: $black-primary;
}
@mixin sub-heading-font-1920 {
    // landing page "Our Business Partners"
    @include airbnbCereal-book;
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 26px;
    color: $black-primary;
}

///////////////////////////////////

@mixin description-font-320 {
    // landing page org description
    @include airbnbCereal-book;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.96px;
    color: $black-primary;
}

@mixin description-font-768 {
    @include airbnbCereal-book;
    font-size: 10.5px;
    letter-spacing: 0.96px;
    line-height: 14px;
    color: $black-primary;
}
@mixin description-font-1024 {
    @include airbnbCereal-book;
    font-size: 10px;
    letter-spacing: 0.96px;
    line-height: 21px;
    color: $black-primary;
}

@mixin description-font-1366 {
    @include airbnbCereal-book;
    font-size: 12px;
    letter-spacing: 0.96px;
    line-height: 21px;
    color: $black-primary;
}

@mixin description-font-1440 {
    @include airbnbCereal-book;
    font-size: 13px;
    letter-spacing: 0.96px;
    line-height: 21px;
    color: $black-primary;
}

@mixin description-font-1920 {
    @include airbnbCereal-book;
    font-size: 15px;
    letter-spacing: 0.96px;
    line-height: 21px;
    color: $black-primary;
}

///////////////////////////////////
//  font sizes will reduce by 10% from 1920p
// for 768 use 1024p sizes

@mixin image-description-font-320 {
    // landing page org description
    @include airbnbCereal-book;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 0.72px;
    color: $black-primary;
}

@mixin image-description-font-600 {
    @include airbnbCereal-book;
    color: $black-primary;
    font-size: 10.5px;
    line-height: 14.7px;
    letter-spacing: 0.72px;
}

@mixin image-description-font-1024 {
    @include airbnbCereal-book;
    color: $black-primary;
    font-size: 10.5px;
    line-height: 14.7px;
    letter-spacing: 0.72px;
}

@mixin image-description-font-1366 {
    @include airbnbCereal-book;
    color: $black-primary;
    font-size: 12px;
    line-height: 16.8px;
    letter-spacing: 0.72px;
}
@mixin image-description-font-1440 {
    @include airbnbCereal-book;
    color: $black-primary;
    font-size: 13.5px;
    line-height: 18.9px;
    letter-spacing: 0.72px;
}

@mixin image-description-font-1920 {
    @include airbnbCereal-book;
    color: $black-primary;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0.048rem;
    color: $black-primary;
}

///////////////////////////////////
//  font sizes will reduce by 10% from 1920p
// for 768 use 1024p sizes

@mixin nav-links-font-320 {
    // nav dropdown bar links
    @include airbnbCereal-book;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 1.6px;
    color: $blue;
}

@mixin nav-links-font-768 {
    @include airbnbCereal-medium;
    font-weight: 400;
    font-size: 14px;
    line-height: 18.2px;
    letter-spacing: 1.12px;
    color: $blue;
}

@mixin nav-links-font-1024 {
    @include airbnbCereal-medium;
    font-weight: 400;
    font-size: 14px;
    line-height: 18.2px;
    letter-spacing: 1.12px;
    color: $blue;
}

@mixin nav-links-font-1366 {
    @include airbnbCereal-medium;
    font-weight: 400;
    font-size: 16px;
    line-height: 20.8px;
    letter-spacing: 1.28px;
    color: $blue;
}

@mixin nav-links-font-1440 {
    @include airbnbCereal-medium;
    font-weight: 400;
    font-size: 18px;
    line-height: 23.4px;
    letter-spacing: 1.44px;
    color: $blue;
}

@mixin nav-links-font-1920 {
    @include airbnbCereal-medium;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 1.6px;
    color: $blue;
}

@mixin nav-links-font-1440 {
    // nav bar links
    @include nav-links-font-1366;
    font-size: 18px;
}

@mixin nav-links-font-1920 {
    // nav bar links
    @include nav-links-font-1440;
}

///////////////////////////////////
///  font sizes will reduce accordingly

@mixin nav-links-onboarding-font-1920 {
    // nav bar links
    @include nav-links-font-1920;
    @include airbnbCereal-book;
    line-height: 21px;
    color: $white-primary;
}
///////////////////////////////////
///  font sizes will reduce accordingly

@mixin footer-links-font-1920 {
    // footer bar links
    @include airbnbCereal-medium;
    font-size: 0.82rem;
    line-height: 1.05rem;
    letter-spacing: 0.065rem;
    color: $blue;
}

///////////////////////////////////

@mixin primary-label-font-320 {
    // landing page search btn popup
    @include airbnbCereal-book;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 1.5px;
    color: $black-secondary;
}

@mixin primary-label-font-1920 {
    // landing page search btn modal
    @include airbnbCereal-medium;
    font-size: 1rem;
    line-height: 1.3rem;
    letter-spacing: 0.1rem;
    color: $black-secondary;
}

///////////////////////////////////

@mixin primary-input-placeholder-font-320 {
    // landing page search input
    @include airbnbCereal-book;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 1.5px;
    color: $gray-dark;
}

@mixin primary-input-placeholder-font-768 {
    // landing page search input
    @include airbnbCereal-book;
    font-size: 14px;
    letter-spacing: 1.33px;
    line-height: 17.5px;
    color: $gray-dark;
}
@mixin primary-input-placeholder-font-1024 {
    // landing page search input
    @include airbnbCereal-book;

    font-size: 14px;
    letter-spacing: 1.33px;
    line-height: 17.5px;
    color: $gray-dark;
}
@mixin primary-input-placeholder-font-1366 {
    // landing page search input
    @include airbnbCereal-book;
    font-size: 15px;
    letter-spacing: 1.52px;
    line-height: 20px;
    color: $gray-dark;
}

@mixin primary-input-placeholder-font-1440 {
    // landing page search input
    @include airbnbCereal-book;
    font-size: 19px;
    letter-spacing: 1.71px;
    line-height: 22.5px;
    color: $gray-dark;
}

@mixin primary-input-placeholder-font-1920 {
    // landing page search input
    @include airbnbCereal-book;
    font-size: 19px;
    letter-spacing: 2px;
    line-height: 22px;
    color: $gray-dark;
}

@mixin secondary-input-placeholder-font-320 {
    // landing page filter modal
    @include airbnbCereal-book;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1.4px;
    color: $gray-medium;
}

@mixin secondary-input-placeholder-font-1920 {
    // landing page filter popup
    @include primary-input-placeholder-font-320;
}

///////////////////////////////////

@mixin AirbnbCereal-W-bd-font {
    font-family: "AirbnbCereal", sans-serif;
    font-weight: bold;
    letter-spacing: 1.6px;
}

@mixin AirbnbCereal-W-bd-font-1920 {
    @include AirbnbCereal-W-bd-font();
    font-size: 20px;
}
////////////////////vinayak//////////

@mixin form-radiobuttons-font-1920 {
    // add your org 
    @include airbnbCereal-book;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 1.44px;
    color: $gray-medium;
}

@mixin form-organization-font-1920 {
    // add your org 
    @include airbnbCereal-bold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 1.6px;
    color: #000000;
    margin: 10px 0;
}

@mixin form-font-book-1366 {
    // add your org 
    @include airbnbCereal-book;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1.2px;
    color: #000000;
}

@mixin form-font-1366 {
    // add your org 
    @include airbnbCereal-bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1.2px;
    color: #000000;
}

@mixin form-radiobuttons-font-1024 {
    // add your org 
    @include airbnbCereal-book;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
    color: $gray-medium;
}

@mixin form-organization-font-1024 {
    // add your org 
    @include airbnbCereal-bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
    color: #000000;
    margin: 8px 0;
}

@mixin form-font-book-1024 {
    // add your org 
    @include airbnbCereal-book;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
    color: #000000;
}

@mixin form-font-1024 {
    // add your org 
    @include airbnbCereal-bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
    color: #000000;
}


@mixin form-radiobuttons-font-800 {
    // add your org 
    @include airbnbCereal-book;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1px;
    color: $gray-medium;
}

@mixin form-organization-font-800 {
    // add your org 
    @include airbnbCereal-bold;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #000000;
    margin: 8px 0;
}

@mixin form-font-book-800 {
    // add your org 
    @include airbnbCereal-book;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #000000;
}

@mixin form-font-800 {
    // add your org 
    @include airbnbCereal-bold;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #000000;
}




@mixin form-radiobuttons-font-320 {
    // add your org 
    @include airbnbCereal-book;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 1px;
    color: $gray-medium;
}

@mixin form-organization-font-320 {
    // add your org 
    @include airbnbCereal-bold;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #000000;
    margin: 8px 0;
}

@mixin form-font-book-320 {
    // add your org 
    @include airbnbCereal-book;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #000000;
}

@mixin form-font-320 {
    // add your org 
    @include airbnbCereal-bold;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #000000;
}

// ===========vinayak================


