@import "../../styles/main.scss";
.youtube-thumbnail {
    -webkit-backface-visibility: hidden; /* Fixes aliasing issues on some browsers */
    -webkit-transform: translateZ(0); /* Forces hardware acceleration */
}

@media (min-width: 320px) {
    .partner-slider-container {
        margin: 0 24px;
        height: 100%;
        .slick-slider {
            height: 100%;
            display: contents;
            .slick-list {
                height: 100%;
                width: 100%;
                .slick-track {
                    height: 100%;

                    .slick-active:first-of-type {
                        margin-left: 0; /* Remove left margin for the first active element */
                    }
                    .slick-active:last-of-type {
                        margin-right: 0; /* Remove right margin for the last active element */
                    }
                    .slick-slide {
                        width: 309px;
                        height: 165px;
                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                            .slide {
                                display: flex;
                                justify-items: center;
                                box-shadow: 0px 3px 6px #bac4d226;
                                border: 1px solid #bac4d2;
                                // border: 1px solid white;
                                // border-left-width: 5px;
                                // border-right-width: 5px;
                                border-radius: 11px;
                                overflow: hidden;
                                padding: 0px;
                                width: 100%;
                                height: 180px;
                                .carousel-image {
                                    width: 100%;
                                    height: auto;
                                    object-fit: cover;
                                    border-radius: 11px;
                                    min-height: 158px;
                                }
                                .react-player__preview {
                                    border-radius: 11px;
                                }
                                .slide-title-hidden {
                                    display: none;
                                }
                            }
                            .slide-title {
                                margin-top: 8px;
                                @include image-description-font-320;
                                text-transform: capitalize;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: calc(100% - 30px);
                            }
                        }
                    }
                    .slick-slide .slick-cloned {
                        width: 305px;
                    }
                    .slide:not(.slick-active) {
                        cursor: pointer;
                    }
                }
            }
        }
        
    }
    .slider-container-length-two {
        width: 100%;
    }
}

@media (min-width: 600px) {
    .partner-slider-container {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        position: relative;
        .slick-slider {
            .slick-list {
                .slick-track {
                    display: flex;
                    .slick-slide {
                        height: 100%;
                        div {
                            // padding: 5px;
                            padding-bottom: 0;
                            display: flex;
                            justify-content: center;
                            .slide {
                                padding: 0px;
                                height: 120px;
                                width: 100%;
                                border-radius: 15px;
                                // aspect-ratio: 4 / 3;
                                .carousel-image {
                                    // aspect-ratio: 4 / 3;
                                    object-fit: cover;
                                    width: 100%;
                                    height: 100%;
                                    min-height: 150px;
                                }
                            }
                            .slide-title {
                                @include image-description-font-600;
                            }
                        }
                    }
                }
            }
        }
        .next-arrow {
            position: absolute;
            left: calc(100% - 18px);
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
        .previous-arrow {
            position: absolute;
            left: -13px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
    }
}

@media (min-width: 1024px) {
    .partner-slider-container {
        height: 100%;

        // .slick-active {
        // }
        .slick-active:first-of-type {
            margin-left: 0; /* Remove left margin for the first active element */
        }
        .slick-active:last-of-type {
            margin-right: 0; /* Remove right margin for the last active element */
        }
        .slick-slider {
            .slick-list {
                .slick-track {
                    .slick-slide {
                        width: 490px;
                        height: 178px;
                        div {
                            .slide {
                                // box-shadow: 0px 1px 3px #eceff3;
                                border-radius: 11px;
                                overflow: hidden;
                                min-width: 200px;
                                max-width: 300px;
                                height: 100%;
                                // aspect-ratio: 16/9;
                                .img-slide {
                                    img {
                                        width: 100%;
                                        height: auto;
                                        object-fit: cover;
                                    }
                                }
                            }
                            .slide-title {
                                @include image-description-font-1024;
                            }
                        }
                    }
                }
            }
            .slider-img {
                width: 100%;
            }
            .slide:not(.slick-active) {
                cursor: pointer;
            }
        }
        .next-arrow {
            position: absolute;
            left: calc(100% - 20px);
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
        .previous-arrow {
            position: absolute;
            left: -15px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
        .slick-list {
            height: 279px;
        }



    }
    
    .slider-container-length-two {
        padding: 0px 50px;
    }
}

@media (min-width: 1366px) {
    .partner-slider-container {
        .slick-slider .slick-list .slick-track .slick-slide {
            width: 490px;
            height: 225px;
            div {
                .slide {
                    max-width: 360px;
                }
                .slide-title {
                    @include image-description-font-1366;
                }
            }
        }
       
        
    }
    .slider-container-length-two {
        padding: 0px 80px;
    }
    
    
}

@media (min-width: 1440px) {
    .partner-slider-container {
        height: 100%;

        // .slick-active {
        // }
        .slick-active:first-of-type {
            margin-left: 0; /* Remove left margin for the first active element */
        }
        .slick-active:last-of-type {
            margin-right: 0; /* Remove right margin for the last active element */
        }
        .slick-slider {
            .slick-list {
                .slick-track {
                    .slick-slide {
                        width: 490px;
                        height: 258px;
                        div {
                            .slide {
                                // box-shadow: 0px 1px 3px #eceff3;
                                border-radius: 11px;
                                overflow: hidden;
                                min-width: 300px;
                                max-width: 480px;
                                height: 100%;
                                // aspect-ratio: 16/9;
                                .img-slide {
                                    img {
                                        width: 100%;
                                        height: auto;
                                        object-fit: cover;
                                    }
                                }
                            }
                            .slide-title {
                                @include image-description-font-1440;
                            }
                        }
                    }
                }
            }
            .slider-img {
                width: 100%;
            }
            .slide:not(.slick-active) {
                cursor: pointer;
            }
        }
        .next-arrow {
            position: absolute;
            left: calc(100% - 25px);
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
        .previous-arrow {
            position: absolute;
            left: -16px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
        .slick-list {
            height: 100%;
        }
    }
}

@media (min-width: 1920px) {
    .partner-slider-container {
        height: 100%;
        .slick-active:first-of-type {
            margin-left: 0; /* Remove left margin for the first active element */
        }
        .slick-active:last-of-type {
            margin-right: 0; /* Remove right margin for the last active element */
        }
        .slick-slider {
            .slick-list {
                .slick-track {
                    .slick-slide {
                        width: 490px;
                        height: 297px;
                        div {
                            .slide {
                                // box-shadow: 0px 1px 3px #eceff3;
                                border-radius: 11px;
                                overflow: hidden;
                                width: 490px;
                                height: 100%;
                                // aspect-ratio: 16/9;
                                .img-slide {
                                    img {
                                        width: 100%;
                                        height: auto;
                                        object-fit: cover;
                                    }
                                }
                            }
                            .slide-title {
                                @include image-description-font-1920;
                            }
                        }
                    }
                }
            }
            .slider-img {
                width: 100%;
            }
            .slide:not(.slick-active) {
                cursor: pointer;
            }
        }
        .next-arrow {
            position: absolute;
            left: calc(100% - 25px);
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
        .previous-arrow {
            position: absolute;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }

       
    }
    .slider-container-length-two {
        padding: 0px 150px;
    }
}
