@import "../../../../styles/main.scss";

@media (min-width: 320px) {
    .media-container {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 10px 0;
        .slide {
            aspect-ratio: 16 / 9;
            height: 165px;
            .video-player {
                height: 100%;
                width: 100%;
                aspect-ratio: 16 / 9;
            }

            img {
                height: 100%;
                width: 100%;
                aspect-ratio: 16 / 9;
                object-fit: contain;
            }
        }
    }
}

@media (min-width: 768px) {
    .media-container {
        padding: 17px 0;
        .slide {
            height: 200px;
            aspect-ratio: 16 / 9;
            .video-player {
                height: 100%;
                width: 100%;
                aspect-ratio: 16 / 9;
            }

            img {
                height: 100%;
                width: 100%;
                aspect-ratio: 16 / 9;
                object-fit: contain;
            }
        }
    }
}

@media (min-width: 1024px) {
    .media-container {
        padding: 27px 0;
    }
}

@media (min-width: 1366px) {
}

@media (min-width: 1440px) {
    .media-container {
        .slide {
            height: 250px;
        }
    }
}
@media (min-width: 1920px) {
    .media-container {
        .slide {
            height: 300px;
        }
    }
}
