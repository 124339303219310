.layout {
  .custom-header {
    height: 52px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    // justify-content: space-between;

    .view-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      // padding-left: 10px;
      // padding-right: 10px;
      height: 100%;
      // justify-content: space-around;
    }
  }
}

.ant-layout .ant-layout-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 10px 0px;
  z-index: 1;
  overflow: hidden;
}

.ant-layout-content {
  position: relative;
}

.card-container-view{
  width: 100%;
  display: flex;
  justify-content: center;

  .previewCard {
    position: absolute;
    bottom: 60px;
    z-index: 10000;

    .image-container {
      display: flex;
      justify-content: center;
      width: 40%;
      // height: 10vh;
      // background-color: #d7d7d7;
      box-shadow: 0px 0px 3px #0000001a;
      // border: 0.5px solid #c5c5c5;
      // border-radius: 8px;

      .media {
        // object-fit: cover;
        height: 100%;
        width: 100%;
        display: flex;
        // border: 2px solid green;
        align-items: center;
      }

      .youtube {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .ytBtnOverlay {
          height: 40px;
          position: absolute;

          // top: 50%;
          // left: 15%;
          // transform: translate(-50%, -50%);
          // padding: 10px;
        }
      }

      // iframe {
      //   .vp-center {
      //     .vp-player-ui-overlays {
      //       .player.player-tiny {
      //         .vp-controls {
      //           display: none;
      //         }
      //       }
      //     }
      //   }
      // }
      .slider-Img {
        display: flex;
        width: 100%;
        object-fit: cover;
        height: 100%;
        // max-height: 60px;
        padding: 0px;
        // margin-left: -18%;
        // background-color: gainsboro;
        border-radius: 5px;
        // margin-bottom: 10px;
        // margin-top: 10px;
        overflow: hidden;

        .home-preview-img {
          width: 100%;
          // max-height: 50px;
          height: auto;
        }
      }
    }
  }
}

.text-container {
  width: 55%;

}


.menu {
  border: 0px;
  // padding: 0px 10px;
  border-radius: 5px;
  background: #569dfa;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  height: 32px;
  // margin-bottom: 35px;
}

.header-back-btn {
  // height: auto;
  // margin-top: -25px;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.custom-header {
  padding-inline: 0px !important;
}

.first-row,
.last-row {
  display: flex;
  justify-content: space-around;
}

.filled-circle {
  position: absolute;
  top: 38%;
  width: 10px;
  height: 10px;
}

.line {
  width: 79%;
}

.footer-icons {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.train {
  transform: scale(1.3);
  margin-bottom: 3px;
}

.view-filter {
  background: none !important;
  text-align: end;
  padding: 5px;

  .button-primary {
    margin-right: 10px;
    padding: 5px 15px;
    // padding: 5%;
  }

  .button-primary,
  .view {
    width: auto;
    font-size: 16px;
    min-height: auto;
    // padding: 5px 15px;
    // padding-right: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #569dfa;

    .filterIcon {
      padding-top: 3%;
      transform: scale(1.3);
      padding-right: 5%;

    }

    .view-img {
      transform: scale(1.5);
      padding-left: 14%;
      padding-bottom: 4%;
      // width: 4px;
      // padding: 5px;
      // padding-top: 5px;
      padding-right: 5%;
    }
  }
}

.map-filter-options {
  margin-left: 10px;

  span {
    background-color: #D4D4D4;
    color: black;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 5px;
    padding-left: 8px;
    font-size: 11px;
    font-weight: 400;
    border-radius: 15px;

    svg {
      color: black;
      font-size: 13px;
    }
  }
}

.closeIcon {
  position: absolute;
  right: 10px;
  top: 10px;
}

@media(min-width:768px) {
  .previewCard {
    bottom: 80px;
    width: 450px;
  }
}