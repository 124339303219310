@media (min-width: 360px) {
    .search-bar {
      padding: 10px 10px 10px 10px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      background-color: white;
      // max-height: 52px;
  
      .header-logo {
        display: none;
        // height: 100%;
      }
  
      .help-header-logo {
        margin-left: -15px;
      }
  
      .back-btn {
        padding-bottom: 0%;
        cursor: pointer;
        width: 20%;
        display: flex;
        justify-content: center;
        // margin-left: 10%;
      }
  
      .search-btn {
        border: 0px;
        padding: 5px 10px;
        border-radius: 5px;
        background: #569dfa;
        color: white;
      }
  
      .search-input-container {
        width: 100%;
        margin-right: 1%;
  
        ::placeholder {
          color: #878787
        }
  
        .ant-input-search-button {
          background-color: #569dfa;
        }
      }
  
      .org-menu-btn {
        display: flex;
  
        .org-btn {
          align-items: center;
          margin-right: 1%;
  
          img {
            padding-bottom: 1%;
            padding-right: 3%;
          }
        }
  
        .drop-btn {
          border: 0px;
          padding: 8px;
          // padding-right: 10px;
          // padding-left: 10px;
          border-radius: 5px;
          background: #569dfa;
          color: white;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          // height: 32px;
  
          &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }
        }
      }
    }
  }
  
  @media (min-width: 768px) {
    .search-bar {
      display: grid;
      grid-template-columns: 2rem 1fr 2fr 1fr;
      // height: 52px;
  
      .header-logo {
        // display: inline-flex;
        display: block;
        justify-content: start;
        padding-left: 5%;
        height: 100%;
  
        img {
          // transform: scale(0.8); 
          // width: 20%;
          height: 52px
        }
      }
  
      .help-header-logo {
        margin-left: 0px;
      }
  
      .back-btn {
        padding-right: 0%;
        padding-bottom: 0%;
        display: block;
        width: 0% !important;
      }
  
      .org-menu-btn {
        display: inline-flex;
        justify-content: end;
  
        org-btn {
          margin-right: 3%;
        }
      }
    }
  }