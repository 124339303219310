@import "../../styles//main.scss";
@media (min-width: 360px) {
  .background-container {
    .float-container {
      position: fixed;
      bottom: -1px;
      z-index: 1001;
      width: 100%;

      .float-group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        width: 100%;
        padding: 5px;
        padding-bottom: 12px;
        // padding-left: 20px;
        // gap: 20px;
        align-items: center;
      }
    }

    .fav-icon {
      margin-top: 5px;
    }

    .icon-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-text {
        display: none;
      }
    }

    .search-btn {
      border: 0px;
      padding: 5px 10px;
      border-radius: 5px;
      background: #569dfa;
      color: white;
      align-items: center;
    }

    .filter-container {
      text-align: end;
      padding: 5px;

      .filter-icon {
        margin-right: 10px;
        padding: 5px 15px;
        align-items: center;
      }

      .alpha-filter-btn {
        width: auto;
        font-size: 15px;
        margin-right: 3px;
        min-height: auto;
        padding: 1px 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;
      }

      .alpha-filter-btn:hover {
        border: none;
        transform: scale(1.01);
      }

      .alpha-filter-btn:focus {
        border: none;
      }

      .share-dropdown {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .filter-icon,
      .view-btn {
        width: auto;
        font-size: 15px;
        margin-right: 3px;
        min-height: auto;
        padding: 3px 13px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #569dfa;

        .view-img {
          // padding-top: 5px;
          padding-left: 15%;
          padding-bottom: 5%;
          padding-right: 10%;
          transform: scale(1.7);
        }
      }

      .alpha-filter-btn-white {
        width: auto;
        font-size: 15px;
        margin-right: 3px;
        min-height: auto;
        padding: 3px 13px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
      }

      // .alpha-filter-btn{
      //   font-size: 12px;
      // }

      // .view-btn {
      //   padding: 5px 15px;
      // }
    }

    .filter-options {
      margin-left: 10px;

      span {
        display: inline-flex;
        background-color: #d4d4d4;
        // border: 0.5px solid black;
        color: black;
        // padding: 3px 8px;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 8px;
        // padding-right: 5px;
        font-size: 11px;
        font-weight: 400;
        border-radius: 15px;
        align-items: center;

        .anticon .anticon-close .ant-tag-close-icon {
          border: none !important;
        }

        svg {
          font-size: 13px;
          color: black;
          // border: 0px;
        }
      }
    }

    .home-page-card-item {
      position: relative;

      .kebab-menu {
        background-color: #fff;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
        cursor: pointer;
      }
    }

    .org-card-container {
      .image-container {
        display: flex;
        justify-content: center;
        width: 40%;
        // height: 10vh;
        // background-color: #d7d7d7;
        box-shadow: 0px 0px 3px #0000001a;
        // border: 0.5px solid #c5c5c5;
        border-radius: 8px;

        .media {
          // object-fit: cover;
          height: 100%;
          width: 100%;
          display: flex;
          // border: 2px solid green;
          align-items: center;
        }

        .bookMarked {
          // height: 40px;
          left: 20px;
          top: 8px;
          position: absolute;
          z-index: 1000;
        }

        .youtube {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          .ytBtnOverlay {
            height: 40px;
            position: absolute;

            // top: 50%;
            // left: 15%;
            // transform: translate(-50%, -50%);
            // padding: 10px;
          }
        }

        // iframe {
        //   .vp-center {
        //     .vp-player-ui-overlays {
        //       .player.player-tiny {
        //         .vp-controls {
        //           display: none;
        //         }
        //       }
        //     }
        //   }
        // }
        .slider-Img {
          display: flex;
          width: 100%;
          object-fit: cover;
          height: 100%;
          // max-height: 60px;
          padding: 0px;
          // margin-left: -18%;
          // background-color: gainsboro;
          border-radius: 5px;
          // margin-bottom: 10px;
          // margin-top: 10px;
          overflow: hidden;

          .home-preview-img {
            width: 100%;
            // max-height: 50px;
            height: auto;
          }
        }
      }
    }

    .text-container {
      width: 55%;
    }
  }

  .no-data-warning {
    height: 50% !important;
  }

  .card-item {
    margin: 10px;
    // border-radius: 5px;
    border: 1px solid #d1d1d1;
    // box-shadow: none;
    box-shadow: 0px 0px 3px #0000001a;

    // border: 0.5px solid #c5c5c5;
    // border-radius: 8px;
  }

  .drop-down-select {
    width: 100%;
  }
}

.ant-space {
  gap: 0px !important;
}

.ant-tabs-nav-operations {
  .ant-tabs-nav-more {
    display: none;
  }
}

// .label-text {
//   font-size: 18px;
//   font-weight: 500;
// }

.search-in-modal {
  margin-top: 20px;
  margin-bottom: 20px;

  .search-bar .search-btn {
    display: none;
    padding: 0px;
  }

  .search-bar .search-input-container {
    width: 300px;
  }
}

.modal-container {
  top: 25px;
  padding-left: 0px;
  padding-right: 0px;

  .ant-modal-close {
    color: white;
    font-weight: bolder;
  }
  .ant-modal-close:hover {
    background-color: white;
  }

  .ant-modal-content {
    // top: 0vh;
    top: -35px;
    padding: 0px;
    box-shadow: 3px 5px 16px #0000003b;
    // border: 3px solid #5aa2fb;
    // border: 1px solid ;

    border: none !important;
    border-radius: 10px;
    // min-height: 60em;
  }

  .ant-modal-header {
    padding: 15px;
    padding-bottom: 0px;
    // background-color: #5aa2fb;
    background-color: #ffffff;
    background: transparent;

    margin: -1px -1px 0 -1px;
    border-radius: 20px 20px 0 0;

    .ant-modal-title {
      color: black;
    }
  }

  .ant-modal-body {
    padding-left: 20px;
    padding-right: 25px;
    padding-top: 25px;
    padding-bottom: 15px;
  }

  .ant-modal-footer {
    padding-bottom: 25px;
    padding-right: 16px;
    text-align: center;

    .ant-btn:nth-child(1) {
      width: 120px;
      height: 40px;
      background-color: #908a8a;

      span {
        @include airbnbCereal-book;
        color: white !important;
        letter-spacing: 1.6px;
      }
    }

    .ant-btn:nth-child(2) {
      // width: 125px;
      height: 40px;
      background-color: #569dfa;
      // background-color: $red-primary;

      span {
        @include airbnbCereal-book;
        color: white !important;
        letter-spacing: 1.6px;
      }
    }
  }
}

.home-page-card-item .ant-card-body {
  display: flex;
  padding: 8px 12px;
  height: 8.5em;

  :hover {
    cursor: pointer;

    // box-shadow: 0px 0px 5px #f00606;
    // // box-shadow: 0px 0px 5px #0000001a;
    // transition: linear;
  }
}

.view-img {
  align-items: flex-end;
}

.card-hover:hover {
  box-shadow: 0px 5px 20px #0000001a;
  transition: box-shadow 0.3s ease-in-out;
}

.card-hover::after {
  box-shadow: 0px 5px 20px #0000001a;
  transition: box-shadow 0.3s ease-in-out;
}

.home-page-card-item {
  overflow: hidden;

  .preview-image {
    // padding-top: 3%;
    height: 40%;
    max-width: 90%;
    // border-radius: 8px;
    //     max-height: 80px;
    //     padding: 10px;
    flex-shrink: 0;
    // min-width: 30%;
    // min-height: 100%
    // transform: scale(0.9);
    // width: 90%;
  }

  .text-container {
    padding-left: 12px;
    font-family: Arial, Helvetica, sans-serif;
    // font-weight: bold;

    .logo-container {
      width: 100%;
      // border: 1px solid #ccc;
      padding: 3px;
      margin: auto 0px;
      display: flex;
      justify-content: space-between;

      .logo-image {
        height: 100%;
        max-height: 40px;
        display: flex;
      }
    }

    .rating-icon {
      padding-right: 2px;
    }
  }
}

.sector-info-container {
  // margin-top: 10px;
  margin-top: 1%;
  // margin-left: 5%;
  overflow: hidden;
  // text-overflow: ellipsis;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none;
}

.remove-btn {
  position: absolute;
  top: 27px;
  right: -20px;
  color: #569dfa;
}

.microsite-details {
  // ;
  font-size: 11px;
  display: -webkit-box;
  // justify-content: space-between;
  // white-space: nowrap;
  // white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (min-width: 480px) {
  .background-container {
    .org-card-container {
      .image-container {
        width: 40%;
        // max-width: 150px;
      }
    }

    .float-container {
      position: fixed;
      bottom: 10px;
      left: 50%;
      margin-left: -235px;
      z-index: 1001;

      .float-group {
        display: grid;
        grid-template-columns: 0.7fr 1fr 1fr 1fr;
        background-color: white;
        border-radius: 50px;
        border: 1px solid #0000001a;
        box-shadow: 10px 10px 13px #0000001a;
        width: 440px;
        padding: 10px;
        padding-right: 13px;
        gap: 20px;
        align-items: center;
      }
    }
  }
}

@media (min-width: 768px) {
  /* Track */
  html {
    overflow: auto;
  }

  // body {
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   bottom: 0px;
  //   right: 0px;
  //   padding: 0px;
  //   overflow-y: scroll;
  //   overflow-x: hidden;
  // }

  .alpha-filter {
    margin-right: 10px;
  }

  .alpha-filter-btn {
    width: auto;
    font-size: 16px;
    min-height: auto;
    padding: 1px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
  }

  .alpha-filter-btn:hover {
    border: none;
    transform: scale(1.01);
  }

  .alpha-filter-btn:focus {
    border: none;
  }

  .fav-icon {
    margin-top: 0px !important;
    margin-left: 10px;
  }

  .icon-container {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    .icon {
      color: #fff;
    }

    .icon-text {
      margin-left: 3px;
      width: 100%;
      display: contents !important;
      // color: #569dfa;
    }
  }

  .filter-icon,
  .view-btn {
    width: auto;
    font-size: 16px;
    // min-height: auto;
    min-height: 45px;

    padding: 5px 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #569dfa;

    .view-img {
      // padding-top: 5px;
      padding-left: 15%;
      padding-bottom: 5%;
      padding-right: 10%;
      transform: scale(1.7);
    }
  }

  .alpha-filter-white {
    margin-right: 10px;
  }

  .alpha-filter-btn-white {
    width: auto;
    font-size: 16px;
    min-height: auto;
    padding: 5px 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  .background-container {
    .org-card-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .home-page-card-item .ant-card-body {
      display: flex;
      padding: 8px 12px;
      height: 8.5em;
      box-shadow: 0px 0px 3px #0000001a;
    }
  }
}

@media (min-width: 1024px) {
  .background-container {
    .org-card-container {
      width: 80%;
      margin: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .image-container {
        align-items: center;
        width: 40%;
      }
    }

    .home-page-card-item {
      .ant-card-body {
        display: flex;
        padding: 8px 12px;
        height: 10em;
        box-shadow: 0px 0px 3px #0000001a;
      }
    }
  }
}

@media (min-width: 1440px) {
  .background-container {
    .org-card-container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      .image-container {
        max-width: 200px;
      }

      .text-container {
        width: 60%;
      }
    }

    // .home-page-card-item .ant-card-body {
    //   height: 12em;
    // }
  }
}

@media (min-width: 2160px) {
  .background-container {
    .org-card-container {
      // display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      .text-container {
        width: 70%;
      }
    }

    .home-page-card-item .ant-card-body {
      height: 12em;
    }
  }
}

/** microSites container **/

.microsites-container {
  width: 100%;
  min-height: 83vh;
  padding: 5px 5px;

  .search-and-filter-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 11px 15px 15px 15px;
    align-items: center;

    .arrow-container {
      display: flex;
      gap: 15px;
      align-items: center;
      justify-content: center;

      .title {
        @include AirbnbCereal-W-bd-font();
        font-size: 20px;
      }
    }

    .search-and-filter-container {
      display: flex;
      align-items: center;

      .fav-icon {
        // height: 25px;
        height: 32px;

        margin-right: 10px;
        // cursor: pointer;
      }

      .title-bar-search-wrapper {
        width: 400px;
        margin-right: 10px;
        .ant-input-affix-wrapper {
          box-shadow: 0px 1px 2px #c0cee24f;
          border: 1px solid #7e97b8;
          border-radius: 8px;
        }
        input {
          height: 35px;
          font-size: 16px;
        }
        input::placeholder {
          // @include primary-input-placeholder-font-320;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 1.6px;
          color: #abb4bf;
          // color: #212529;
          font-weight: 600;
        }
      }

      .filter-container {
        text-align: end;
        padding: 5px;

        .filter-icon {
          margin-right: 10px;
          padding: 5px 15px;
          align-items: center;
        }

        .alpha-filter-btn {
          width: auto;
          font-size: 15px;
          margin-right: 10px;
          min-height: 40px;
          padding: 1px 10px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border: none;
        }

        .alpha-filter-btn:hover {
          border: none;
          transform: scale(1.01);
        }

        .alpha-filter-btn:focus {
          border: none;
        }

        .share-dropdown {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .filter-icon,
        .view-btn {
          width: auto;
          font-size: 15px;
          margin-right: 3px;
          min-height: 40px;
          padding: 3px 13px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: #569dfa;

          .view-img {
            // padding-top: 5px;
            padding-left: 15%;
            padding-bottom: 5%;
            padding-right: 10%;
            transform: scale(1.7);
          }
        }

        .alpha-filter-btn-white {
          width: auto;
          font-size: 15px;
          margin-right: 3px;
          min-height: auto;
          padding: 3px 13px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
        }

        // .alpha-filter-btn{
        //   font-size: 12px;
        // }

        // .view-btn {
        //   padding: 5px 15px;
        // }
      }
    }
  }

  .filter-options {
    margin-left: 10px;
    margin-bottom: 15px;

    span {
      display: inline-flex;
      background-color: #d4d4d4;
      // border: 0.5px solid black;
      color: black;
      // padding: 3px 8px;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-left: 8px;
      // padding-right: 5px;
      font-size: 15px;
      font-weight: 400;
      border-radius: 15px;
      align-items: center;

      .anticon .anticon-close .ant-tag-close-icon {
        border: none !important;
      }

      svg {
        font-size: 12px;
        color: black;
        // border: 0px;
      }
    }
  }

  .list-and-view-container {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;

    .list-container {
      border: 0.5px solid $border-gray;
      border-radius: 10px;
      flex: 2;
      min-height: 70dvh;

      .empty-micros {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .no-micros {
          @include AirbnbCereal-W-bd-font();
          font-weight: 400;
        }
      }

      .micro-nto-found {
        height: 100%;
        display: flex;
      }

      .list-card-container {
        // display: flex;
        // flex-wrap: wrap;
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        padding: 0px 20px;
        margin-bottom: 10px;
        height: auto;
        // height: calc(100dvh - 330px);
        height: auto;
        max-height: calc(100dvh - 330px);
        margin-right: 10px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #4d4b4e;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: #4d4b4e;
        }

        .motion-wrapper {
          //   width: 40%;
          //   margin-right: 40px;
          //   margin-bottom: 30px;

          .card-wrapper {
            margin-left: 10px;
            // min-height: 159px;
            // max-height: 159px;
            cursor: pointer;
            border: 0.5px solid $border-gray;

            .card-container {
              display: flex;
              justify-content: space-between;
              gap: 25px;
              height: 10em;

              .card-image-container {
                // flex: 3.2;
                // width: 35%;
                // height: 100%;

                .media-image-container {
                  position: relative;
                  .play-button {
                    height: 30px;
                    // width: 25px;
                    // left: 80px;
                    // top: 60px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }

                  .bookMarked {
                    // height: 40px;
                    left: 10px;
                    // top: -5px;
                    top: 8px;
                    position: absolute;
                    z-index: 1000;
                  }

                  .image-preview {
                    height: 120px;
                    border-radius: 10px;
                    object-fit: cover;
                    border: 1px solid $border-gray;
                    width: 100%;
                    aspect-ratio: 16/9;
                  }
                }

                .media {
                  width: 100%;
                  height: 100%;
                  .slider-Img {
                    width: 100%;
                    height: 100%;
                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 10px;
                    }
                  }
                }
              }

              .card-description-container {
                flex: 4;
                display: flex;
                // justify-content: space-between;
                flex-direction: column;
                .micro-share-and-logo-container {
                  display: flex;
                  justify-content: space-between;
                  height: 40%;
                  margin-bottom: 3px;
                  .kebab-menu {
                    position: relative;
                    .microsite-dropdown {
                      .fav-wrapper {
                        border: 1px solid;
                        .save-as-fav {
                          // width: 20px;
                          // height: 20px;
                          .save-as-fav-img {
                            width: 10px;
                            height: 10px;
                          }
                        }
                      }
                    }
                  }
                  .logo-and-share-wrapper {
                    display: flex;
                    justify-content: space-between;
                    // height: 40%;
                    width: 70%;
                  }
                }

                .sector-text-container {
                  @include AirbnbCereal-W-bd-font();
                  font-size: 11px;
                  letter-spacing: 0.8px;
                  font-weight: 400;
                  font-size: 9px;
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;

                  -webkit-box-orient: vertical;
                  // margin-top: 10px;
                  .microsite-details-text {
                  }
                }

                .logo-container {
                  //   flex: 9;

                  // height: 40%;

                  .microsite-preview-image {
                    height: 100%;
                    object-fit: contain;
                    width: 100%;
                  }
                }

                .kebab-menu-container {
                  //   flex: 1;
                }
              }
            }
          }

          .card-wrapper .ant-card-body {
            padding: 0px !important;
          }
        }
      }
      .list-grid {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    .arrows-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .arrows-container {
        display: flex;
        gap: 5px;
        border-bottom: 0.5px solid $border-gray;
        border-left: 0.5px solid $border-gray;
        padding: 3px 5px;

        .arrows {
          color: $arrow-color;
          cursor: pointer;
        }
      }
    }

    .view-container {
      border: 0.5px solid $border-gray;
      border-radius: 10px;
      //   background-color: #908a8a;
      flex: 1;
      //   height: 30vh;
      height: calc(100dvh - 272px);
      position: sticky;
      top: 100px;
    }
  }
}

@media (min-width: 319px) and (max-width: 359px) {
  .microsites-container .search-and-filter-wrapper .search-and-filter-container .filter-container .alpha-filter-btn {
    // padding: 0px !important;
    padding: 1px 10px !important;
  }
}

@media (min-width: 320px) and (max-width: 759px) {
  .microsites-container {
    // min-height: 70vh;
    @include main-body-container-320;
    min-height: unset;
    // overflow-y: initial;
    .list-and-view-container {
      height: calc(100% - 108px);
      margin-bottom: 1px;
      .list-container {
        border: none;
        // overflow-y: auto;
        .empty-micros {
          .no-micros {
            position: absolute;
            top: 50%;
            font-size: 14px;
            margin: 0px 10px;
          }
        }
        .list-card-container {
          gap: 5px;
          padding: 0px 10px;
          .motion-wrapper {
            .card-wrapper {
              margin: 0px;
              &:last-child {
                margin-bottom: 10px; // Override for the last card
              }
              .card-container {
                gap: 10px;
                height: 9em;
                .card-image-container {
                  .media-image-container {
                    .image-preview {
                      height: 100px;
                      width: 140px;
                    }
                  }
                  .media {
                    .slider-Img {
                      height: 100px;
                      width: 140px;
                    }
                  }
                }
                .card-description-container {
                  .sector-text-container {
                    -webkit-line-clamp: 3;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
        .list-grid {
          grid-template-columns: 1fr;
          height: calc(100dvh - 275px);
          max-height: unset;
        }
      }

      .view-container {
        border: none;
        // height: calc(100vh - 290px);
        height: 97%;

        .ant-layout {
          height: 100%;
        }
      }
      .arrows-wrapper {
        margin-right: 10px;

        .arrows-container {
          // padding: 5px 5px;
          // border: 1px solid #bac4d2;
          // border-radius: 5px;
          display: none;
          .responsive-view-img {
            width: 17px;
          }
          .responsive-list-img {
            width: 25px;
          }
        }
      }
    }
    .search-and-filter-wrapper {
      margin: 0px;
      .search-and-filter-container {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        position: relative;
        .fav-icon {
          position: absolute;
          top: 3px;
          z-index: 9;
          right: 0px;
          height: 30px;
        }

        .title-bar-search-wrapper {
          //   width: 350px;
          width: 87%;
          input::placeholder {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 1.6px;
            font-weight: 600;
          }
        }
        .filter-container {
          display: flex;
          margin-top: 10px;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .alpha-filter-btn {
            font-size: 15px;
            // min-height: 40px;
            // padding: 1px 12px;
            width: 40px;
            height: 30px;
            padding: 0px;
          }
          .filter-icon {
            font-size: 10px;
          }
          .toggle-img {
            width: 40px;
            height: 40px;
            padding: 5px 5px;
            border: 1px solid #bac4d2;
            border-radius: 5px;
          }
        }
      }
      .arrow-container {
        display: none;
      }
    }

    // .filter-options{
    //   margin-top: 10px;
    //   margin-bottom: 8px;
    // }

    .filter-options {
      margin-top: 10px;
      margin-bottom: 8px;
      span {
        font-size: 12px;
      }

      svg {
        font-size: 10px !important;
      }
    }
  }
}

@media (min-width: 760px) and (max-width: 1023px) {
  .microsites-container {
    min-height: unset;
    @include main-body-container-768;

    .search-and-filter-wrapper {
      margin: 11px 0px 15px 2px;

      .search-and-filter-container {
        .title-bar-search-wrapper {
          width: auto;
          margin-right: 3px;
          input::placeholder {
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 1.6px;
            font-weight: 600;
          }
        }
        .filter-container {
          display: flex;
          .toggle-img {
            width: 35px;
            height: 35px;
            padding: 5px 5px;
            border: 1px solid #bac4d2;
            border-radius: 5px;
            margin-left: 10px;
          }
          .alpha-filter-btn {
            min-height: 35px;
            margin-right: 5px;
            font-size: 13px;
          }

          .filter-icon {
            margin-right: 0px;
            min-height: 35px;
            font-size: 12px !important;
          }
        }
      }

      .arrow-container {
        border: none;
        .title {
          font-size: 12px;
        }
      }
    }
    .list-and-view-container {
      .list-container {
        border: none;
        .empty-micros {
          .no-micros {
            position: absolute;
            top: 50%;
            font-size: 16px;
            margin: 0px 10px;
          }
        }

        .list-card-container {
          grid-template-columns: 1fr 1fr;
          padding: 0px 10px 0px 0px;
          height: auto;
          .motion-wrapper {
            .card-wrapper {
              .card-container {
                gap: 10px;
                height: 9em;

                .card-description-container {
                  .sector-text-container {
                    -webkit-line-clamp: 3;
                    font-size: 12px;
                  }
                }
                .card-image-container {
                  .media-image-container {
                    .image-preview {
                      height: 100px;
                      width: 150px;
                    }
                  }
                  .media {
                    width: 100%;
                    height: 100%;
                    .slider-Img {
                      width: 100%;
                      height: 100%;
                      img {
                        width: 100%;
                        height: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .arrows-wrapper {
        margin-right: 10px;
        .arrows-container {
          display: none;
          padding: 5px 5px;
          border: 1px solid #bac4d2;
          border-radius: 5px;
          .responsive-view-img {
            width: 20px;
          }
          .responsive-list-img {
            width: 25px;
          }
        }
      }
      .view-container {
        border: none;

        // min-height: calc(100dvh - 425px);
        // max-height: calc(100dvh - 625px);

        // height: calc(100dvh - 270px);
        height: calc(100dvh - 241px);

        .ant-layout {
          height: 100%;
        }
      }
    }

    .filter-options {
      span {
        font-size: 13px;
      }

      svg {
        font-size: 10px !important;
      }
    }
  }
}

@media (min-width: 760px) and (max-width: 1023px) and (orientation: landscape) {
  .microsites-container .list-and-view-container .view-container {
    border: none;
    height: calc(100dvh - 241px);
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .microsites-container {
    min-height: fit-content;
    @include main-body-container-1024;
    .search-and-filter-wrapper {
      margin: 8px 15px 8px 15px;

      .arrow-container {
        .title {
          font-size: 13px;
        }
      }

      .search-and-filter-container {
        .title-bar-search-wrapper input {
          height: 30px;
        }
        .filter-container {
          .alpha-filter-btn {
            min-height: 35px;
          }

          .filter-icon {
            min-height: 35px;
          }
        }
      }
    }

    .list-and-view-container {
      // display: grid;
      // grid-template-columns: 1fr 1fr;
      margin-bottom: 15px;
      .list-container {
        // width: 49% !important;
        flex: 1;
        .list-card-container {
          //   gap: 15px;
          //   padding: 0px 10px;
          //   grid-template-columns: 1fr !important;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          height: auto;
          .motion-wrapper {
            .card-wrapper {
              margin-left: 0px;
              .card-container {
                height: 9em;
                width: 469px;
                .card-image-container {
                  .media-image-container {
                    .image-preview {
                      height: 100px;
                      width: 160px;
                    }
                  }
                }
                .card-description-container {
                  .micro-share-and-logo-container {
                    .kebab-menu {
                      .share-icon {
                        height: 15px;
                      }
                    }
                  }
                  .sector-text-container {
                    font-size: 11px;
                  }
                }
              }
            }
          }
        }
      }
      .view-container {
        flex: 1;
        // height: calc(100dvh - 270px);
        height: calc(100dvh - 242px);

        // width: calc(100% / 2);
        .ant-layout {
          height: 95%;
        }
      }
    }
  }
}
// 150%
@media (min-width: 1280px) and (max-width: 1366px) {
  .microsites-container {
    // background-color: #908a8a;
    .search-and-filter-wrapper .arrow-container .title {
      font-size: 16px;
    }
    .list-and-view-container {
      display: flex;

      .list-container {
        flex: 2 !important;
        .list-card-container {
          grid-template-columns: 1fr 1fr;
          height: auto;
          max-height: calc(100dvh - 236px);
          .motion-wrapper .card-wrapper .card-container {
            height: 9em;
            .card-image-container .media-image-container .image-preview {
              height: 100px;
              width: 160px;
            }
            .card-description-container .sector-text-container {
              font-size: 9px;
            }
          }
        }
      }
      .view-container {
        flex: 1;
        // height: calc(100dvh - 270px);
        // width: calc(100% / 2);
        height: calc(100dvh - 167px);
        .ant-layout {
          // height: 100%;
        }
      }
    }
  }
}
// 125%
@media (min-width: 1367px) and (max-width: 1600px) {
  .microsites-container {
    // background-color: #908a8a;

    .search-and-filter-wrapper .arrow-container .title {
      font-size: 16px;
    }
    .list-and-view-container {
      display: flex;

      .list-container {
        flex: 2 !important;
        .list-card-container {
          grid-template-columns: 1fr 1fr;
          height: auto;

          max-height: calc(100dvh - 272px);
          .motion-wrapper .card-wrapper .card-container {
            height: 9em;
            .card-image-container .media-image-container .image-preview {
              height: 100px;
              width: 160px;
            }
            .card-description-container .sector-text-container {
              font-size: 9px;
            }
          }
        }
      }
      .view-container {
        flex: 1;
        height: calc(100dvh - 214px);
      }
    }
  }
}

//100%
@media (min-width: 1920px) {
  .microsites-container {
    // background-color: #7e97b8;

    .search-and-filter-wrapper {
      margin: 11px 15px 15px 15px;

      .search-and-filter-container {
        .title-bar-search-wrapper {
          input {
            height: 35px;
          }
          input::placeholder {
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 1.6px;
            font-weight: 600;
          }
        }
        .filter-container {
          .alpha-filter-btn {
            min-height: 40px;
          }
        }
      }

      .arrow-container .title {
        font-size: 18px;
      }
    }

    .list-and-view-container {
      .list-container {
        .list-card-container {
          gap: 20px;
          padding: 0 20px;
          height: auto;
          .motion-wrapper .card-wrapper .card-container {
            height: 10em;
            .card-description-container .sector-text-container {
              font-size: 12px;
            }
          }

          .micro-share-and-logo-container .kebab-menu .share-icon {
            height: auto;
          }
        }
      }
    }
  }
}
