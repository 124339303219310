@import '../../styles/main.scss';

.ant-radio-button-wrapper {
  margin: 1%;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 5px;

  &:hover {
    color: $blue;
  }
}

.btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: 0%;
}

.ant-radio-button-wrapper-checked {
  color: #ffff !important;
  background-color: $blue-secondary !important;
  border: $blue-secondary !important;
}

.profile-submit-btn {
  width: auto;
  margin-left: 10px;
  height: 40px;
  background-color: #3067AE;
}

.profile-cancel-btn {
  width: auto;
  height: 40px;
  border: 1px solid #ccc;
}

:where(.css-dev-only-do-not-override-1bfkb9e).ant-radio-button-wrapper-checked:not(:where(.css-dev-only-do-not-override-1bfkb9e).ant-radio-button-wrapper-disabled)::before {
  display: none;
}

:where(.css-dev-only-do-not-override-1bfkb9e).ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

.ProfileFormitem {
  label {
    display: flex;
    justify-content: start;
  }
}

.form-item-edit {
  .ant-form-item-label {
    text-align: left;
  }
}

.partner-details {
  .col-1 {
    width: 80%;
    margin-left: 30px;
  }
}

.profile-pic-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.file-input {
  display: none;
}

.choose-file-label {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #59a638;
  text-align: center;
  background-color: #f0f0f0;
  padding: 10px 20px;
  border-radius: 5px;
  border: 2px solid #59a638;
  transition: background-color 0.3s ease;
}

.choose-file-label:hover {
  background-color: #59a638;
  color: white;
}

.remove-btn {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #ff4d4f;
  font-size: 14px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.remove-btn:hover {
  text-decoration: underline;
}

.edit-profile-backarrow {
  @include airbnbCereal-bold;
  display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  gap: 10px;
}

@media (min-width: 320px) {
  .wrapper-edit-profile {
    @include main-body-container-1024;
    overflow-x: hidden;
    padding-bottom: $footer-height-320;

    .ant-form-item-label {
      display: flex;
      align-items: center;
    }

    .edit-profile-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      //  margin: 10px;
      .edit-profile-backarrow {
        padding: 0px;
        font-size: 14px;

        img {
          height: 14px
        }
      }
    }

    .edit-profile-backarrow {
      font-size: 14px;

      // padding: 10px;
      img {
        height: 14px
      }
    }

    .row-spacing {
      margin-bottom: 20px;
      flex-direction: column;
    }


    .edit-detail-button-container {
      @include airbnbCereal-book;
      margin-right: 5px;

      .edit-details-button {
        font-size: 14px;
        font-weight: 500;
        color: #333;
        border-radius: 8px;
        padding: 4px 8px;
        background-color: #f5f5f5;
        border: 1px solid #ddd;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      .edit-details-button:hover {
        background-color: #e9e9e9;
        border-color: #ccc;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      }

      .edit-details-button:active {
        background-color: #e0e0e0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transform: scale(0.98);
      }
    }


    .readOnlyPhoneNumber {
      @include airbnbCereal-book;
      color: #7E7E7E;
      font-size: 14px;
      margin-top: -15px;
      letter-spacing: 1px;
    }

    .editForm-row-spacing {
      margin-bottom: 50px;

      >div {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
    }



    .style-label {
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
        width: 18px;
      }

      label {
        @include airbnbCereal-book;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 1px
      }
    }

    .form-btn-container {
      >div{
        margin-bottom: 0px
      }
      .profile-cancel-btn {
        @include airbnbCereal-book;
        font-size: 12px !important;
        height: 30px !important;
        width: 60px;

        span {
          margin-left: -5px
        }

      }

      .profile-submit-btn {
        @include airbnbCereal-book;
        font-size: 12px !important;
        height: 30px !important;
        width: 60px;
      }
    }

    .ProfileForm {
      margin: 9px !important
    }

    .label-values {
      @include airbnbCereal-book;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90vw;
      font-size: 14px;
      // margin-top: 10px
    }

    .ageGroup-buttons {
      @include airbnbCereal-book;
      font-size: 12px;
      margin-left: 0;
      margin-right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .gender-select {
      font-size: 26px !important;
    }

    .ant-select-selector {
      height: 40px !important;

      .ant-select-selection-item {
        @include airbnbCereal-book;
        font-size: 14px;
        display: flex;
        justify-content: start;
        align-items: center;
      }
    }

    .profile-pic-container {
      margin-top: 10px;
      font-size: 14px;
      flex-direction: row;
      align-items: center !important;
    }

    .profile-pic-preview {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 3px solid #ddd;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      // margin-top: 40px;
    }

    .profile-pic-preview img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .form-btn-container {
      @include airbnbCereal-book;
      display: flex;
      flex-direction: row;
      justify-content: end;
      width: 60vw;
      margin-top: 60px;
    }


    // ===============================
    .profile-card-container {

      .profile-form {

        label {
          font-size: 17px;
          font-weight: 600;
        }
      }

      .partner-details {
        width: 100%;
        margin-top: 10px;
        line-height: 1.9;

        .col-1 {
          width: 80%;

          .image-row {
            height: 80px !important;
          }

          .partner-info-label {
            @include airbnbCereal-book;
            // min-height: 50px;
            padding-left: 0px !important;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            letter-spacing: 1px;
            white-space: nowrap;

            img {
              margin-right: 5px;
              width: 15px;
            }
          }

          .partner-info-value {
            @include airbnbCereal-book;
            // min-height: 50px;
            padding: 0px !important;
            font-size: 14px;
            display: flex;
            align-items: center;
            letter-spacing: 1px;
            margin-left: 16px;
            height: 25px;

            p {
              margin: 0;
              margin-left: 6px;
            }
          }
        }
      }

      .user-profile-pic {
        width: 30px;
        margin-left: 5px;
        margin-top: 5px;
      }
    }

  }
}

@media (min-width: 768px) {
  .wrapper-edit-profile {
    padding-bottom: unset;
    // .ant-form-item-label {}
    .edit-profile-header {
      margin: 10px;
      // .edit-profile-backarrow {
      // img
    }

    .edit-profile-backarrow {
      padding: 10px;
    }

    .row-spacing {
      flex-direction: row;
    }

    .edit-detail-button-container {
      margin-right: 15px;

      .edit-details-button {
        padding: 8px 16px;
      }
    }

    .readOnlyPhoneNumber {
      margin-top: unset;
    }

    .editForm-row-spacing {
      margin-bottom: 35px;

      >div {
        display: flex;
        flex-direction: row;
        gap: 25px;
      }
    }

    .style-label {
      img {
        margin-right: 15px;
        width: unset;
      }
    }

    .label-values {
      width: 400px;
      font-size: 14px;
      letter-spacing: 1px;
    }

    .ageGroup-buttons {
      font-size: 14px;
    }

    .ant-select-selector {
      height: 35px !important;
    }

    .profile-pic-container {
      font-size: 14px;
      flex-direction: column;
    }

    .profile-pic-preview {
      width: 80px;
      height: 80px;
    }

    .form-btn-container {
      width: 157%;
      margin-top: 50px;
    }

    // =============================
    .profile-card-container {

      // .profile-form {
      //   label {
      .partner-details {
        margin-top: unset;
        line-height: unset;

        .col-1 {

          // .image-row {
          .partner-info-label {
            min-height: 50px;
            padding-left: 15px !important;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1px;

            img {
              margin-right: 15px;
              width: unset;
            }
          }

          .partner-info-value {
            min-height: 50px;
            padding: 0px !important;
            letter-spacing: 1px;
            margin-left: unset;
            height: unset;
          }
        }
      }

      .user-profile-pic {
        width: 40px;
        margin-left: 5px;
        margin-top: unset;
      }
    }

  }
}

@media (min-width: 820px) {
  .wrapper-edit-profile {
    padding-bottom: unset;
    .edit-profile-header {
      // .ant-form-item-label {}
      margin: 10px;
      // .edit-profile-backarrow {
      // img
    }

    .edit-profile-backarrow {
      padding: 10px;
    }

    .row-spacing {
      margin-bottom: 30px;
    }

    .edit-detail-button-container {
      margin-right: 15px;
      // .edit-details-button {
      // }
    }

    // .readOnlyPhoneNumber {

    .editForm-row-spacing {
      margin-bottom: 45px;

      >div {
        display: flex;
        flex-direction: row;
        gap: 25px;
      }
    }

    .style-label {
      label {
        font-weight: 700;
        font-size: 14px;
      }
    }

    .form-btn-container {
      .profile-cancel-btn {
        @include airbnbCereal-book;
        font-size: 14px !important;
        height: 35px !important;
        width: 70px;

        span {
          margin-left: -5px
        }

      }

      .profile-submit-btn {
        @include airbnbCereal-book;
        font-size: 14px !important;
        height: 35px !important;
        width: 70px;
      }
    }

    .ProfileForm {
      margin: 9px !important
    }

    // .label-values {
    // }

    // .ageGroup-buttons {
    // }

    .gender-select {
      font-size: 26px !important;
    }

    .ant-select-selector {
      height: 35px !important;

      // .ant-select-selection-item {
    }

    // .profile-pic-container {

    // .profile-pic-preview {
    // }

    // .profile-pic-preview img {

    .form-btn-container {
      width: 150%;
      margin-top: 50px;
    }

    // =================================
    .profile-card-container {

      // .profile-form {
      // label {
      .partner-details {
        .col-1 {
          // .image-row {
          // .partner-info-label {
          // img {        

          // .partner-info-value {
        }
      }

      .user-profile-pic {
        width: 40px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .wrapper-edit-profile {
    padding-bottom: unset;
    .ant-radio-group-large {
      label {
        height: 35px !important;
      }
    }

    .row-spacing {
      margin-bottom: 20px;
    }

    // .edit-profile-header {
    // .edit-profile-backarrow {

    // .edit-profile-backarrow {


    .edit-detail-button-container {
      .edit-details-button {
        font-size: 12px;
      }
    }

    // .readOnlyPhoneNumber {

    .editForm-row-spacing {
      margin-bottom: 35px;

      >div {
        display: flex;
        flex-direction: row;
        gap: 40px;
      }
    }

    // .style-label {
    // img {
    // label {

    .form-btn-container {
      // .profile-cancel-btn {
      // span {

      // .profile-submit-btn {
    }

    // .ProfileForm {

    // .label-values {

    // .ageGroup-buttons {

    // .gender-select {

    .ant-select-selector {
      height: 35px !important;

      // .ant-select-selection-item {
    }

    // .profile-pic-container {

    // .profile-pic-preview {

    // .profile-pic-preview img {

    .form-btn-container {
      width: 164%;
      margin-top: 30px;
    }

    // =============================
    .profile-card-container {

      // .profile-form {
      // label {    
      .partner-details {
        // .col-1 {
        // .image-row {
        // .partner-info-label {
        // img {
      }

      // .partner-info-value {
    }
  }

  // .user-profile-pic {  
}

@media (min-width: 1366px) {
  .wrapper-edit-profile {
    @include main-body-container-1366;
    padding-bottom: unset;
    .ant-radio-group-large {
      label {
        height: 35px !important;
      }
    }

    .row-spacing {
      margin-bottom: 10px;
    }

    .edit-profile-header {
      margin: 10px;

      .edit-profile-backarrow {
        font-size: 16px;
        // img { 
      }
    }

    .edit-profile-backarrow {
      font-size: 16px;

      img {
        height: 17px;
        width: 20px;
      }
    }

    .edit-detail-button-container {
      .edit-details-button {
        font-size: 14px;
      }
    }

    .readOnlyPhoneNumber {
      font-size: 16px;
    }

    .editForm-row-spacing {
      margin-bottom: 22px;

      >div {
        display: flex;
        flex-direction: row;
        gap: 100px;
      }
    }

    .style-label {

      // img {
      label {
        font-weight: 700;
        font-size: 16px;
      }
    }

    .form-btn-container {
      .profile-cancel-btn {
        font-size: 16px !important;
        height: 40px !important;
        width: 80px;

        span {
          margin-left: -5px
        }
      }

      .profile-submit-btn {
        font-size: 16px !important;
        height: 40px !important;
        width: 80px;
      }
    }

    // .ProfileForm {
    .label-values {
      width: 400px;
      font-size: 16px;
    }

    .ageGroup-buttons {
      font-size: 16px;
    }

    // .gender-select {
    .ant-select-selector {
      height: 35px !important;

      .ant-select-selection-item {
        font-size: 16px;
      }
    }

    .profile-pic-container {
      font-size: 16px;
    }

    // .profile-pic-preview {

    // .profile-pic-preview img {

    .form-btn-container {
      width: 169%;
      margin-top: 30px;
    }

    // =====================
    .profile-card-container {

      // .profile-form {
      // label {
      .partner-details {
        .col-1 {

          // .image-row {
          .partner-info-label {
            font-size: 16px;
            // img {
          }

          .partner-info-value {
            font-size: 16px;
          }
        }
      }

      .user-profile-pic {
        margin-left: 5px;
      }
    }

    // ======================
  }
}

@media (min-width: 1920px) {
  .wrapper-edit-profile {
    @include main-body-container-1920;
    padding-bottom: unset;
    .ant-radio-group-large {
      label {
        height: 40px !important;
      }
    }

    .row-spacing {
      margin-bottom: 30px;
    }

    .edit-profile-header {
      margin: 10px;

      .edit-profile-backarrow {
        font-size: 18px;

        img {
          height: 17px;
          width: 20px;
        }
      }
    }

    .edit-profile-backarrow {
      font-size: 18px;

      img {
        height: 17px;
        width: 20px;
      }
    }


    .edit-detail-button-container {
      margin-right: 15px;

      .edit-details-button {
        font-size: 18px;
      }
    }

    .readOnlyPhoneNumber {
      font-size: 18px;
    }

    .editForm-row-spacing {
      margin-bottom: 35px;
    }

    .style-label {
      img {
        margin-right: 10px;
      }

      label {
        font-size: 18px;
      }
    }


    .form-btn-container {
      .profile-cancel-btn {
        font-size: 18px !important;
        height: 50px !important;
        width: 100px;
      }

      .profile-submit-btn {
        font-size: 18px !important;
        height: 50px !important;
        width: 100px;
      }

      div {
        margin-bottom: 0px;
      }
    }

    // .ProfileForm {

    .label-values {
      font-size: 18px;
    }

    .ageGroup-buttons {
      font-size: 18px;
    }

    // .gender-select {
    //   font-size: 216px !important;
    // }

    .ant-select-selector {
      height: 40px !important;

      .ant-select-selection-item {
        font-size: 18px;
      }
    }

    .profile-pic-container {
      font-size: 18px;
    }

    .profile-pic-preview {
      width: 100px;
      height: 100px;
    }

    // .profile-pic-preview img {

    .form-btn-container {
      width: 184%;
      margin-top: -10px;
      gap: 10px;
    }

    // ===============================
    .profile-card-container {
      // .profile-form {
      // label {

      .partner-details {
        .col-1 {

          // .image-row {
          .partner-info-label {
            min-height: 50px;
            padding-left: 15px !important;
            font-size: 18px;
            font-weight: 600;

            img {
              margin-right: 10px;
            }
          }

          .partner-info-value {
            min-height: 50px;
            font-size: 18px;
          }
        }
      }

      .user-profile-pic {
        width: 60px;
        margin-left: 5px;
      }
    }

    // ===========================

  }
}