@media (min-width: 360px) {
  .background-container {
    .float-container {
      position: fixed;
      bottom: -1px;
      z-index: 1001;
      width: 100%;

      .float-group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        width: 100%;
        padding: 5px;
        padding-bottom: 12px;
        // padding-left: 20px;
        // gap: 20px;
        align-items: center;
      }
    }

    .fav-icon {
      margin-top: 5px;
    }

    .icon-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-text {
        display: none;
      }
    }

    .search-btn {
      border: 0px;
      padding: 5px 10px;
      border-radius: 5px;
      background: #569dfa;
      color: white;
      align-items: center;
    }

    .filter-container {
      text-align: end;
      padding: 5px;

      .filter-icon {
        margin-right: 10px;
        padding: 5px 15px;
        align-items: center;
      }

      .alpha-filter-btn {
        width: auto;
        font-size: 15px;
        margin-right: 3px;
        min-height: auto;
        padding: 1px 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;
      }

      .alpha-filter-btn:hover {
        border: none;
        transform: scale(1.01);
      }

      .alpha-filter-btn:focus {
        border: none;
      }

      .share-dropdown {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .filter-icon,
      .view-btn {
        width: auto;
        font-size: 15px;
        margin-right: 3px;
        min-height: auto;
        padding: 3px 13px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #569dfa;

        .view-img {
          // padding-top: 5px;
          padding-left: 15%;
          padding-bottom: 5%;
          padding-right: 10%;
          transform: scale(1.7);
        }
      }

      .alpha-filter-btn-white {
        width: auto;
        font-size: 15px;
        margin-right: 3px;
        min-height: auto;
        padding: 3px 13px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
      }

      // .alpha-filter-btn{
      //   font-size: 12px;
      // }

      // .view-btn {
      //   padding: 5px 15px;
      // }
    }

    .filter-options {
      margin-left: 10px;

      span {
        display: inline-flex;
        background-color: #d4d4d4;
        // border: 0.5px solid black;
        color: black;
        // padding: 3px 8px;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 8px;
        // padding-right: 5px;
        font-size: 11px;
        font-weight: 400;
        border-radius: 15px;
        align-items: center;

        .anticon .anticon-close .ant-tag-close-icon {
          border: none !important;
        }

        svg {
          font-size: 13px;
          color: black;
          // border: 0px;
        }
      }
    }

    .home-page-card-item {
      position: relative;

      .kebab-menu {
        background-color: #fff;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
        cursor: pointer;
      }
    }

    .org-card-container {
      .image-container {
        display: flex;
        justify-content: center;
        width: 40%;
        // height: 10vh;
        // background-color: #d7d7d7;
        box-shadow: 0px 0px 3px #0000001a;
        // border: 0.5px solid #c5c5c5;
        // border-radius: 8px;

        .media {
          // object-fit: cover;
          height: 100%;
          width: 100%;
          display: flex;
          // border: 2px solid green;
          align-items: center;
        }

        .bookMarked {
          // height: 40px;
          left: 20px;
          top: 8px;
          position: absolute;
          z-index: 1000;
        }

        .youtube {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          .ytBtnOverlay {
            height: 40px;
            position: absolute;

            // top: 50%;
            // left: 15%;
            // transform: translate(-50%, -50%);
            // padding: 10px;
          }
        }

        // iframe {
        //   .vp-center {
        //     .vp-player-ui-overlays {
        //       .player.player-tiny {
        //         .vp-controls {
        //           display: none;
        //         }
        //       }
        //     }
        //   }
        // }
        .slider-Img {
          display: flex;
          width: 100%;
          object-fit: cover;
          height: 100%;
          // max-height: 60px;
          padding: 0px;
          // margin-left: -18%;
          // background-color: gainsboro;
          border-radius: 5px;
          // margin-bottom: 10px;
          // margin-top: 10px;
          overflow: hidden;

          .home-preview-img {
            width: 100%;
            // max-height: 50px;
            height: auto;
          }
        }
      }
    }

    .text-container {
      width: 55%;
    }
  }

  .no-data-warning {
    height: 50% !important;
  }

  .card-item {
    // margin: 10px;
    // border-radius: 5px;
    // border: 1px solid #d1d1d1;
    // box-shadow: none;
    box-shadow: 0px 0px 3px #0000001a;

    // border: 0.5px solid #c5c5c5;
    // border-radius: 8px;
  }

  .drop-down-select {
    width: 100%;
  }
}

.ant-space {
  gap: 0px !important;
}

.ant-tabs-nav-operations {
  .ant-tabs-nav-more {
    display: none;
  }
}

// .label-text {
//   font-size: 18px;
//   font-weight: 500;
// }

.search-in-modal {
  margin-top: 20px;
  margin-bottom: 20px;

  .search-bar .search-btn {
    display: none;
    padding: 0px;
  }

  .search-bar .search-input-container {
    width: 300px;
  }
}

.modal-container {
  top: 25px;
  padding-left: 10px;
  padding-right: 10px;

  .ant-modal-close {
    color: white;
    font-weight: bolder;
  }

  .ant-modal-content {
    top: 0vh;
    padding: 0px;
    box-shadow: 3px 5px 16px #0000003b;
    border: 3px solid #5aa2fb;
    border-radius: 20px;
    // min-height: 60em;
  }

  .ant-modal-header {
    padding: 20px;
    background-color: #5aa2fb;
    margin: -1px -1px 0 -1px;
    border-radius: 20px 20px 0 0;

    .ant-modal-title {
      color: white;
    }
  }

  .ant-modal-body {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 25px;
    padding-bottom: 15px;
  }

  .ant-modal-footer {
    padding-bottom: 25px;
    padding-right: 16px;
    text-align: center;

    .ant-btn:nth-child(1) {
      width: 130px;
      height: 40px;
      background-color: #908a8a;

      span {
        color: white !important;
      }
    }

    .ant-btn:nth-child(2) {
      width: 130px;
      height: 40px;
      background-color: #569dfa;

      span {
        color: white !important;
      }
    }
  }
}

.home-page-card-item .ant-card-body {
  display: flex;
  padding: 8px 12px;
  height: 8.5em;

  :hover {
    cursor: pointer;

    // box-shadow: 0px 0px 5px #f00606;
    // // box-shadow: 0px 0px 5px #0000001a;
    // transition: linear;
  }
}

.view-img {
  align-items: flex-end;
}

.card-hover:hover {
  box-shadow: 0px 5px 20px #0000001a;
  transition: box-shadow 0.3s ease-in-out;
}

.card-hover::after {
  box-shadow: 0px 5px 20px #0000001a;
  transition: box-shadow 0.3s ease-in-out;
}

.home-page-card-item {
  overflow: hidden;

  .preview-image {
    // padding-top: 3%;
    height: 40%;
    max-width: 90%;
    // border-radius: 8px;
    //     max-height: 80px;
    //     padding: 10px;
    flex-shrink: 0;
    // min-width: 30%;
    // min-height: 100%
    // transform: scale(0.9);
    // width: 90%;
  }

  .text-container {
    padding-left: 12px;
    font-family: Arial, Helvetica, sans-serif;
    // font-weight: bold;

    .logo-container {
      width: 100%;
      // border: 1px solid #ccc;
      padding: 3px;
      margin: auto 0px;
      display: flex;
      justify-content: space-between;

      .logo-image {
        height: 100%;
        max-height: 40px;
        display: flex;
      }
    }

    .rating-icon {
      padding-right: 2px;
    }
  }
}

.sector-info-container {
  // margin-top: 10px;
  margin-top: 1%;
  // margin-left: 5%;
  overflow: hidden;
  // text-overflow: ellipsis;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none;
}

.remove-btn {
  position: absolute;
  top: 27px;
  right: -20px;
  color: #569dfa;
}

.microsite-details {
  // ;
  font-size: 11px;
  display: -webkit-box;
  // justify-content: space-between;
  // white-space: nowrap;
  // white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (min-width: 480px) {
  .background-container {
    .org-card-container {
      .image-container {
        width: 40%;
        // max-width: 150px;
      }
    }

    .float-container {
      position: fixed;
      bottom: 10px;
      left: 50%;
      margin-left: -235px;
      z-index: 1001;

      .float-group {
        display: grid;
        grid-template-columns: 0.7fr 1fr 1fr;
        background-color: white;
        border-radius: 50px;
        border: 1px solid #0000001a;
        box-shadow: 10px 10px 13px #0000001a;
        width: 440px;
        padding: 10px;
        padding-right: 13px;
        gap: 20px;
        align-items: center;
      }
    }
  }
}

@media (min-width: 768px) {
  /* Track */
  html {
    overflow: auto;
  }

  // body {
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   bottom: 0px;
  //   right: 0px;
  //   padding: 0px;
  //   overflow-y: scroll;
  //   overflow-x: hidden;
  // }

  .alpha-filter {
    margin-right: 10px;
  }

  .alpha-filter-btn {
    width: auto;
    font-size: 16px;
    min-height: auto;
    padding: 1px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
  }

  .alpha-filter-btn:hover {
    border: none;
    transform: scale(1.01);
  }

  .alpha-filter-btn:focus {
    border: none;
  }

  .fav-icon {
    margin-top: 0px !important;
    margin-left: 10px;
  }

  .icon-container {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    .icon {
      color: #fff;
    }

    .icon-text {
      margin-left: 3px;
      width: 100%;
      display: contents !important;
      // color: #569dfa;
    }
  }

  .filter-icon,
  .view-btn {
    width: auto;
    font-size: 16px;
    min-height: auto;
    padding: 5px 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #569dfa;

    .view-img {
      // padding-top: 5px;
      padding-left: 15%;
      padding-bottom: 5%;
      padding-right: 10%;
      transform: scale(1.7);
    }
  }

  .alpha-filter-white {
    margin-right: 10px;
  }

  .alpha-filter-btn-white {
    width: auto;
    font-size: 16px;
    min-height: auto;
    padding: 5px 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  .background-container {
    .org-card-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .home-page-card-item .ant-card-body {
      display: flex;
      padding: 8px 12px;
      height: 8.5em;
      box-shadow: 0px 0px 3px #0000001a;
    }
  }
}

@media (min-width: 1024px) {
  .background-container {
    .org-card-container {
      width: 80%;
      margin: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .image-container {
        align-items: center;
        width: 40%;
      }
    }

    .home-page-card-item {
      .ant-card-body {
        display: flex;
        padding: 8px 12px;
        height: 10em;
        box-shadow: 0px 0px 3px #0000001a;
      }
    }
  }
}

@media (min-width: 1440px) {
  .background-container {
    .org-card-container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      .image-container {
        max-width: 200px;
      }

      .text-container {
        width: 60%;
      }
    }

    // .home-page-card-item .ant-card-body {
    //   height: 12em;
    // }
  }
}

@media (min-width: 2160px) {
  .background-container {
    .org-card-container {
      // display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      .text-container {
        width: 70%;
      }
    }

    .home-page-card-item .ant-card-body {
      height: 12em;
    }
  }
}
