.map-container {
  height: calc(100vh - 130px);
  overflow: hidden;
}

@media (min-width: 375px) {
  .map-container {
    height: calc(100vh - 100px);
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  .map-container {
    height: calc(100vh - 125px);
    overflow: hidden;
  }
}
.leaflet-container {
  height: 100vh;
}

.leaflet-div-icon {
  background-color: transparent !important;
  border: none !important;
}

.leaflet-popup-close-button {
  display: none;
}

.leaflet-popup-content {
  margin: 0px !important;
}

.leaflet-popup-content-wrapper {
  border: 2px solid red;
  cursor: pointer;
}

.leaflet-popup-tip {
  border-right: 2px solid red;
  border-bottom: 2px solid red;
}

// .popupCustom .leaflet-popup-tip,
// .popupCustom .leaflet-popup-content-wrapper {
//   background: #e0e0e0;
//   color: #234c5e;
//   border: 2px solid red;
// }
.popup-content {
  margin: 13px 5px 13px 5px;
  display: flex;
  justify-content: center;
}

.ant-layout-content {
  z-index: 1;
}

.locateBtn {
  background-color: white;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.51);
  z-index: 1000;
  margin-right: 10px !important;
  top: 80px !important;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all !important;

  :hover {
    cursor: pointer;
  }

  .locateMeImg {
    // transform: scale(15);
    width: 23px;
    height: 23px;
  }
}

// .leaflet-layer,
// .leaflet-control-zoom-in,
// .leaflet-control-zoom-out,
// .leaflet-control-attribution {
//   filter: invert(100%) hue-rotate(180deg) brightness(65%) contrast(90%);
// }
