.App {
  text-align: center;
  color: green;
}

/* span {
  color: #242424;
} */

.Toastify__toast {
  width: 500px; 
  /* max-width: 25dvw;  */
  border-radius: 8px;
  font-size: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.Toastify__toast {
  border-left: 5px solid #4caf50; /* Green border for success */
  background-color: #E9F3E8;
}

.Toastify__toast--error {
  border-left: 5px solid #f44336; /* Red border for error */
  background-color: #F8E9E9;
}

.Toastify__toast--warning {
  border-left: 5px solid #ff9800; /* Orange border for warning */
  background-color: #FCF9E9;
}

.Toastify__toast--info {
  border-left: 5px solid #2196f3; /* Blue border for info */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.background-container {
  min-height: auto;
  width: 100%;
  height: calc(100% - 150px);
  background-size: cover;
  /* position: absolute; */
  /* z-index: -1000; */
}

.modal-container {
  top: 50px;
}

/* input[type=text] {
  border-left: 0px !important;
  border-right: 0px !important;
  border-top: 0px !important;
  border-bottom: 1px solid #ccc !important;
} */

.profileDropDown {
  position: absolute;
  z-index: 2;
  right: 0px;
  background-color: white;
  top: 50px;
  border-radius: 0px 0px 20px 20px;
  border: 1px solid lightblue;
  color: black;
}

@media (min-width:360px) and (max-width:759px) {
  .Toastify__toast {
    width: 90dvw;
    margin: 10px 10px;
  }
}