@import "./variables";
@import "./colors";
@import "./fonts";
@import "./buttons";

body {
    margin: 0;

    a {
        text-decoration: none;
        color: initial;
    }

    p {
        margin: 6px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }
}

@mixin font-xsmall {
    font-size: 13px;
}

@mixin font-small {
    font-size: 12px;
}

@mixin font-medium {
    font-size: 14px;
}

@mixin font-large {
    font-size: 18px;
}

@mixin font-xlarge {
    font-size: 20px;
}

//////////////////////////////////////

@mixin main-body-container-320 {
    //  100dvh - navHeight - footerHeight
    // min-height: calc(100dvh - $nav-height-320 - $footer-height-320);
    height: calc(100dvh - 120px);
    overflow-y: auto;
}

@mixin main-body-container-768 {
    //  100dvh - navHeight - footerHeight
    min-height: calc(100dvh - $nav-height-768 - $footer-height-768);
}

@mixin main-body-container-1024 {
    //  100dvh - navHeight - footerHeight
    min-height: calc(100dvh - $nav-height-1024 - $footer-height-1024);
}

@mixin main-body-container-1366 {
    //  100dvh - navHeight - footerHeight
    min-height: calc(100dvh - $nav-height-1366 - $footer-height-1366);
}

@mixin main-body-container-1440 {
    //  100dvh - navHeight - footerHeight
    min-height: calc(100dvh - $nav-height-1440 - $footer-height-1440);
}

@mixin main-body-container-1920 {
    //  100dvh - navHeight - footerHeight
    min-height: calc(100dvh - $nav-height-1920 - $footer-height-1920);
}
////////////////

@mixin AirbnbCereal-W-bd-font {
    font-family: "AirbnbCereal", sans-serif;
    font-weight: bold;
    letter-spacing: 1.6px;
}

@mixin AirbnbCereal-W-bd-font-1920 {
    @include AirbnbCereal-W-bd-font();
    font-size: 20px;
}

///////////////////////

$pink: #ff184e;
$black: #000000;

// BORDER COLOR
$border-gray: #bac4d2;
$arrow-color: #7e97b8;

//////////////////////////////////////
// Base font size for body
html,
body {
    font-size: 20px; // Default to 20px for 1920px and larger
}

//Media Queries
@media (max-width: 1920px) {
    html,
    body {
        font-size: $base-font-size-1920;
    }
}

@media (max-width: 1440px) {
    html,
    body {
        font-size: $base-font-size-1440;
    }
}

@media (max-width: 1366px) {
    html,
    body {
        font-size: $base-font-size-1366;
    }
}

@media (max-width: 1024px) {
    html,
    body {
        font-size: $base-font-size-1024;
    }
}

@media (max-width: 768px) {
    html,
    body {
        font-size: $base-font-size-768;
    }
}

@media (max-width: 320px) {
    //  will not be required
    html,
    body {
        font-size: $base-font-size-320;
    }
}
