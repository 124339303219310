@import "../../../styles/main.scss";

.About-Us {
    .sub-tabs-content-holder {
        box-shadow: none !important;
        border: none !important;
        margin: 0 !important;
    }
}

@media (min-width: 320px) {
    .About-Us {
        .sub-tabs-nav-list {
            background-color: #f0f2f6;
            margin: 0;
            border-radius: 7px;
            .sub-tabs-nav {
                border: 1px solid red;
                margin-bottom: 0 !important;
                .sub-tabs-nav-list {
                    margin: 0 -10px;
                }
                .sub-tabs-nav-wrap {
                    &::after,
                    &::before {
                        box-shadow: none;
                    }
                }
                &::after,
                &::before {
                    border: none;
                }
            }
            .sub-tabs-ink-bar {
                width: 0 !important; // Disable ink bar
            }

            .sub-tabs-tab {
                border: none;
                background-color: $white-primary;
                @include airbnbCereal-book;
                font-size: 11px;
                letter-spacing: 1.2px;
                line-height: 14px;
                color: $black-primary;
                transition: color 0.3s ease;
                border-radius: 7px !important;
                &:hover {
                    color: $white-primary;
                }
            }
            .sub-tabs-tab-active {
                padding: 10px;
                border: none;
                border-radius: $base-border-radius-400;
                box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.12);
                background-color: $blue;
                border-radius: 7px;

                .sub-tabs-tab-btn {
                    color: $white-primary;
                }
            }
            .sub-tabs-tab + .sub-tabs-tab {
                margin-left: 10px !important;
            }
        }
        .sub-tabs-top > .sub-tabs-nav::before {
            border: none;
        }
        .sub-tabs-nav {
            margin: 0;
        }
    }
}
@media (min-width: 768px) {
    .About-Us {
        .sub-tabs-content-holder {
            padding: 0 15px 0 0px;
            min-height: 100%;
            min-height: 185px;
            height: calc(100vh - $nav-height-768 - $footer-height-768 - 370px);
            overflow: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #4d4b4e;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #4d4b4e;
            }
        }
        .sub-tabs-nav-list {
            margin: 0;

            .sub-tabs-tab {
                border: none;
                @include airbnbCereal-book;
                font-size: 13px;
                letter-spacing: 1.2px;
                line-height: 20px;
                color: $black-primary;
                transition: color 0.3s ease;
                border-radius: 0px !important;
                padding: 10px 16px;
                &:hover {
                    color: $white-primary;
                    border-radius: 7px;
                    background-color: $blue-secondary;
                    transition: none;
                    border-radius: 7px !important;
                    transition: color 100ms ease;
                    transition: all 250ms ease;
                }
            }
            .sub-tabs-tab-active {
                padding: 10px 16px;
                border: none;
                box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.12);
                background-color: $blue;
                border-radius: 7px !important;
                .sub-tabs-tab-btn {
                    color: $white-primary;
                }
                &:hover {
                    background-color: $blue;
                }
            }
        }
        .sub-tabs-nav {
            margin-bottom: 15px;
            .sub-tabs-nav-wrap {
                &::after,
                &::before {
                    box-shadow: none;
                    content: none;
                    width: 0;
                }
            }
            &::before {
                border: none;
            }
        }
    }
}
@media (min-width: 1024px) {
    .About-Us {
        .sub-tabs-content-holder {
            padding: 0 15px;
            min-height: 100%;
            min-height: 185px;
            height: calc(100vh - $nav-height-1024 - $footer-height-1024 - 440px);
            overflow: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #4d4b4e;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #4d4b4e;
            }
        }
        .sub-tabs-nav {
            margin-bottom: 16px;
        }
        .sub-tabs-nav-list {
            margin: 0 15px;
            .sub-tabs-tab {
                font-size: 13px;
                letter-spacing: 1.2px;
                line-height: 25px;
            }
        }
    }
}
@media (min-width: 1366px) {
    .About-Us {
        .sub-tabs-content-holder {
            height: calc(100vh - $nav-height-1366 - $footer-height-1366 - 430px);
            min-height: 172px;
        }
        .sub-tabs-nav-list {
            .sub-tabs-tab {
                font-size: 13px;
                line-height: 26px;
            }
        }
    }
}
@media (min-width: 1440px) {
    .About-Us {
        .sub-tabs-content-holder {
            height: calc(100vh - $nav-height-1440 - $footer-height-1440 - 460px);
            min-height: 251px;
        }
        .sub-tabs-nav-list {
            .sub-tabs-tab {
                font-size: 14px;
                line-height: 27px;
            }
        }
    }
}
@media (min-width: 1920px) {
    .About-Us {
        .sub-tabs-content-holder {
            height: calc(100dvh - $nav-height-1920 - 555px);
            min-height: 230px;
        }
        .sub-tabs-nav-list {
            .sub-tabs-tab {
                font-size: 14px;
            }
        }
    }
}
