@import "../../styles/main.scss";

.searchContainer {
    display: flex;
    justify-content: flex-end;
}

@media (min-width: 320px) {
    .news-container {
        .search-container {
            margin: 10px 15px;
            display: flex;
            justify-content: end;
        }
    }
}
@media (min-width: 768px) {
    .news-container {
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1366px) {
}
@media (min-width: 1440px) {
}
@media (min-width: 1920px) {
    .news-container {
        .search-container {
            input {
                height: 30px;
                @include airbnbCereal-book;
                font-size: 15px;
                letter-spacing: 1.2px;
                line-height: 21px;
                color: $black-primary;
                &::placeholder {
                    @include airbnbCereal-book;
                    font-size: 15px;
                    letter-spacing: 1.2px;
                    line-height: 21px;
                    color: #7e7e7e;
                }
            }
        }
    }
}
