@import "../../styles//main.scss";

@media (min-width: 320px) {
    .customSelect .ant-select-item-option-content:hover::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
    }

    .profile-modal-container {
        .ant-modal-footer {
            display: flex;
            justify-content: center;
            .profile-submit-btn {
                @include airbnbCereal-book;
                font-size: 16px;
                letter-spacing: 1.6px;
                line-height: 21px;
                width: 186px;
                height: 40px;
                margin-bottom: 23px;
                box-shadow: 0px 1px 2px #c0cee24f;
                border: 0.5px solid $blue-secondary;
                border-radius: $base-border-radius-400;
            }
        }
        padding: 10px !important;
        .ant-modal-content {
            padding: 15px 20px;
        }
        .ant-modal-body {
            padding: 0;
            .title-bar {
                @include heading-font-320;
                padding: 0 0 8px 0;
                border-bottom: 1px solid $border-gray;
                margin-bottom: 20px;
            }
            label {
                @include primary-label-font-320;
                margin-bottom: 10px;
            }
            .radio-group-space {
                padding: 0;
                button {
                    @include secondary-input-placeholder-font-320;
                    color: $black;
                    &:focus {
                        color: #ffff;
                        background-color: #7e97b8 !important;
                        border: #7e97b8 !important;
                    }
                }
                .age-radio {
                    width: 80%;
                    overflow: hidden;
                    display: flex;
                    justify-content: space-around;
                    margin-bottom: 1.5rem;
                    .create-age-radio {
                        .ant-radio-button-wrapper {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            min-width: 85px;
                            height: 40px;
                        }

                        button {
                            align-items: center;
                            display: inline-flex;
                            &:before {
                                color: #ffff;
                                background-color: #7e97b8 !important;
                                border: #7e97b8 !important;
                            }
                        }
                    }
                    .create-age-radio {
                        display: flex !important;
                        justify-content: left;
                        flex-wrap: wrap;
                        .ant-radio-button-wrapper-checked {
                            background-color: #7e97b8 !important;
                            border: #7e97b8 !important;
                        }
                        .ant-radio-button-wrapper:hover {
                            background-color: #7e97b8 !important;
                            border: #7e97b8 !important;
                            color: white !important;
                        }
                        .ant-radio-button-checked {
                            background-color: #7e97b8 !important;
                            border: #7e97b8 !important;
                            border-radius: 8px;
                        }
                    }
                }
            }
            .alert-container {
                label {
                    display: flex;
                    justify-content: center;
                    color: silver;
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .profile-modal-container {
        width: 41rem !important;
        .ant-modal-content {
            padding: 15px 25px;
        }
        .ant-modal-body {
            .title-bar {
                @include primary-heading-font-1920;
                margin-bottom: 20px;
            }
            label {
                @include primary-label-font-1920;
                margin-bottom: 10px;
            }
            .radio-group-space {
                padding: 0 10px;

                .age-radio {
                    .create-age-radio {
                        .ant-radio-button-wrapper {
                            @include airbnbCereal-medium;
                            font-size: 1rem;
                            letter-spacing: 0.1rem;
                            line-height: 1.3rem;
                            width: 6rem;
                            height: 2.6rem;
                        }
                    }
                }
            }
        }
        .ant-select {
            .ant-select-selector {
                @include primary-input-placeholder-font-1920;
                height: 2.75rem;
                display: inline-flex;
                align-items: center;
            }
        }
        .ant-modal-footer {
            .profile-submit-btn {
                @include airbnbCereal-book;
                border-radius: $base-border-radius-1920;
                font-size: 0.9rem;
                letter-spacing: 0.09rem;
                line-height: 1.15rem;
                width: 178px;
                height: 46px;
                margin-bottom: 23px;
                box-shadow: 0px 1px 2px #c0cee24f;
                border: 0.5px solid $blue-secondary;
            }
        }
    }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: white !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
    width: 0px !important;
}
