@import '../../styles/main.scss';

.background-container {
.login-wall {
    .info-container {
        width: 100%;
        @include airbnbCereal-book;
        text-align: center;
        font-size: 17px;
        letter-spacing: 1.6px;
        line-height: 21px;
        margin: 10px 0px;
    }
}
}

@media (min-width:320px) and (max-width:759px){
    .background-container{
        .login-wall {
            .info-container {
                font-size: 10px;
            }
        }
    }
}
 @media(min-width:760px) and (max-width:1023px) {
    .background-container{
        .login-wall{
            .info-container{
                font-size: 16px;  
            }
        }
    }
 }

 //150%
 @media (min-width:1024px) and (max-width:1279px) {
    .background-container{
        .login-wall{
            .info-container{
                font-size: 16px;  
            }
        }
    }
 }

 //125%
 @media (min-width:1367px) and (max-width:1600px){
    .background-container{
        .login-wall{
            .info-container{
                font-size: 18px;  
            }
        }
    }
 }



//100%
 @media (min-width:1920px) {
    .background-container{
        .login-wall{
            .info-container{
                font-size: 19px;  
            }
        }
    }
 }


