@import "../../../../styles/main.scss";

@media (min-width: 320px) {
    .card-list-container {
        .card-container {
            // cursor: pointer;
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 10px;
            width: 100%;
            padding: 0;
            .card-item {
                cursor: pointer;
                padding: 4px 7px 4px 4px;
                width: 100%;
                background-color: $white-primary;
                border: 0.5px solid #dbe2ed;
                display: flex;
                // flex-grow: 1;
                position: relative;
                margin: 0;
                border-radius: 5px;
                .text-container {
                    padding: 10px 7px;
                    width: calc(100% - 60px);
                    .card-title {
                        @include airbnbCereal-bold;
                        font-size: 11px;
                        letter-spacing: 0.72px;
                        line-height: 12px;
                        margin-bottom: 5px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        // -webkit-line-clamp: 1;
                        line-clamp: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-transform: capitalize;
                    }
                    .card-description,
                    .news-card-date,
                    .news-card-description {
                        padding: 0;
                        margin: 0;
                        @include airbnbCereal-book;
                        font-size: 10px;
                        letter-spacing: 0.72px;
                        line-height: 12px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                        line-clamp: 4;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        &.experience-of-work {
                            border-radius: 5px;
                            padding: 6px;
                            display: inline;
                            background: #e6f4ff;
                            color: #0958d9;
                            border: 1px solid #91caff;
                            text-transform: capitalize;
                        }
                    }
                    .news-card-description {
                        -webkit-line-clamp: 3;
                        line-clamp: 3;
                    }
                    .news-card-date {
                        position: absolute;
                        bottom: 2px;
                        left: 10px;
                        @include airbnbCereal-medium;
                        // font-size: inherit;
                        display: flex;
                        align-items: center;
                        img {
                            margin-right: 5px;
                            padding-right: 2px;
                        }
                    }
                }
                .image-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // width: 40%;
                    width: unset;
                    // width: 370px;
                    height: 100%;
                    .youtube {
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        width: 100%;
                        position: relative;

                        .ytBtnOverlay {
                            position: absolute;
                            height: 40px;
                            width: 40px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }

                        .video-preview-image {
                            width: 100%;
                            aspect-ratio: 16 / 9; /* Ensures 16:9 aspect ratio */
                            object-fit: cover; /* Cover the area while maintaining the aspect ratio */
                        }

                        .yt-video-preview-image {
                            width: 100%; /* Full width of the container */
                            max-width: 170px !important;
                            aspect-ratio: 16 / 9; /* Maintain the 16:9 ratio for the image */
                            border-radius: $base-border-radius-1920;
                        }
                    }
                    .vimeo {
                        .vimeo-video-preview-image {
                            width: 100%; /* Full width of the container */
                            max-width: 193px !important;
                            border-radius: $base-border-radius-1920;
                        }
                    }
                    .media {
                        .slider-Img {
                            .home-preview-img {
                                max-width: 193px;
                                width: 100%;
                                aspect-ratio: 16 / 9; /* Ensures 16:9 aspect ratio */
                                object-fit: contain; /* Cover the area while maintaining the aspect ratio */
                                border-radius: $base-border-radius-1920;
                            }
                        }
                    }
                }
                .color-band {
                    position: absolute;
                    left: -1.2px;
                    top: 10px;
                    bottom: 10px;
                    width: 2.5px;
                    border-radius: 10px;
                }
            }
            .info-container {
                width: 100%;
                @include airbnbCereal-book;
                text-align: center;
                font-size: 10px;
                letter-spacing: 1.6px;
                line-height: 21px;
            }
        }
    }
}
@media (min-width: 768px) {
    .card-list-container {
        .card-container {
            margin-top: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 15px;
            width: 100%;
            padding: 0;
            .card-item {
                padding: 4px;
                width: 100%;
                background-color: $white-primary;
                border: 0.5px solid #dbe2ed;
                height: 140px;
                display: flex;
                // flex-grow: 1;
                position: relative;
                margin: 0;
                border-radius: 5px;
                .text-container {
                    position: relative;
                    padding: 10px 20px 10px 10px;
                    width: calc(100% - 210px);
                    .card-title {
                        @include airbnbCereal-bold;
                        font-size: 13px;
                        letter-spacing: 0.8px;
                        line-height: 21px;
                        margin-bottom: 10px;
                    }
                    .card-description,
                    .news-card-date,
                    .news-card-description {
                        padding: 0;
                        margin: 0;
                        @include airbnbCereal-book;
                        font-size: 11px;
                        letter-spacing: 0.5px;
                        line-height: 18px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                        line-clamp: 4;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .news-card-description {
                        -webkit-line-clamp: 3;
                        line-clamp: 3;
                        letter-spacing: 0.8px;
                        line-height: 22px;
                    }
                    .news-card-date {
                        position: absolute;
                        bottom: 6px;
                        left: 10px;
                        @include airbnbCereal-medium;
                        font-size: inherit;
                        display: flex;
                        align-items: center;
                        img {
                            margin-right: 8px;
                        }
                    }
                }
                .image-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // width: 40%;
                    width: unset;
                    // width: 370px;
                    height: 100%;
                    .youtube {
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        width: 100%;
                        position: relative;

                        .ytBtnOverlay {
                            position: absolute;
                            height: 40px;
                            width: 40px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }

                        .video-preview-image {
                            width: 100%;
                            aspect-ratio: 16 / 9; /* Ensures 16:9 aspect ratio */
                            object-fit: cover; /* Cover the area while maintaining the aspect ratio */
                        }

                        .yt-video-preview-image {
                            width: 100%; /* Full width of the container */
                            max-width: 193px !important;
                            aspect-ratio: 16 / 9; /* Maintain the 16:9 ratio for the image */
                            border-radius: $base-border-radius-1920;
                        }
                    }
                    .vimeo {
                        .vimeo-video-preview-image {
                            width: 100%; /* Full width of the container */
                            max-width: 193px !important;
                            border-radius: $base-border-radius-1920;
                        }
                    }
                    .media {
                        .slider-Img {
                            .home-preview-img {
                                max-width: 193px;
                                width: 100%;
                                aspect-ratio: 16 / 9; /* Ensures 16:9 aspect ratio */
                                object-fit: contain; /* Cover the area while maintaining the aspect ratio */
                                border-radius: $base-border-radius-1920;
                            }
                        }
                    }
                }
                .color-band {
                    position: absolute;
                    left: -2px;
                    top: 10px;
                    bottom: 10px;
                    width: 4px;
                    border-radius: 10px;
                }
            }
            .info-container {
                font-size: 16px;
                letter-spacing: 1.6px;
                line-height: 21px;
                margin: 10px 0 30px;
            }
        }
    }
}
@media (min-width: 1024px) {
    .card-list-container {
        .card-container {
            .card-item {
                padding: 4px;
                width: 100%;
                height: 140px;
                min-width: 482px;
                .text-container {
                    width: calc(100% - 210px);
                    .card-title {
                        font-size: 13px;
                        letter-spacing: 0.8px;
                        line-height: 21px;
                    }
                    .card-description,
                    .news-card-date,
                    .news-card-description {
                        font-size: 11px;
                        letter-spacing: 0.5px;
                        line-height: 18px;
                    }
                }
                .image-container {
                    .youtube {
                        .yt-video-preview-image {
                            max-width: 200px !important;
                        }
                        .ytBtnOverlay {
                            height: 40px;
                            width: 40px;
                        }
                    }
                    .vimeo {
                        .vimeo-video-preview-image {
                            max-width: 200px !important;
                        }
                    }
                    .media {
                        .slider-Img {
                            .home-preview-img {
                                max-width: 200px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1366px) {
    .card-list-container {
        .card-container {
            .card-item {
                padding: 4px;
                height: 125px;
                width: 49.19%;
                min-width: 482px;
                .text-container {
                    width: calc(100% - 200px);
                    padding: 5px 20px 5px 10px;

                    .card-title {
                        font-size: 14px;
                        letter-spacing: 0.8px;
                        line-height: 21px;
                        margin-bottom: 5px;
                    }
                    .card-description,
                    .news-card-date,
                    .news-card-description {
                        font-size: 12px;
                        letter-spacing: 0.5px;
                        line-height: 19px;
                    }
                    .news-card-description {
                        line-height: 18px;
                    }
                }
                .image-container {
                    .youtube {
                        .yt-video-preview-image {
                            max-width: 190px !important;
                        }
                        .ytBtnOverlay {
                            height: 40px;
                            width: 40px;
                        }
                    }
                    .vimeo {
                        .vimeo-video-preview-image {
                            max-width: 190px !important;
                        }
                    }
                    .media {
                        .slider-Img {
                            .home-preview-img {
                                max-width: 190px;
                            }
                        }
                    }
                }
            }
            .info-container {
                font-size: 17px;
            }
        }
    }
}
@media (min-width: 1440px) {
    .card-list-container {
        .card-container {
            .card-item {
                padding: 4px;
                height: 130px;
                min-width: 482px;
                .text-container {
                    width: calc(100% - 200px);
                    padding: 5px 20px 5px 10px;
                    .card-title {
                        font-size: 15px;
                        letter-spacing: 1px;
                        line-height: 19px;
                        margin-bottom: 8px;
                    }
                    .card-description,
                    .news-card-date,
                    .news-card-description {
                        font-size: 13px;
                        letter-spacing: 0.5px;
                        line-height: 21px;
                    }
                    .news-card-description {
                        line-height: 20px;
                    }
                }
                .image-container {
                    .youtube {
                        .yt-video-preview-image {
                            max-width: 190px !important;
                        }
                        .ytBtnOverlay {
                            height: 40px;
                            width: 40px;
                        }
                    }
                    .vimeo {
                        .vimeo-video-preview-image {
                            max-width: 190px !important;
                        }
                    }
                    .media {
                        .slider-Img {
                            .home-preview-img {
                                max-width: 190px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1920px) {
    .card-list-container {
        .card-container {
            // justify-content: space-evenly;
            .card-item {
                padding: 4px;
                height: 142px;
                width: 49.5%;
                .text-container {
                    width: calc(100% - 230px);
                    padding: 7px 20px 7px 10px;

                    .card-title {
                        font-size: 16px;
                        letter-spacing: 1.2px;
                        line-height: 21px;
                        margin-bottom: 7px;
                    }
                    .card-description,
                    .news-card-date,
                    .news-card-description {
                        font-size: 14px;
                        letter-spacing: 0.56px;
                        line-height: 23px;
                    }
                    .news-card-description {
                        line-height: 21px;
                    }
                }
                .image-container {
                    .youtube {
                        .yt-video-preview-image {
                            max-width: 215px !important;
                        }
                        .ytBtnOverlay {
                            height: 40px;
                            width: 40px;
                        }
                    }
                    .vimeo {
                        .vimeo-video-preview-image {
                            max-width: 215px !important;
                        }
                    }
                    .media {
                        .slider-Img {
                            .home-preview-img {
                                max-width: 215px;
                            }
                        }
                    }
                }
            }
            .info-container {
                font-size: 18px;
            }
        }
    }
}
