.preview-secondary-player {
    padding-top: 10px;
}

.ant-modal-content {
    .ant-modal-close {
      color: #000;
      position: absolute;
      right: 1%;
      top: 1%;
    }
  }

@media (min-width:768px) {
    .video-modal {
        width: 80vh !important;

        iframe {
            height: 300px;
        }
    }
}

@media (min-width:1024px) {
    .video-modal {
        width: 700px !important;

        iframe {
            height: 400px;
        }
    }
}

@media (min-width: 760px) and (max-width: 1023px) and (orientation: landscape) {
    .ant-modal-content{
        width: 490px;
        right: 50px;
    }
    .video-modal {
        width: 80vh;

        iframe {
            height: 200px;
        }
    }
}