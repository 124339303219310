.profile-container {
  width: 13em;
  margin-right: 250px !important;
  box-shadow: -4px 5px 6px #00000059;
  border: 1.3px solid #5aa2fb !important;
  border-radius: 0px 0px 14px 14px;

  .profile-info-container {
    border-bottom: 1px solid #c3c3c3;
    padding: 10px;
    text-align: left;
  }

  .profile-navigate-container {
    border-bottom: 1px solid #c3c3c3 !important;
    text-align: left;
    padding: 10px;

    a {
      text-decoration: none;
      font-weight: 500;
      color: #6D6D6D;
    }
  }

  .orgLoginContainer {
    display: flex;
    align-items: center;

    .orgLogin {
      cursor: pointer;
      padding-left: 15px;
      padding-bottom: 4px;
      text-decoration: none;
      text-decoration: none;
      font-weight: 500;
      color: #6D6D6D;
    }
  }

  .border-none {
    border: none !important;
  }

  .profile-username {
    font-weight: bold;
  }
}

@media (min-width:360px) {
  .profile-container {
    margin-top: 1em;
    margin-right: 10px !important;
  }
}

@media (min-width:760px) {
  .profile-container {
    margin-top: 1em;
    margin-right: 110px !important;
  }
}

@media (min-width:820px) {
  .profile-container {
    margin-top: 1em;
    margin-right: 140px !important;
  }
}

@media (min-width:1024px) {
  .profile-container {
    margin-top: 1em;
    margin-right: 20px !important;
  }
}

@media (min-width:1220px) {
  .profile-container {
    margin-top: 1em;
    margin-right: 70px !important;
  }
}

@media (min-width:1320px) {
  .profile-container {
    margin-top: 1em;
    margin-right: 80px !important;
  }
}