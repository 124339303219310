@import "../../styles/main.scss";
.toast-component {
.toast-header {
    @include airbnbCereal-book;
    color: $black;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.4px;
}
.toast-label {
    @include airbnbCereal-book;
    color: $black;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1.4px; 
}
}