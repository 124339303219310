.filter-container {
  text-align: end;
  padding: 5px;

  .filterIcon {
    padding-top: 3%;
    // padding-left: 15%;
    transform: scale(1.4);
    padding-right: 5%;
  }
}

.form-container {
  width: 100%;
}
.ant-modal-close-x {
  // border: 2px solid red;
  top: 2%;
  right: 1%;
}

.closeIcon {
  // border: 2px solid green;
  // margin-top: 5px;
  // margin-left: -18px;

  top: 2%;
  right: 1%;
  // :hover {
  //   margin-top: 5px;
  //   margin-left: -18px;
  // }
}
.microsite-filter-title {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 30px !important;
    height: 30px !important;
  }
}

.ant-space-item{
  margin-bottom: -25px;
}

.ant-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
}@media  (min-width: 320px) and (max-width: 759px) {
  .micro-filter-btn {
    min-height: 40px;
  }
}
@media (min-width: 760px) and (max-width: 1023px) {
  .micro-filter-btn {
    min-height: 35px;
  }
}