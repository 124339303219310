@import "../../styles/common.scss";

.partner-wrapper {
    width: 220px;
    .partner-image {
        border: 2px dashed #bac4d2;
        border-top: 0;
        padding: 7px;
        margin-right: -2px;
        // padding-right: 1px;
        overflow: hidden;
        box-sizing: border-box;
        height: 60px !important;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .partner-description {
        @include airbnbCereal-book;
        padding: 6px;
        font-size: 0.6rem;
        border: 2px dashed #bac4d2;
        border-top: 0;
        max-height: 60px;
        text-overflow: ellipsis;
        margin-right: -2px;
        padding-right: 1px;
        text-align: center;
    }
}

.filters-modal-wrapper {
    .ant-modal-content {
        padding: 10px;
    }
    .mobile-filters-title {
        font-size: 18px;
        letter-spacing: 1.44px;
        color: #222020;
        line-height: 23px;
        font-weight: 600;
    }
    .ant-modal-body {
        padding: 0px;
        .title-bar {
            padding: 8px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 0;
            // border-bottom: 1px solid #7e97b8;
            font-size: 18px;
        }
    }
}

.main-body-container {
    display: flex;
    justify-content: center;
    background-color: #f6f6f6;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

.main-body {
    justify-self: center;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 87px 0 87px;
    height: 100%;
}

.mobile-filter-icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 24px;
    cursor: pointer;
    img {
        width: 30px !important;
        height: 30px !important;
    }
}

// handle tab sizes for "Our Business Partners" carousal
@media (min-width: 320px) and (max-width: 431px) {
    .partner-wrapper {
        width: 140px;
        .partner-image {
            height: 100%;
            border: 0;
            border-bottom: 2px dashed #bac4d2;
            padding: 7px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .partner-description {
            display: none;
        }
    }
    .partner-wrapper:nth-child(odd) .partner-image {
        border-right: 2px dashed #bac4d2;
    }
    .business-partners-wrapper {
        border: 2px dashed #bac4d2;
        border-radius: 8px;
        min-height: 318px;
        // max-height: 53px;
        // height: 100%;
        div {
            .our-business-partners {
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 1.12px;
                // height: 79px;
                color: #222020;
                padding: 0;
                text-transform: capitalize;
                display: flex;
                align-items: center;
                height: 53px;
                max-height: 53px;
            }
        }
    }
    .business-partners-list {
        border-top: 2px dashed #bac4d2;
        height: 100%;
    }
}
@media (min-width: 432px) and (max-width: 514px) {
    .partner-wrapper {
        width: 170px;
        .partner-image {
            height: 100%;
            border: 0;
            border-bottom: 2px dashed #bac4d2;
        }
    }
    .partner-wrapper:nth-child(odd) .partner-image {
        border-right: 2px dashed #bac4d2;
    }
    .business-partners-wrapper {
        border: 2px dashed #bac4d2;
        border-radius: 8px;
        div {
            .our-business-partners {
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 1.12px;
                height: 60px;
                color: #222020;
                padding: 0;
                text-transform: capitalize;
                display: flex;
                align-items: center;
            }
        }
    }
    .business-partners-list {
        border-top: 2px dashed #bac4d2;
    }
}
@media (min-width: 515px) and (max-width: 767px) {
    .partner-wrapper {
        width: 170px;
        .partner-image {
            height: 100%;
            border: 0;
            border-bottom: 2px dashed #bac4d2;
        }
    }
    .partner-wrapper:nth-child(odd) .partner-image {
        border-right: 2px dashed #bac4d2;
    }
    .business-partners-wrapper {
        border: 2px dashed #bac4d2;
        border-radius: 8px;
        div {
            .our-business-partners {
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 1.12px;
                height: 79px;
                color: #222020;
                padding: 0;
                text-transform: capitalize;
                display: flex;
                align-items: center;
            }
        }
    }
    .business-partners-list {
        border-top: 2px dashed #bac4d2;
        max-width: 480px;
        margin: 0 auto;
    }
}
@media (min-width: 768px) {
    .partner-wrapper {
        height: 120px;
        width: 218px;
    }
    .business-partners-list {
        border-top: 2px dashed #bac4d2;
        .partner-description {
            font-size: 12px;
            // line-height: 21px;
            letter-spacing: 0.96px;
            color: #000000;
        }
    }
}

.filters-modal-wrapper {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .ant-modal-content {
        margin: 0;
        border-radius: 10px;
    }
}

// normal responsive styles
@media (min-width: 320px) {
    // .ant-form-item-control-input-content{
    //     display: flex;
    // }

    .filters-modal-wrapper {
        top: 0vh !important;
        .ant-modal-content {
            margin: 10px auto;
        }
    }

    .mobile-filter-close-icon {
        transform: translateX(75vw);
        img {
            width: 14px !important;
            height: 14px !important;
        }
    }
    .partner-page-container {
        display: flex;
        flex-direction: column;
        @include main-body-container-320;
        .partner-page-carousel {
            margin-top: 15px;
        }

        .partner-page-org-title {
            font-size: 15px;
            line-height: 19px;
            font-weight: 900;
            text-align: center;
            margin: 5px 0 0 0;
            letter-spacing: 1.5px;
            height: 19px;
        }

        .partner-name {
            text-align: center;
            font-size: 15px;
            font-weight: 800;
            text-transform: uppercase;
            margin-top: 17px;
        }

        .partner-page-logos-and-search {
            height: fit-content;
            margin: 0 23px;
            margin-top: 19px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            .search-wrapper {
                @include airbnbCereal-book;
                margin-top: 26px;
                width: 100%;
                margin-bottom: 30px;
                .search-icon-landing-page {
                    font-size: 14px;
                    padding: 0 6px;
                }
                .title-bar-search-wrapper {
                    .ant-input-affix-wrapper {
                        background-color: #eaeef5;
                        border-radius: 10px;
                        border-color: transparent;
                        box-shadow: 0px 1px 2px #c0cee24f;
                    }
                }
                input {
                    @include primary-input-placeholder-font-320;
                    background-color: transparent;
                    height: 39px !important;
                    color: $black-primary;
                }
                .ant-input::placeholder {
                    @include primary-input-placeholder-font-320;
                    font-size: 15px;
                    color: #abb4bf;
                    // color: #212529;
                    font-weight: 900;
                }
                .btn-search {
                    margin: 0 5px 0 8px;
                    height: 100%;
                }
                .filter-btn {
                    height: 100%;
                    box-shadow: 0px 1px 2px $border-gray;
                    border: 0.5px solid $blue-secondary;
                    border-radius: $base-border-radius-400;
                }
            }
            .partners-logo-carousel {
                height: 100%;
                flex-grow: 1;
                .business-partners-wrapper {
                    flex-grow: 1;
                    .business-partners-list {
                        .business-partners-list-container {
                            max-height: 25vh;
                        }
                    }
                }
                .our-business-partners-container {
                    max-height: 53px;
                }
            }
        }

        .partner-page-join-now {
            // height: 60px;
            margin: 23px;
            padding: 8px;
            display: flex;
            flex-wrap: wrap;
            // flex-wrap: wrap;
            .partner-org-title-container {
                display: none;
            }
            .join-now-btn {
                @include airbnbCereal-book;
                width: 150px;
                height: 47px;
                border-radius: 11px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                font-size: 14px;
                line-height: 19px;
                float: right;
                border-radius: 8px;
                letter-spacing: 1.5px;
            }
            .partner-org-description {
                @include description-font-320;
                padding: 0 20px;
                width: 100%;
                line-height: 12px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: auto 0;
                display: none;
                word-break: auto-phrase;
            }
            .partner-org-description-xs-screens {
                @include description-font-320;
                padding: 10px 0px;
                font-size: 10px;
                line-height: 12px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: auto 0;
            }
            .partner-org-logo-container {
                display: flex;
                flex-grow: 1;
                // height: 100%;
                // justify-content: center;
                .partner-org-logo {
                    object-fit: contain;
                    max-width: 80px;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .profile-filters-form {
        label {
            @include primary-label-font-320;
            padding-bottom: 5px;
        }
        .ant-select {
            .ant-select-selector {
                color: #222020;

                display: inline-flex;
                justify-content: center;
                align-items: center;

                height: 45px;
                box-shadow: 0px 1px 2px #c0cee24f;
                border: 1px solid #7e97b8;
                border-radius: 8px;
            }
        }
        .filters-submit-btn-container {
            display: flex;
            justify-content: center;
            .ant-form-item-control-input-content {
                display: flex;
                gap: 10px;
            }

            .filters-submit-btn {
                @include airbnbCereal-book;
                font-size: 14px;
                letter-spacing: 1.6px;
                color: #ffffff;
                line-height: 18px;
                // width: 120px;
                height: 40px;
                border-radius: 5px;
            }
        }
    }
    .main-body {
        padding: 0;
    }
}

@media (min-width: 400px) {
    .mobile-filter-close-icon {
        transform: translateX(80vw);
    }
    .partner-page-container {
        .partner-page-join-now {
            height: 60px;
            flex-wrap: nowrap;
            .partner-org-logo-container {
                height: 100%;
                // flex-grow: 0;
                display: flex;
                justify-content: center;
                flex: 0 0 18%;
            }
            .partner-org-description-container {
                display: flex;
                flex: 0 0 46%;
                word-wrap: break-word;
                text-align: center;
                .partner-org-description {
                    word-break: auto-phrase;
                    display: block;
                    font-size: 10px;
                    line-height: 12px;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: auto 0;
                }
            }
            .join-now-btn {
                width: 450px;
            }
            .partner-org-description-xs-screens {
                display: none;
            }
            .custom-style-class {
                display: flex;
                justify-content: center;
                flex: 0 0 30%;
                white-space: nowrap;
            }
        }
    }
}

@media (min-width: 768px) {
    .partner-page-container {
        display: flex;
        height: 100%;
        // 100dvh - nav  -footer
        @include main-body-container-768;
        overflow: unset;
        // overflow: hidden;
        .partner-name {
            display: none;
        }

        .partner-page-carousel {
            margin-top: 10px;
            // margin-top: 1vh;
            height: fit-content;
            // min-height: 315px;
            // margin: 0px;
            padding: 8px;
            flex: 0 0 auto;
        }
        .partner-page-org-title {
            display: none;
        }
        .partner-page-logos-and-search {
            height: fit-content;
            height: 100%;
            margin: 0;
            margin-top: 1.1vh;
            //margin-top: 1vh;
            flex-grow: 1;
            max-height: 1000px;
            display: flex;
            flex-direction: column;
            .search-wrapper {
                margin: 0;
                margin-top: 30px;
                width: 100%;
                padding: 0 30px;
                display: flex;
                justify-content: center;
                .search-icon-landing-page {
                    font-size: 18px;
                    padding: 0 6px;
                }
                input {
                    @include primary-input-placeholder-font-768;
                    min-width: 300px;
                    // width: 75%;
                    width: 100%;
                    max-width: 970px;
                    height: 37px !important;
                    // padding: 0 1rem;
                    color: $black-primary;
                }
                .ant-input::placeholder {
                    @include primary-input-placeholder-font-768;
                    // color: rgba(58, 58, 58, 0.833);
                    font-size: 16px;
                    // color: #212529;
                    color: #abb4bf;
                    font-weight: 700;
                }
                .btn-search {
                    @include airbnbCereal-book;
                    width: 150px;
                    height: 45px;
                    font-size: 13px;
                    letter-spacing: 1.3px;
                    line-height: 17px;
                    margin: 0 11px;
                    border-radius: $base-border-radius-1920;
                }
                .filter-btn {
                    width: 50px;
                    height: 45px;
                    border-radius: $base-border-radius-1920;
                    border: 0.5px solid #7e97b8;
                }
            }
            .partners-logo-carousel {
                min-height: 165px;
                padding: 0 30px;
                margin-top: 20px;
                margin-bottom: 25px;
                display: flex;
                height: 100%;
                flex-grow: 1;
                .business-partners-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    max-height: 100%;
                    overflow: hidden;
                    flex-grow: 1;
                    .our-business-partners {
                        @include sub-heading-font-768;
                        padding: 0;
                        max-width: 140px;
                        height: 52px;
                        text-align: center;
                        flex: 0 0 auto;
                        text-transform: capitalize;
                    }
                    .business-partners-list {
                        // width: 100%;
                        border-top: 0;
                        min-height: 164px;
                        height: 100%;
                        flex-grow: 1;
                        overflow-y: auto;
                        max-width: calc(100% - 150px);
                        .business-partners-list-container {
                            max-height: 35vh;
                        }
                    }
                    .our-business-partners-container {
                        width: 140px;
                    }
                }
            }
        }
        .partner-page-join-now {
            padding: 11px;
            height: 80px;
            margin: 10px 0;
            // margin: 1vh 0;
            flex: 0 0 auto;
            .join-now-btn {
                width: 350px;
                height: 50px;
                border-radius: 11px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                float: right;
                margin-right: 10px;

                font-size: 13px;
                letter-spacing: 1.5px;
                line-height: 19px;
            }
            .partner-org-description {
                @include description-font-1920;
                padding: 0px 24px;
                width: 100%;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                @include description-font-768;
                font-size: 14px;
            }
            .partner-org-logo-container {
                // padding: 15px;
                .partner-org-logo {
                    object-fit: contain;
                    max-width: 97%;
                }
            }
            .partner-org-description-container {
                flex: 0 0 60%;
            }
            .custom-style-class {
                display: flex;
                justify-content: center;
                flex: 0 0 20%;
            }
        }
    }
    .profile-filters-form {
        label {
            color: #222020;
            font-weight: 400;
            font-size: 15px;
            letter-spacing: 1.5px;
            line-height: 19px;
            padding-bottom: 5px;
        }
        .ant-select {
            .ant-select-selector {
                color: #222020;

                display: inline-flex;
                justify-content: center;
                align-items: center;

                height: 45px;
                box-shadow: 0px 1px 2px #c0cee24f;
                border: 0.5px solid #7e97b8;
                border-radius: 8px;
            }
        }
        .filters-submit-btn-container {
            justify-content: end;
            .filters-submit-btn {
                font-size: 16px;
                letter-spacing: 1.6px;
                color: #ffffff;
                width: 167px;
                height: 46px;
                box-shadow: 0px 3px 6px #bac4d226;
                border-radius: 27px;
            }
        }
    }
    .partner-page-filters-popup {
        width: 44.15rem;
    }
    .main-body {
        padding: 0 40px 0 40px;
    }
    .body-desktop {
        overflow: hidden;
    }
}

@media (min-width: 1024px) {
    .partner-page-container {
        @include main-body-container-1024;
        max-width: 890px;
        margin: 0 auto;
        .partner-page-logos-and-search {
            margin-top: 10px;
            .search-wrapper {
                margin-top: 30px;
                width: 98%;
                .search-icon-landing-page {
                    font-size: 15px;
                    padding: 0 6px;
                }
                input {
                    width: 100%;
                    height: 33px !important;
                    @include primary-input-placeholder-font-1024;
                    color: $black-primary;
                }
                .ant-input::placeholder {
                    @include primary-input-placeholder-font-1024;
                    font-size: 17px;
                    // color: #212529;
                    color: #abb4bf;
                    font-weight: 700;
                }

                .btn-search {
                    width: 150px;
                    height: 40px !important;
                    margin: 0 11px;

                    font-size: 13px;
                    letter-spacing: 1.3px;
                    line-height: 17px;
                }
                .filter-btn {
                    width: 44px;
                    height: 40px;
                }
            }
            .partners-logo-carousel {
                padding: 0 30px;
                margin-top: 30px;
                margin-bottom: 10px;
                .business-partners-wrapper {
                    .business-partners-list {
                        .business-partners-list-container {
                            max-height: 35vh;
                        }
                    }
                }
            }
            .partners-logo-carousel .business-partners-wrapper .our-business-partners {
                @include sub-heading-font-1024;
            }
        }
        .partner-page-join-now {
            padding: 8px;
            height: 80px;
            .partner-org-title-container {
                display: contents;
                .partner-org-title {
                    @include airbnbCereal-bold;
                    letter-spacing: 1.6px;
                    font-size: 18px;
                }
            }
            .partner-org-description-container {
                .partner-org-description {
                    width: 100%;
                    padding: 0 24px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    @include description-font-1024;
                    font-size: 14px;
                }
            }
            .partner-org-description-container {
                flex: 0 0 48%;
            }
            .join-now-btn {
                width: 380px;
                height: 50px;

                font-size: 13px;
                letter-spacing: 1.5px;
                line-height: 19px;
            }
            .custom-style-class {
                display: flex;
                justify-content: center;
                flex: 0 0 20%;
            }
        }
    }
    .partner-page-filters-popup {
        max-width: 883px;
    }
    .main-body {
        padding: 0 85px 0 85px;
    }
}

@media (min-width: 1366px) {
    .partner-page-container {
        @include main-body-container-1366;
        max-width: 1120px;
        .partner-page-logos-and-search {
            .search-wrapper {
                .search-icon-landing-page {
                    font-size: 18px;
                    padding: 0 6px;
                }
                input {
                    @include primary-input-placeholder-font-1366;
                    color: $black-primary;
                }
                .ant-input::placeholder {
                    @include primary-input-placeholder-font-1366;
                    font-size: 18px;
                    // color: #212529;
                    color: #abb4bf;
                    font-weight: 700;
                }
                .btn-search {
                    font-size: 15px;
                    letter-spacing: 1.5px;
                    line-height: 20px;
                }
            }
            .partners-logo-carousel .business-partners-wrapper .our-business-partners {
                @include sub-heading-font-1366;
            }
        }
        .partner-page-join-now {
            .partner-org-description-container .partner-org-description {
                font-size: 16px;
                letter-spacing: 0.96px;
                line-height: 21px;
            }
            .custom-style-class {
                display: flex;
                justify-content: center;
                flex: 0 0 20%;
            }
        }
        .partner-page-join-now .join-now-btn {
            font-size: 15px;
            letter-spacing: 1.5px;
            line-height: 19px;
        }
    }
}

@media (min-width: 1440px) {
    .partner-page-container {
        @include main-body-container-1440;

        max-width: 1373px;
        margin: 0 auto;
        .partner-page-logos-and-search {
            .partners-logo-carousel {
                .business-partners-wrapper {
                    .business-partners-list {
                        .business-partners-list-container {
                            max-height: 27vh;
                        }
                    }
                    .our-business-partners {
                        @include sub-heading-font-1440;
                    }
                }
            }
            .search-wrapper {
                width: 91%;
                .search-icon-landing-page {
                    font-size: 22px;
                    padding: 0 10px;
                }
                .btn-search {
                    height: 62px !important;
                    width: 181px;

                    font-size: 17px;
                    letter-spacing: 1.7px;
                    line-height: 22px;
                }
                .filter-btn {
                    height: 62px !important;
                    width: 60px;
                }
                input {
                    @include primary-input-placeholder-font-1440;
                    height: 48px !important;
                    color: $black-primary;
                    width: 100%;
                    max-width: 2000px;
                }
                .ant-input::placeholder {
                    @include primary-input-placeholder-font-1440;
                    font-size: 20px;
                    // color: #212529;
                    color: #abb4bf;
                    font-weight: 700;
                }
            }
        }
        .partner-page-join-now {
            height: 100px;
            .partner-org-title {
                display: block;
                font-size: 20px;
                padding: 0 30px;
            }
            .partner-org-logo-container {
                // padding: 10px 0 10px 10px;
                .partner-org-logo {
                    max-width: 50%;
                }
            }
            .partner-org-description-container {
                .partner-org-description {
                    padding: 0 40px;
                    @include description-font-1440;
                }
            }
            .partner-org-logo-container {
                .partner-org-title {
                    font-size: 20px;
                    line-height: 26px;
                }
            }
            .join-now-btn {
                margin: 0;
                width: 318px;
                height: 78px;
                font-size: 17px;
                letter-spacing: 1.5px;
                line-height: 19px;
            }
            .custom-style-class {
                display: flex;
                justify-content: center;
                flex: -1 0 30%;
            }
        }
    }
    .main-body {
        padding: 0 90px 0 90px;
    }
}

@media (min-width: 1920px) {
    .partner-page-container {
        margin: 0 auto;
        @include main-body-container-1920;
        max-width: 1540px;
        .partner-page-carousel {
            padding: 11px;
            margin-top: 1.3vh;
            padding-bottom: 10px;
        }

        .partner-page-logos-and-search {
            // height: 388px;
            // width: 1564px;
            margin: 0;
            margin-top: 1.2vh;
            .search-wrapper {
                margin-top: 2vh;
                width: 85%;
                .search-icon-landing-page {
                    font-size: 22px;
                    padding: 0 10px;
                }
                input {
                    @include primary-input-placeholder-font-1920;
                    max-width: 1400px;
                    width: 100% !important;
                    height: 52px !important;
                    color: $black-primary;
                }
                .ant-input::placeholder {
                    @include airbnbCereal-book;
                    // @include primary-input-placeholder-font-1920;
                    font-size: 20px;
                    // color: #212529;
                    color: #abb4bf;
                    font-weight: 700;
                }

                .btn-search {
                    width: 186px;
                    height: 62px !important;
                    margin: 0 11px;

                    font-size: 19px;
                    letter-spacing: 2px;
                    line-height: 25px;
                }
                .filter-btn {
                    width: 64px;
                    height: 62px;
                }
            }
            .partners-logo-carousel {
                padding: 0 107px;
                margin-top: 0.8vh;
                margin-bottom: 1vh;
                height: calc(28vh - 1.45 * (62px / 1080px * 100vh));
                .business-partners-wrapper {
                    .our-business-partners {
                        padding: 0;
                        width: 140px;
                        height: 52px;
                        text-align: center;

                        font-size: 19px;
                        letter-spacing: 2px;
                        line-height: 26px;
                    }
                    .business-partners-list {
                        .business-partners-list-container {
                            max-height: 18vh;
                        }
                    }
                }
            }
        }

        .partner-page-join-now {
            margin: 1.2vh 0;
            .partner-org-description-container .partner-org-description {
                @include description-font-1920;
                font-size: 18px;
                line-height: 30px;
            }
            .join-now-btn {
                font-size: 19px;
                letter-spacing: 1.5px;
                line-height: 19px;
            }
            .custom-style-class {
                display: flex;
                justify-content: center;
                flex: 0 0 20%;
            }
        }
    }
    .main-body {
        padding: 0 170px 0 170px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1035px) {
    .our-business-partners {
        transform: translate(29px, 0px);
    }
}
@media only screen and (min-width: 873px) and (max-width: 945px) {
    .our-business-partners {
        transform: translate(29px, 0px);
    }
}
