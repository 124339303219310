@import '../../styles/main.scss';


.text-to-contact-info{
  @include airbnbCereal-book;
}

@media (min-width: 320px) {
  .content {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 13px;
    
    .map-container {
      width: 90%;
      height: 40vh;
      margin: 0px auto;
    }
    div{
      @include airbnbCereal-book;
      gap: 10px;
      font-size: 14px;
      overflow: unset !important;
      a{
        color: #3067ae;
      }
    }  
  }

  .social-media-container{
    margin-top: 10px;
    width: 50%;
    .info-container{
      @include airbnbCereal-book;
      font-size: 14px;
      padding: 12px;
    }
  }
  
  .social-media-icons-image{
    display: inline-block;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;    
    height: 30px;
  }

  .contactTab{
    display: flex;
    flex-direction: column;
    // align-items: center;

  }

  .ant-form-item-control-input-content{
    label{
      @include airbnbCereal-book;
      font-size: 14px;
    }
  }

  .ant-list-items{
    @include airbnbCereal-book;
    font-size: 14px;
  }

  

  .ratingNdReview {
    @include airbnbCereal-bold;
    display: flex;
    flex-direction: column;

    .ratingBars {
      padding-left: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;

      .progressBarContainer {
        display: flex;
        align-content: center;
        padding-right: 10px;

        .progressNumber {
          font-size: 12px;
          margin-right: 3px;

          .progressBar {
            width: 80%;
            margin-bottom: 10px;
          }
        }
      }
    }
    .reviewForm {
      //   width: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      padding-right: 10px;

      textarea{
        @include airbnbCereal-book;
        letter-spacing: 1px;
        font-size: 14px
      }
      .submit-button{
        @include airbnbCereal-book;
      }
    }
  }
  .download-container {
    display: flex;
    justify-content: start;
    align-items: center;

    .download-btn {
      @include airbnbCereal-book;
      // background: #569dfa;
      background: $red-primary;
      color: white;
      margin-right: 10px;
    }

    .download-btn:hover {
      background: $red-secondary;
      color: white;
    }

    span.file-name {
      margin-top: 8px;
      font-size: 14px;
      color: #4a4a4a;
      word-wrap: break-word;
      max-width: 100%;
      text-align: left;
    }
  }

  .text-to-contact-info{
   font-size: 14px;
  }
}

@media (min-width: 340px) {

  .content{
    div{
      div{      
      &:first-child { 
        margin-right: 50px;
      }
    }
    }
  }
  
 
    .contactTab{
      display: flex;
      flex-direction: row;
    }
  
  
}

@media (min-width: 768px) {

  .social-media-container{
    width: 35%;
  }

  .social-media-icons-image{  
    height: 50px;
  }

  .content {
    margin-left: 20px;
    .map-container {
      width: 100%;
      height: 35vh;
    }
    div{
      gap: 10px;
      font-size: 14px;
      div{
        &:first-child { 
          margin-right: unset;
        }
      }
    }
    .social-media-icons{
      height: 50px;
    }
  }

  .contactTab{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ant-form-item-control-input-content{
    label{
      @include airbnbCereal-book;
      font-size: 16px;
    }
  }

  .ant-list-items{
    @include airbnbCereal-book;
    font-size: 16px;
  }

  .ratingNdReview {
    @include airbnbCereal-bold;
    display: flex;
    flex-direction: row;

    .ratingBars {
      width: 40%;
      padding: 10px;
      display: flex;
      flex-direction: column;

      .progressBarContainer {
        display: flex;
        align-content: center;

        .progressNumber {
          font-size: 12px;
          margin-right: 3px;

          .progressBar {
            width: 80%;
            margin-bottom: 10px;
          }
        }
      }
    }
    .reviewForm {
      width: 60%;
      padding: 20px;
      display: flex;
      flex-direction: column;
    }
  }

  .download-container {
    span.file-name {
      font-size: 14px;
    }
  }

}

.col-style{
  display: flex;
  align-items: center;
}

@media (min-width: 1024px) {
  .social-media-container{
    width: 30%;
    .info-container{
      @include airbnbCereal-book;
      font-size: 14px;
    }
  }

  .social-media-icons-image{  
    height: 50px;
  }

  .content {
    div{
      gap: 10px;
      font-size: 16px;
    }
  }

  .ratingNdReview {
    .reviewForm {
      .submit-button{
        font-size: 14px;
      }
    }
  }
}

@media(min-width:1366px){

  .social-media-container{
    width: 25%;
    .info-container{
      @include airbnbCereal-book;
      font-size: 16px;
    }
  }

  .social-media-icons-image{  
    height: 50px;
  }

  .text-to-contact-info{
    font-size: 16px;
   }
  .download-container {
    .download-btn {
      margin-right: 10px;
      font-size: 16px;
      padding: 0px 10px;
    }
    span.file-name {
      font-size: 16px;
    }
  }
}

@media (min-width: 1440px) {
  .social-media-container{
    width: 18%;
    .info-container{
      @include airbnbCereal-book;
      font-size: 18px;
    }
  }

  .social-media-icons-image{  
    height: 80px;
  }

  .text-to-contact-info{
    font-size: 18px;
   }
  .content {
    margin-left: 20px;
    div{
      gap: 10px;
      font-size: 18px;
    }
    .social-media-icons{
      height: 70px;
    }
  }

  .ratingNdReview {
    .reviewForm {
      .submit-button{
        display: flex;
        align-items: center;
        font-size: 16px !important;
        padding: 20px;
      }

      textarea{
        font-size: 16px
      }
    }
  }

  .ant-form-item-control-input-content{
    label{
      @include airbnbCereal-book;
      font-size: 18px;
      letter-spacing: 1px !important;
    }
  }

  .ant-list-items{
    @include airbnbCereal-book;
    font-size: 18px;
  }

  .download-container {

    .download-btn {
      @include airbnbCereal-book;
      background: $red-primary;
      color: white;
      margin-right: 10px;
      font-size: 16px;
      padding: 0px 10px;
    }

    span.file-name {
      font-size: 16px;
    }
  }
}

.spin-ui{
    display: "flex";
    justify-content: "center";
    align-items: "center";
}
