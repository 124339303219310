@import "../../../../styles/main.scss";

.microsite-info-card-container {
//   height: calc(100% - 290px);
  min-height: 200px;
// max-height: 200px;
  overflow-y: auto;
  // background-color: #569dfa;

  .site-card-border-less-wrapper 
  
  .ant-card {
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    border: none;

    .ant-card-body
    {
        .pointers {
            display: flex;
        }
        p .micro-descrp {
          @include airbnbCereal-book;
            font-size: 18px;
            line-height: 45px;
            padding-left: 20px;
            text-indent: 0px;
            white-space: "pre-wrap";
            margin: 0;
            margin-bottom: 40px;
          }
    }
   
  }

  .infoCard-row-detail {
    label {
      font-size: 20px;
    }
    .anchor {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }
}

@media (min-width: 320px) and (max-width: 759px) {


  .microsite-info-card-container.spl-container {
    // padding: 20px;
    padding: 0px;
    // margin-bottom: 10px;
  }
  .microsite-info-card-container {
    .site-card-border-less-wrapper {


      .ant-card-body
      {
          .pointers {
              display: flex;
          }
          p .micro-descrp {
            @include airbnbCereal-book;
              font-size: 14px !important;
              line-height: 30px !important;
              padding-left: 10px !important;
              text-indent: 0px;
              white-space: "pre-wrap";
              margin: 0;
              margin-bottom: 10px !important;
            }
      }

      height: auto;
      min-height: auto;
      .title-header {
        @include airbnbCereal-bold;
        font-size: 14px;
        margin-bottom: 20px;
      }
      .ant-card-head {
        display: none;
        font-size: 20px;
        word-wrap: break-word;
        font-weight: bolder;

        .ant-card-head-title {
          padding-bottom: 0px !important;
          display: none;
        }
      }

      .ant-card-body {
        margin-left: -10px;
        padding: 20px 0px;
        p {
          margin-top: 0px;

          pre {
            color: #242424;
            margin-top: 0px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            white-space: pre-wrap;
            word-wrap: break-word;
            text-align: justify;
            @include airbnbCereal-book;
            font-size: 14px;
          }
        }

        .type {
          color: #3e3e3e;

          .pointer-headers {
            // color: #3e3e3e;
            // font-weight: 700;
            @include airbnbCereal-bold;
          }

          display: flex;
          align-items: center;
          // margin: 1rem 0px;
        }

        .pointers {
          p {
            margin-bottom: 5px;
            margin-top: 5px;
            @include airbnbCereal-book;
          }
        }

        .skills {
          // align-items: flex-start;
          display: block;
          // margin-bottom: 10px;

          // .skillsLabel {
          // margin-top: 5px;
          // margin-bottom: 5px;
          // }

          .skillTags {
            display: block;
            padding-left: 5px;

            span {
              margin-bottom: 5px;
            }
          }
        }

        .apply-btn {
          display: flex;
          // justify-content: end;

          .applyButton {
            display: flex;
            height: 35px;
            width: 60px;
            border: 0px;
            padding: 15px;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            background: #569dfa;
            color: white;
            cursor: pointer;
          }
        }

        .phone-icon {
          display: flex;
          justify-content: end;
          margin-left: 10px;

          .call-btn {
            display: flex;
            height: 35px;
            width: 35px;
            border: 1px solid #67676751;
            padding: 15px;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            // background: #569dfa;
            background: #fff;
            color: white;
            cursor: pointer;
          }

          .call-btn:hover {
            border: 1px solid #569dfa;
          }
        }
      }

      .microsite-header {
        .microsite-drop-btn {
          float: right;
          border: 0px;
          padding: 10px 10px;
          margin: 15px;
          border-radius: 5px;
          background: #569dfa;
          color: white;
          cursor: pointer;

          &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }
        }
      }

      .carousel-video {
        display: flex;
        justify-content: center;
        height: 30vh;
        max-height: 200px;
        margin-bottom: 15px;
        border-radius: 20px;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
      }

      .carousel-img {
        // height: 35vh;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        // border-radius: 20px;

        img {
          object-fit: contain;
          height: 30vh;
        }
      }
    }

    .carousel-vimeo-video {
      text-align: center;
    }
  }

  // iframe {
  //     height: 15vh;
  //     width: 100%;
  // }

  .infoCard-row-detail {
    div {
      @include airbnbCereal-book;
      padding-bottom: 10px;
      font-size: 14px;
      margin-left: 3px;
      div {
        align-items: center;
        gap: 5px;
      }
    }
  }
}

@media (min-width: 768px) {
  .microsite-info-card-container {
    .site-card-border-less-wrapper {

      .ant-card-body
      {
        .apply-btn {
          display: flex;
          // justify-content: end;

          .applyButton {
            display: flex;
            height: 35px;
            width: 60px;
            border: 0px;
            padding: 15px;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            background: #569dfa;
            color: white;
            cursor: pointer;
          }
        }
        
          .pointers {
              display: flex;
          }
          p .micro-descrp {
            @include airbnbCereal-book;
              font-size: 14px !important;
              line-height: 30px !important;
              padding-left: 10px !important;
              text-indent: 0px;
              white-space: "pre-wrap";
              margin: 0;
              margin-bottom: 10px !important;
            }
      }

      .carousel-video,
      .carousel-vimeo-video {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
        height: 35vh;
        max-height: 215px;

        // img {
        //     // border-radius: 20px;
        // }
      }

      .carousel-img {
        width: 80%;

        img {
          height: 35vh;
        }
      }

      .ant-card {
      }
    }
  }
}

@media (min-width: 1024px) {
  .infoCard-row-detail {
    div {
      font-size: 16px;
      margin-left: 5px;
    }
  }
  .microsite-info-card-container {
    // @include main-body-container-768;
    .site-card-border-less-wrapper {
      .title-header {
        @include airbnbCereal-bold;
        font-size: 16px;
      }
      // .ant-card-head-title {
      //     font-size: larger;
      // }

      .ant-card-body {
        p {
          pre {
            font-size: 16px;
          }
        }
      }

      .ant-card {
        // width: 60%;

        // border: 2px solid rgba(234, 232, 232, 0.773);
        // box-shadow: 0px 0px 1px;
        .ant-card-head {
          font-size: 25px;
        }
      }

      .carousel-video {
        width: 46%;
        height: 45vh;
        max-height: 240px;
      }

      .carousel-img {
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
        height: 45vh;
        max-height: 240px;
        // border-radius: 8px;

        iframe {
          display: flex;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
        }

        img {
          height: 40vh;
        }
      }
    }
  }
}

@media(min-width:1366px){
  .microsite-info-card-container {
    .site-card-border-less-wrapper {

      .ant-card-body
      {
          .pointers {
              display: flex;
          }
          p .micro-descrp {
            @include airbnbCereal-book;
              font-size: 16px !important;
              line-height: 35px !important;
              padding-left: 10px !important;
              text-indent: 0px;
              white-space: "pre-wrap";
              margin: 0;
              margin-bottom: 10px !important;
            }
      }
    }
  }
}

@media (min-width: 1440px) {
  .microsite-info-card-container {
    .site-card-border-less-wrapper {

      .ant-card-body
      {
          .pointers {
              display: flex;
          }
          p .micro-descrp {
            @include airbnbCereal-book;
              font-size: 20px !important;
              line-height: 45px !important;
              padding-left: 10px !important;
              text-indent: 0px;
              white-space: "pre-wrap";
              margin: 0;
              margin-bottom: 10px !important;
            }
      }

      .title-header {
        @include airbnbCereal-bold;
        font-size: 18px;
      }
      .ant-card-head-title {
        font-size: larger;
      }

      .ant-card-body {
        p {
          font-size: large;
          pre {
            font-size: 18px;
          }
        }
      }

      .carousel-video {
        height: 40vh;
        max-height: 280px;
        width: 37%;
      }

      .carousel-img {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
        height: 40vh;
        max-height: 280px;

        iframe {
          display: flex;
          margin-left: auto;
          margin-right: auto;
          width: 95%;
        }
      }
    }
  }
  .infoCard-row-detail {
    div {
      font-size: 18px;
    }
  }
}

@media (min-width: 2140px) {
  .microsite-info-card-container {
    .site-card-border-less-wrapper {
      .carousel-video {
        height: 40vh !important;
      }

      .carousel-video,
      .carousel-img {
        // width: 80%;
        // margin-left: auto;
        // margin-right: auto;
        margin-bottom: 15px;
        height: 20vh;

        iframe {
          // display: flex;
          // margin-left: auto;
          // margin-right: auto;
          width: 100%;
        }
      }
    }
  }
}
