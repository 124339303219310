@import "../../../../styles/main.scss";

.sub-tabs-component {
    .sub-tabs-content-holder {
        box-shadow: none !important;
        border: none !important;
        margin: 0 !important;
    }
    // remove default box-shadow
    .sub-tabs-nav-wrap::after,
    .sub-tabs-nav-wrap::before {
        box-shadow: none !important;
    }
}

@media (min-width: 320px) {
    .sub-tabs-component {
        .shared-sub-tabs-content-wrapper,
        .sub-tabs-content-wrapper {
            padding: 0 3px 0 0;
            min-height: 145px;
            height: calc(100vh - $nav-height-320 - $footer-height-320 - 325px);
            overflow: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #4d4b4e;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #4d4b4e;
            }
        }
        .shared-sub-tabs-content-wrapper {
            min-height: 145px;
            height: calc(100vh - $nav-height-320 - 325px);
        }
        .sub-tabs-nav-list {
            // background-color: #f0f2f6;
            margin: 0;
            border-radius: 7px;
            .sub-tabs-nav {
                border: 1px solid red;
                margin-bottom: 0 !important;
                .sub-tabs-nav-list {
                    margin: 0 -10px;
                }

                &::after,
                &::before {
                    border: none;
                }
            }
            .sub-tabs-ink-bar {
                width: 0 !important; // Disable ink bar
            }

            .sub-tabs-tab .sub-tabs-tab-btn::after {
                content: "";
                position: absolute;
                bottom: 2px;
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
                height: 3px;
                background-color: transparent;
                border-radius: 30px;
                transition: all 0.3s ease;
            }

            .sub-tabs-tab {
                border: none;
                background-color: transparent;
                @include airbnbCereal-book;
                font-size: 12px;
                letter-spacing: 1.2px;
                line-height: 14px;
                color: $black-primary;
                transition: color 0.3s ease;
                border-radius: 7px !important;
            }

            .sub-tabs-tab-active {
                .sub-tabs-tab-btn {
                    color: $black !important;
                }
            }
            /* Hover underline */
            .sub-tabs-tab:hover .sub-tabs-tab-btn::after {
                background-color: $blue;
            }

            /* Active underline */
            .sub-tabs-tab-active .sub-tabs-tab-btn::after {
                background-color: $blue;
                color: $black !important;
            }

            .sub-tabs-tab + .sub-tabs-tab {
                margin-left: 10px !important;
            }
        }
        .sub-tabs-top > .sub-tabs-nav::before {
            border: none;
        }
        .sub-tabs-nav {
            margin: 0;
            margin-bottom: 10px;
        }
    }
}
@media (min-width: 768px) {
    .sub-tabs-component {
        .shared-sub-tabs-content-wrapper,
        .sub-tabs-content-wrapper {
            padding: 0 15px;
            min-height: 190px;
            height: calc(100vh - $nav-height-768 - $footer-height-768 - 385px);
        }
        .shared-sub-tabs-content-wrapper {
            min-height: 190px;
            height: calc(100vh - $nav-height-768 - 385px);
        }

        .sub-tabs-nav-list {
            margin: 0;

            .sub-tabs-tab {
                background-color: transparent;
                border: none;
                @include airbnbCereal-book;
                font-size: 13px;
                letter-spacing: 1.2px;
                line-height: 20px;
                color: $black-primary;
                transition: color 0.3s ease;
                border-radius: 0px !important;
                padding: 10px 16px;
                &:hover {
                    transition: none;
                    transition: color 100ms ease;
                    transition: all 250ms ease;
                }
            }

            .sub-tabs-tab-active .sub-tabs-tab-btn::after {
                height: 4px;
            }
        }
        .sub-tabs-nav {
            margin-bottom: 15px;
            .sub-tabs-nav-wrap {
                &::after,
                &::before {
                    box-shadow: none;
                    content: none;
                    width: 0;
                }
            }
            &::before {
                border: none;
            }
        }
    }
}
@media (min-width: 1024px) {
    .sub-tabs-component {
        .shared-sub-tabs-content-wrapper,
        .sub-tabs-content-wrapper {
            padding: 0 5px 0 0;
            min-height: 182px;
            height: calc(100vh - $nav-height-1024 - $footer-height-1024 - 446px);
        }
        .shared-sub-tabs-content-wrapper {
            min-height: 185px;
            height: calc(100vh - $nav-height-1024 - 446px);
        }
        .sub-tabs-nav {
            margin-bottom: 16px;
        }
        .sub-tabs-nav-list {
            margin: 0 15px;
            .sub-tabs-tab {
                font-size: 13px;
                letter-spacing: 1.2px;
                line-height: 25px;
            }
        }
    }
}
@media (min-width: 1366px) {
    .sub-tabs-component {
        .sub-tabs-content-wrapper {
            min-height: 170px;
            height: calc(100vh - $nav-height-1366 - $footer-height-1366 - 443px);
            padding: 0 3px 0 0;
        }
        .shared-sub-tabs-content-wrapper {
            min-height: 170px;
            height: calc(100vh - $nav-height-1366 - 443px);
        }
        .sub-tabs-nav-list {
            .sub-tabs-tab {
                font-size: 13px;
                line-height: 26px;
            }
        }
    }
}
@media (min-width: 1440px) {
    .sub-tabs-component {
        .sub-tabs-content-wrapper {
            min-height: 180px;
            height: calc(100vh - $nav-height-1440 - $footer-height-1440 - 495px);
        }
        .shared-sub-tabs-content-wrapper {
            min-height: 180px;
            height: calc(100vh - $nav-height-1440 - 495px);
        }
        .sub-tabs-nav-list {
            .sub-tabs-tab {
                font-size: 14px;
                line-height: 27px;
            }
        }
    }
}
@media (min-width: 1920px) {
    .sub-tabs-component {
        .sub-tabs-content-wrapper {
            height: calc(100dvh - $nav-height-1920 - $footer-height-1920 - 580px);
            min-height: 193px;
        }
        .shared-sub-tabs-content-wrapper {
            // height: calc(100dvh - $nav-height-1920 - 555px);
            height: calc(100dvh - $nav-height-1920 - 580px);
            min-height: 200px;
        }
        .sub-tabs-nav-list {
            .sub-tabs-tab {
                font-size: 14px;
                padding: 7px 16px;
            }
        }
        .sub-tabs-nav {
            margin-bottom: 10px;
        }
    }
}
