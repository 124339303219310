@import "../../../../styles/main.scss";

@media (min-width: 320px) {
    .our-team-container {
        .card-container {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 10px;
            width: 100%;
            padding: 0;
            .card-item {
                padding: 4px 7px 4px 4px;
                width: 100%;
                background-color: $white-primary;
                border: 0.5px solid #dbe2ed;
                display: flex;
                // flex-grow: 1;
                position: relative;
                margin: 0;
                border-radius: 5px;
                cursor: pointer;
                .text-container {
                    padding: 10px 7px;
                    width: calc(100% - 20px);
                    .card-title {
                        @include airbnbCereal-bold;

                        font-size: 11px;
                        letter-spacing: 0.72px;
                        line-height: 12px;
                        margin-bottom: 5px;
                    }
                    .card-description {
                        padding: 0;
                        margin: 0;
                        @include airbnbCereal-book;
                        font-size: 10px;
                        letter-spacing: 0.72px;
                        line-height: 12px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4; /* Limits the text to 4 lines */
                        line-clamp: 4;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .image-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: unset;
                    height: 100%;
                    img {
                        max-width: 90px;
                        width: 100%;
                        // height: 100%;
                        aspect-ratio: 1 / 1; /* Ensures 16:9 aspect ratio */
                        object-fit: contain; /* Cover the area while maintaining the aspect ratio */
                        border-radius: $base-border-radius-1920;
                    }
                }
                .color-band {
                    position: absolute;
                    left: -1.2px;
                    top: 10px;
                    bottom: 10px;
                    width: 2.5px;
                    border-radius: 10px;
                }
            }
            .info-container {
                width: 100%;
                @include airbnbCereal-book;
                text-align: center;
                font-size: 10px;
                letter-spacing: 1.6px;
                line-height: 21px;
            }
        }
    }
}
@media (min-width: 768px) {
    .our-team-container {
        .card-container {
            margin-top: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 15px;
            width: 100%;
            padding: 0;
            .card-item {
                padding: 4px;
                width: 100%;
                background-color: $white-primary;
                border: 0.5px solid #dbe2ed;
                height: 140px;
                display: flex;
                // flex-grow: 1;
                position: relative;
                margin: 0;
                border-radius: 5px;
                .text-container {
                    padding: 10px 20px 10px 10px;
                    width: calc(100% - 120px);
                    .card-title {
                        @include airbnbCereal-bold;

                        font-size: 13px;
                        letter-spacing: 0.8px;
                        line-height: 21px;
                        margin-bottom: 10px;
                    }
                    .card-description {
                        padding: 0;
                        margin: 0;
                        @include airbnbCereal-book;
                        font-size: 11px;
                        letter-spacing: 0.5px;
                        line-height: 18px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                        line-clamp: 4;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .image-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: unset;
                    height: 100%;
                    img {
                        max-width: 110px;
                    }
                }
                .color-band {
                    position: absolute;
                    left: -2px;
                    top: 10px;
                    bottom: 10px;
                    width: 4px;
                    border-radius: 10px;
                }
            }
            .info-container {
                font-size: 16px;
                letter-spacing: 1.6px;
                line-height: 21px;
                margin: 10px 0 30px;
            }
        }
    }
}
@media (min-width: 1024px) {
    .our-team-container {
        .card-container {
            .card-item {
                padding: 4px;
                width: 49%;
                height: 130px;
                min-width: 382px;
                .text-container {
                    .card-title {
                        font-size: 13px;
                        letter-spacing: 0.8px;
                        line-height: 21px;
                    }
                    .card-description {
                        font-size: 11px;
                        letter-spacing: 0.5px;
                        line-height: 18px;
                        -webkit-line-clamp: 4;
                        line-clamp: 4;
                    }
                }
            }
        }
    }
}
@media (min-width: 1366px) {
    .our-team-container {
        .card-container {
            .card-item {
                padding: 4px;
                height: 125px;
                width: 49%;
                min-width: 482px;
                .text-container {
                    width: calc(100% - 120px);
                    padding: 5px 20px 5px 10px;
                    .card-title {
                        font-size: 14px;
                        letter-spacing: 0.8px;
                        line-height: 21px;
                        margin-bottom: 5px;
                    }
                    .card-description {
                        font-size: 12px;
                        letter-spacing: 0.5px;
                        line-height: 19px;
                        -webkit-line-clamp: 4;
                        line-clamp: 4;
                    }
                }
                .image-container {
                    img {
                        max-width: 110px;
                    }
                }
            }
            .info-container {
                font-size: 17px;
            }
        }
    }
}
@media (min-width: 1440px) {
    .our-team-container {
        .card-container {
            .card-item {
                padding: 4px;
                height: 130px;
                min-width: 482px;
                .text-container {
                    padding: 5px 20px 5px 10px;
                    .card-title {
                        font-size: 15px;
                        letter-spacing: 1px;
                        line-height: 19px;
                        margin-bottom: 8px;
                    }
                    .card-description {
                        font-size: 13px;
                        letter-spacing: 0.5px;
                        line-height: 21px;
                        -webkit-line-clamp: 4;
                        line-clamp: 4;
                    }
                }
            }
        }
    }
}
@media (min-width: 1920px) {
    .our-team-container {
        .card-container {
            // justify-content: space-evenly;
            .card-item {
                padding: 4px;
                height: 142px;
                width: 49%;
                .text-container {
                    width: calc(100% - 130px);
                    padding: 7px 20px 7px 10px;
                    .card-title {
                        font-size: 16px;
                        letter-spacing: 1.2px;
                        line-height: 21px;
                        margin-bottom: 7px;
                    }
                    .card-description {
                        font-size: 14px;
                        letter-spacing: 0.56px;
                        line-height: 23px;
                        -webkit-line-clamp: 4;
                        line-clamp: 4;
                    }
                }

                .image-container {
                    img {
                        max-width: 120px;
                    }
                }
            }
            .info-container {
                font-size: 18px;
            }
        }
    }
}
