@import "../../../styles/main.scss";

@media (min-width: 320px) {
    .microsite-container {
        @include main-body-container-320;
        height: calc(100dvh - 75px);
        padding-top: 7px;
        .microsite-body-container {
            margin: 0 15px;
            overflow: hidden;
            .logo-wrapper {
                display: none;
            }
            .carousel-container {
                margin: 0 -25px 10px -25px;
            }
            .tabs-container {
                .microsite-tabs .ant-tabs-nav-list {
                    padding: 0px 1em;
                }
                .microsite-tabs .ant-tabs-nav::before {
                    border: none;
                    height: 20px;
                }
                .ant-tabs-extra-content {
                    z-index: 10;
                    .cursor-pointer {
                        cursor: pointer;
                        .arrow {
                            width: 11px;
                            height: 11px;
                        }
                    }
                    .arrow-nav-btn {
                        cursor: pointer;
                        height: 11px;
                        width: 7px;
                    }
                }
                .ant-tabs-tab .ant-tabs-tab-btn::after {
                    content: "";
                    position: absolute;
                    bottom: 2px; /* Adjust to position the underline */
                    left: 50%;
                    transform: translateX(-50%);
                    width: 100%; /* Adjust width as needed */
                    height: 3px; /* Thickness of the underline */
                    background-color: transparent; /* Underline color */
                    border-radius: 10px; /* Rounded corners */
                    transition: all 0.3s ease; /* Smooth transition effect */
                }
                .ant-tabs-tab {
                    background-color: transparent;
                    border: none;
                    @include airbnbCereal-book;
                    font-size: 14px;
                    letter-spacing: 0.88px;
                    line-height: 14px;
                    color: $black-primary;
                    padding: 10px 16px;
                }
                .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                    color: $black;
                }
                /* Hover underline */
                .ant-tabs-tab:hover .ant-tabs-tab-btn::after {
                    background-color: $blue;
                }
                /* Active underline */
                .ant-tabs-tab-active .ant-tabs-tab-btn::after {
                    background-color: $blue;
                    color: $black !important;
                }
                .ant-tabs-nav-list {
                    .ant-tabs-ink-bar {
                        width: 0 !important;
                    }
                }
                .ant-tabs-nav-operations .ant-tabs-nav-more {
                    display: block;
                    visibility: visible;
                }
                .ant-tabs-nav {
                    margin-bottom: 10px;
                    .ant-tabs-nav-wrap {
                        &::after,
                        &::before {
                            box-shadow: none;
                        }
                    }
                }
            }
            .ant-tabs-content-holder {
                padding: 10px 3px 10px 8px;
                border: 1px solid #e1e6ee;
                border-radius: calc($base-border-radius-400 + 4px);
                min-height: 150px;
                overflow: auto;
                .ant-tabs-content {
                    .ant-tabs-tabpane-active {
                        .tab-content-wrapper {
                            display: flex;
                            flex-direction: column;
                            flex-grow: 1;
                            min-height: 145px;
                            height: calc(100vh - $nav-height-320 - $footer-height-320 - 275px);
                            overflow: hidden;
                            overflow-y: auto;
                            &::-webkit-scrollbar {
                                width: 4px;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: #4d4b4e;
                                border-radius: 4px;
                            }

                            &::-webkit-scrollbar-thumb:hover {
                                background-color: #4d4b4e;
                            }
                        }
                        .tab-content-wrapper:not(:has(.sub-tabs-component)) {
                            padding: 0 5px;
                        }
                    }
                }
            }
            .microsite-title-bar-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                position: relative;
                margin-bottom: 10px;
                min-height: 60px;
                .microsite-back-btn {
                    cursor: pointer;
                    z-index: 1000;
                }
                .microsite-bookmark {
                    z-index: 1000;
                    cursor: pointer;
                }
                .microsite-navbar {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-height: 60px;
                    .microsite-brand-title {
                        display: none;
                    }
                    .microsite-brand {
                        height: 50px;
                        max-height: 55px;
                        width: auto;
                        display: block;
                        img {
                            max-height: 100%;
                            max-width: 100%;
                            object-fit: contain;
                            height: 100%;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .microsite-container {
        min-height: calc(100dvh - $footer-height-768);
        height: unset;
        position: relative;
        display: flex;
        flex-direction: column;
        .microsite-body-container {
            overflow: unset;
            margin: 10px auto;
            padding: 20px 20px;
            padding-top: 10px;
            box-shadow: 0px 6px 36px #2e3a4e3a;
            border-radius: 15px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            max-width: 730px;
            width: 100%;
            .microsite-title-bar-container {
                position: relative;
                margin-bottom: 10px;
                min-height: 60px;
                max-height: 60px;
                .microsite-navbar {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: flex;
                    align-items: center;
                    .microsite-brand-title {
                        @include airbnbCereal-medium;
                        margin-left: 20px;
                    }
                    .microsite-brand {
                        max-height: 40px;
                    }
                }
            }
            .logo-wrapper {
                display: flex;
                justify-content: center;
                img {
                    max-height: 50px;
                }
            }
            .carousel-container {
                width: 100%;
                margin: 0;
                height: fit-content;
                padding: 8px;
                padding-top: 4px;
                flex: 0 0 auto;

                box-shadow: 0px 1px 3px #eceff3b3;
                border: 0.5px solid #8fabd060;
                border-radius: 8px;
            }
            .tabs-container {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                .microsite-tabs {
                    flex-grow: 1;
                    height: 100%;
                    .ant-tabs-nav-list {
                        padding: 1.4em 1.5em;
                    }
                }
                .ant-tabs-tab {
                    background-color: transparent;
                    border: none;
                    @include airbnbCereal-book;
                    font-size: 14px;
                    letter-spacing: 1.6px;
                    line-height: 20px;
                    &:hover {
                        transition: all 230ms;
                        transition: color 100ms ease;
                        transition: all 250ms ease;
                    }
                }

                .ant-tabs-tab-active .ant-tabs-tab-btn::after {
                    height: 4px;
                }
                .ant-tabs-nav-list {
                    .ant-tabs-ink-bar {
                        width: 0 !important;
                    }
                }
                .ant-tabs-extra-content 
                 .cursor-pointer {
                    .arrow {
                        width: 18px;
                        height: 18px;
                    }
                }
                .arrow-nav-btn {
                    height: 14px;
                    width: 9px;
                }
                .ant-tabs-nav {
                    margin-bottom: 0px;
                }
            }
            .ant-tabs-content-holder {
                padding: 10px 7px 10px 5px;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                height: 100%;
                .ant-tabs-content {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    height: 100%;
                    .ant-tabs-tabpane-active {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        height: 100%;
                        .tab-content-wrapper {
                            min-height: 195px;
                            height: calc(100vh - $nav-height-768 - $footer-height-768 - 335px);
                        }
                        .tab-content-wrapper:not(:has(.sub-tabs-component)) {
                            padding: 0 7px 0 7px;
                        }
                    }
                }
            }
        }
        .partner-slider-container {
            .previous-arrow {
                position: absolute;
                left: -10px;
                top: 48%;
                transform: translateY(-50%);
                z-index: 1;
                width: 20px;
            }
            .next-arrow {
                position: absolute;
                left: calc(100% - 10px);
                top: 48%;
                transform: translateY(-50%);
                z-index: 1;
                width: 20px;
            }
        }
        .microsite-background-container {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
}

@media (min-width: 1024px) {
    .microsite-container {
        min-height: calc(100dvh - $footer-height-1024);
        .microsite-body-container {
            max-width: 955px;
            margin: 20px auto;
            padding: 20px 50px;
            .ant-tabs-content-holder {
                padding: 7px 5px 7px 7px;
                .ant-tabs-content .ant-tabs-tabpane-active .tab-content-wrapper {
                    min-height: 192px;
                    height: calc(100vh - $nav-height-1024 - $footer-height-1024 - 410px);
                }

                .ant-tabs-content .ant-tabs-tabpane-active .tab-content-wrapper:not(:has(.sub-tabs-component)) {
                    padding: 0 7px 0 7px;
                }
            }
            .tabs-container
            .ant-tabs-extra-content 
            .cursor-pointer {
               .arrow {
                   width: 19px;
                   height: 19px;
               }
           }
            .ant-tabs-tab {
                font-size: 15px;
                line-height: 25px;
            }
            .microsite-title-bar-container {
                margin-bottom: 10px;
            }
        }
        .partner-slider-container {
            .previous-arrow {
                left: -9px;
                width: 27px;
            }
            .next-arrow {
                left: calc(100% - 16px);
                width: 27px;
            }
        }
    }
}

@media (min-width: 1366px) {
    .microsite-container {
        @include main-body-container-1366;
        min-height: calc(100dvh - $footer-height-1366);
        .microsite-body-container {
            max-width: 1170px;
            margin: 10px auto;
            padding: 10px 50px;
            .tabs-container .ant-tabs-tab {
                font-size: 16px;
                line-height: 26px;
            }
            .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane-active .tab-content-wrapper {
                min-height: 175px;
                height: calc(100vh - $nav-height-1366 - $footer-height-1366 - 400px);
            }
            .ant-tabs-content-holder {
                padding: 9px 5px 9px 10px;
            }
        }
        .partner-slider-container {
            .previous-arrow {
                left: -11px;
                width: 31px;
            }
            .next-arrow {
                left: calc(100% - 18px);
                width: 31px;
            }
        }
    }
}
@media (min-width: 1440px) {
    .microsite-container {
        min-height: calc(100dvh - $footer-height-1440);
        .microsite-body-container {
            max-width: 1310px;
            padding: 10px 50px;
            margin: 10px auto;
            .microsite-title-bar-container .microsite-navbar .microsite-brand {
                max-height: 50px;
            }
            .tabs-container .ant-tabs-tab {
                font-size: 18px;
                line-height: 27px;
            }
            .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane-active .tab-content-wrapper {
                min-height: 175px;
                height: calc(100vh - $nav-height-1440 - $nav-height-1440 - 430px);
            }
        }
        .partner-slider-container {
            .previous-arrow {
                left: -15px;
                width: 41px;
            }
            .next-arrow {
                left: calc(100% - 23px);
                width: 41px;
            }
        }
    }
}
@media (min-width: 1920px) {
    .microsite-container {
        min-height: calc(100dvh - $footer-height-1920);
        .microsite-body-container {
            max-width: 1640px;
            margin: 20px auto;
            padding: 10px 50px 30px 50px;

            .logo-wrapper {
                img {
                    max-height: 50px;
                }
            }
            .carousel-container {
                padding: 8px 0 11px 0;
            }
            .tabs-container .ant-tabs-tab {
                font-size: 18px;
            }
            .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane-active .tab-content-wrapper {
                min-height: 195px;
                height: calc(100vh - $nav-height-1920 - $footer-height-1920 - 530px);
            }
            .microsite-title-bar-container {
                margin-bottom: 10px;
            }
        }

        .partner-slider-container {
            .previous-arrow {
                left: -15px;
                width: 46px;
            }
            .next-arrow {
                left: calc(100% - 29px);
                width: 46px;
            }
        }
    }
}
