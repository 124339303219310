@import "../../styles/common.scss";

.help-container {
  .help-page-card-item {
    box-shadow: none !important;
  }
}

.help-page-card-item .ant-card-body {
  box-shadow: none !important;
  border: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 12px;
  margin: 0;
  :hover {
    cursor: pointer;
  }
}

@media (min-width: 320px) {
  .help-wrapper {
    @include main-body-container-320;
    .help-container {
      // max-height: calc(100dvh - 54px - 70px - 45px);
      // padding: 10px;
      margin: 0 17px;
      // overflow-y: auto;
      // overflow-x: hidden;
    }

    .help-text-container {
      width: 60%;
      .help-card-title {
        font-size: 11px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
      }

      .help-card-description {
        margin-top: 1%;
        overflow: hidden;

        @include airbnbCereal-book;
        font-size: 8px;
        font-weight: 400;
        letter-spacing: 0.64px;
        line-height: 12px;

        color: $black-primary;
        padding-right: 10px;

        display: -webkit-box; // Use a flexible box layout
        -webkit-box-orient: vertical; // Specify a vertical layout
        -webkit-line-clamp: 3; // Limits the text to 3 lines
        line-clamp: 3;
        overflow: hidden; // Hide the overflowing content
        text-overflow: ellipsis; // Adds ellipsis for overflow
      }
      .span-km {
        font-size: 10px;
        text-decoration: none;
        white-space: nowrap;
      }
    }

    .connectsu-line-help {
      width: 100%;
      border: none; /* Remove any existing border */
      height: 1px; /* Height of the line */
      background: repeating-linear-gradient(to right, #91a6c296 0px 5px, transparent 5px 10px);
      margin: 15px 0;
    }

    .card-item {
      margin: 10px;
      box-shadow: 0px 0px 3px #0000001a;
    }

    .card-container-help {
      display: flex;
      justify-content: center;
      align-items: center;
      .title-help {
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.75);
      }

      .org-card-container-help {
        .image-container {
          width: 40%;
          .media {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .slider-Img {
              .home-preview-img {
                max-width: 150px;
                width: 100%;
                aspect-ratio: 16 / 9; /* Ensures 16:9 aspect ratio */
                object-fit: contain; /* Cover the area while maintaining the aspect ratio */
                border: 1px solid transparent;
                border-radius: 8px;
              }
            }
          }

          .youtube {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            position: relative;

            .ytBtnOverlay {
              position: absolute;
              height: 40px;
              width: 40px;
            }

            .video-preview-image {
              width: 100%;
              aspect-ratio: 16 / 9; /* Ensures 16:9 aspect ratio */
              object-fit: cover; /* Cover the area while maintaining the aspect ratio */
            }

            .yt-video-preview-image {
              max-width: 150px; /* Full width of the container */
              aspect-ratio: 16 / 9; /* Maintain the 16:9 ratio for the image */
              border: 1px solid transparent;
              border-radius: 8px;
            }
          }
          .vimeo {
            .vimeo-video-preview-image {
              max-width: 150px; /* Full width of the container */
              aspect-ratio: 16 / 9; /* Maintain the 16:9 ratio for the image */
              border: 1px solid transparent;
              border-radius: 8px;
            }
          }
        }
        hr:last-child {
          display: none;
        }
      }
    }

    .no-data-warning {
      height: 100% !important ;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (min-width: 768px) {
  .help-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    @include main-body-container-768;
    .help-container {
      margin: 0 29px;
      flex-grow: 1;
      height: 100%;
      .help-page-card-item {
        margin: 0 10px;
        width: 100%;
      }
    }
    .card-container-help {
      .org-card-container-help {
        margin: 10px 0;
        width: 100%;
        .image-container {
          align-items: center;
          width: unset;
          // width: 370px;
          height: 100%;
          .youtube {
            display: flex;
            align-items: center;
            justify-content: end;
            width: 100%;
            position: relative;

            .ytBtnOverlay {
              position: absolute;
              height: 40px;
              width: 40px;
              top: 50%;
              left: 50%;
              transform: translate(40%, -50%);
            }

            .video-preview-image {
              width: 100%;
              aspect-ratio: 16 / 9; /* Ensures 16:9 aspect ratio */
              object-fit: cover; /* Cover the area while maintaining the aspect ratio */
            }

            .yt-video-preview-image {
              width: 100%; /* Full width of the container */
              max-width: 220px !important;
              aspect-ratio: 16 / 9; /* Maintain the 16:9 ratio for the image */
            }
          }
          .vimeo {
            .vimeo-video-preview-image {
              width: 100%; /* Full width of the container */
              max-width: 220px !important;
            }
          }
          .media {
            .slider-Img {
              .home-preview-img {
                max-width: 220px;
                width: 100%;
                aspect-ratio: 16 / 9; /* Ensures 16:9 aspect ratio */
                object-fit: contain; /* Cover the area while maintaining the aspect ratio */
              }
            }
          }
        }
        .help-text-container {
          width: calc(100% - 370px);
          flex-grow: 1;
          .help-card-title {
            @include airbnbCereal-medium;
            font-size: 12.6px;
            letter-spacing: 1.26px;
            line-height: 16.1px;
            color: $black-secondary;
          }
          .help-card-description {
            @include airbnbCereal-book;
            font-size: 0.6rem;
            font-weight: 400;
            letter-spacing: 0.048rem;
            line-height: 1.05rem;
            // color: $gray-dark;
            color: $black-primary;
          }
          .span-km {
            @include airbnbCereal-book;
            font-size: 0.6rem;
            font-weight: 400;
            letter-spacing: 0.048rem;
            line-height: 1.05rem;
            color: $black-primary;
            text-decoration: none;
            white-space: nowrap;
          }
        }
      }
      .title-help {
        font-size: 0.9rem;
        letter-spacing: 0.09rem;
        margin-bottom: 15px;
      }
    }
  }

  .home-page-card-item .ant-card-body {
    padding: 8px 12px;
    // height: 8.5em;
    box-shadow: 0px 0px 3px #0000001a;
  }
}

@media (min-width: 1024px) {
  .help-wrapper {
    @include main-body-container-1024;
    .help-container {
      margin: 0 30px;

      .card-container-help {
        .org-card-container-help {
          width: 100%;
          margin: 3px 0 15px 0;
          .image-container {
            align-items: center;
            // width: 40%;
            .youtube {
              .ytBtnOverlay {
                height: 40px;
                width: 40px;
              }

              .yt-video-preview-image {
                width: 100%;
                max-width: 210px !important;
              }
            }
            .vimeo {
              .vimeo-video-preview-image {
                width: 100%;
                max-width: 210px !important;
              }
            }
          }
        }
      }
      .connectsu-line-help {
        width: 100%;
        margin: 15px 0;
        height: 2px;
      }
    }
    .card-container-help .org-card-container-help .help-text-container .help-card-title {
      font-size: 12.6px;
      letter-spacing: 1.26px;
      line-height: 16px;
    }
  }
}

@media (min-width: 1366px) {
  .help-wrapper {
    @include main-body-container-1366;
    .help-container {
      margin: 0 30px;
      .help-page-card-item {
        width: 90%;
      }
    }
    .card-container-help .org-card-container-help {
      .help-text-container .help-card-title {
        font-size: 14px;
        letter-spacing: 1.44px;
        line-height: 18.4px;
      }
    }
  }
}

@media (min-width: 1440px) {
  .help-wrapper {
    @include main-body-container-1440;
    .help-container {
      margin: 0 30px;

      .card-container-help {
        .org-card-container-help {
          width: 100%;
          .help-text-container .help-card-title {
            font-size: 16px;
            letter-spacing: 1.62px;
            line-height: 20.7px;
          }
          .image-container {
            .youtube {
              .ytBtnOverlay {
                height: 40px;
                width: 40px;
              }
              .yt-video-preview-image {
                width: 100%;
                max-width: 270px !important;
              }
            }
            .vimeo {
              .vimeo-video-preview-image {
                width: 100%;
                max-width: 270px !important;
              }
            }
            .media {
              .slider-Img {
                .home-preview-img {
                  max-width: 270px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1920px) {
  .help-wrapper {
    @include main-body-container-1920;
    .help-container {
      margin: 0 30px;

      .card-container-help {
        .org-card-container-help {
          width: 100%;
          .image-container {
            // width: 30%;
            .youtube {
              .ytBtnOverlay {
                height: 50px;
                width: 50px;
              }
              .yt-video-preview-image {
                width: 80%;
                max-width: 400px !important;
              }
            }
            .vimeo {
              .vimeo-video-preview-image {
                width: 80%;
                max-width: 400px !important;
              }
            }
            .media {
              .slider-Img {
                .home-preview-img {
                  width: 100%;
                  // max-width: 400px;
                  min-width: 320px;
                }
              }
            }
          }
          .help-text-container {
            width: 70%;
            .help-card-title {
              font-size: 18px;
              line-height: 23px;
              letter-spacing: 1.8px;
            }
          }
        }
      }
    }
  }

  .home-page-card-item .ant-card-body {
    height: 12em;
  }
}
