// @import "../../assets/styles/_colours.scss";

@media (min-width: 360px) {
  .otp-container {
    width: 100%;
  }
}

@media (min-width: 760px) {
  .otp-container {
    width: 50%;
    margin-top: 20px;
    padding: 20px;
    background-color: white;
    border: 1px solid rgb(205, 205, 205);
    border-radius: 10px;
    // box-shadow: 2px 2px 10px (rgb(205, 205, 205));
    box-shadow: 2px 2px 10px (rgb(70, 70, 70));
    margin-left: auto;
    margin-right: auto;
  }
}

.verify-heading {
  align-self: flex-start;
  position: relative;
  color: #0069e7;
  width: 80%;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.verify-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  // position: relative;
  // top: 65px;

  .OTP-form {
    // width: 75%;
    // width: 80%;

    .otpFields {
      width: 100%;
      margin: 5px;
      border: 2px solid;
      border-radius: 10px;
      border-color: #4096ff;

      input {
        color: black;
        height: 35px;
        width: 35px !important;
        border-radius: 10px;
        font-size: 16px;
        border: none;
        box-shadow: 0px 3px 10px #0000000f;
        outline: none;
      }

      input:focus {
        outline: 1px solid #4096ff;
      }
    }

    .submit-otp:disabled {
      background-color: #ed841a73 !important;
      color: white !important;
    }
  }
}

.ant-statistic {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.regular-text {
  color: black;
  font-size: medium;
}

.link {
  color: #f058a9;
  font-weight: 500;
}

.verify-error {
  background-color: #ff9989;
  border: 1.5px solid #d53a13;
  height: 2.5em;
  // margin: 0 2em -2.5em 2em;
  border-radius: 0.3em;
  color: #ffffff;
  text-align: center;
}

@media (min-width: 320px) {
  .login-wrapper {
    .login-card-container {
      .inputContainer {
        .verify-error {
          margin-top: -13px;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .login-wrapper {
    .login-card-container {
      .inputContainer {
        .verify-error {
          margin-top: -26px;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .login-wrapper {
    .login-card-container {
      .inputContainer {
        .verify-error {
          margin-top: -20px;
          margin-bottom: 25px;
        }
      }
    }
  }
}
@media (min-width: 1366px) {
  .login-wrapper {
    .login-card-container {
      .inputContainer {
        .verify-error {
          margin-top: -15px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
@media (min-width: 1440px) {
  .login-wrapper {
    .login-card-container {
      .inputContainer {
        .verify-error {
          margin-top: -7px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
