@import "./styles/variables";

/* bootstrap styles overrides */
:root {
  --bs-primary: #3067ae !important;
  --bs-danger: #cc0e2d !important;
}

.btn-primary {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #25568c !important;
  border-color: #25568c !important;
}

.btn-danger {
  background-color: var(--bs-danger) !important;
  border-color: var(--bs-danger) !important;
}

.btn-danger:hover,
.btn-danger:focus {
  background-color: #a00e2d !important;
  border-color: #a00e2d !important;
}

.text-primary {
  color: var(--bs-primary) !important;
}

.text-danger {
  color: var(--bs-danger) !important;
}

/* Outlined Buttons */
.btn-outline-primary {
  background-color: white !important;
  color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  background-color: var(--bs-primary) !important;
  color: #ffffff !important;
  border-color: var(--bs-primary) !important;
}

.btn-outline-danger {
  background-color: #ffffff !important;
  color: var(--bs-danger) !important;
  border-color: var(--bs-danger) !important;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus {
  background-color: var(--bs-danger) !important;
  color: #ffffff !important;
  border-color: var(--bs-danger) !important;
}

/* Text Colors */
.text-primary {
  color: var(--bs-primary) !important;
}

.text-danger {
  color: var(--bs-danger) !important;
}

/*  */

body {
  margin: 0;
  font-family: "AirbnbCereal", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-form {
  input::placeholder {
    font-family: "AirbnbCereal";
  }
  label {
    font-family: "AirbnbCereal";
  }
}

.input-box {
  border-left: 0px !important;
  border-right: 0px !important;
  border-top: 0px !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0px !important;
}

.w-80 {
  width: 80%;
}

html {
  //font-size: 100%; /* This makes 1rem = 16px */
}
body {
  font-size: 1rem; /* 16px */
  background-color: #ffffff;
}

/* scroll bar styles start */
::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0) !important;
}

// Handle
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #7e97b8;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #7e97b862;
}
/* scroll bar styles end  */

@media (min-width: 770px) {
  body {
    background-color: #f6f6f6;
  }
}

@media (min-width: 320px) {
  .bordered-container {
    box-shadow: 0px 1px 3px #eceff3;
    border: 0.5px solid #7e97b8;
    border-radius: 8px;
  }
}
@media (min-width: 768px) {
  .bordered-container {
    box-shadow: 0px 1px 3px #eceff3;
    border: 0.5px solid #7e97b8;
    border-radius: 15px;
  }
}
@media (min-width: 1025px) {
}
@media (min-width: 1440px) {
}
@media (min-width: 1920px) {
}
