@import "../../styles/main.scss";

.leftHeaders {
  @include form-organization-font-1920;
}

.onboarding-background-container {
  overflow-y: scroll;

  .onboarding-header {
    display: flex;
        gap: 10px;
        margin: 25px 0px 10px 42px;
        font-family: "AirbnbCereal-Bold", sans-serif;
        font-weight: 900;
        font-size: 19px;
        letter-spacing: 1.6px;
        line-height: 26px;
        color: #000000;
  }
  .back-arrow {
    cursor: pointer;
  }
}

.onboarding-background-container::-webkit-scrollbar {
  display: none;
}

.orgType {
  .ant-form-item-label {
    label {
      @include form-organization-font-1920;
    }
  }
}

.ant-form-item-label {
  overflow: inherit !important;
  // margin-right: 3vw;
  text-align: start !important;
}

.next_button {
  // padding: 30px 60px;
  background: #cc0e2d;
  display: flex;
  align-items: center;
  // position: absolute;
}

.inputBody {
  display: flex;
  align-items: center;
  justify-content: right;
  margin: 20px 0;
}

.signup-text {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  line-height: 30px;
}

.back-btn {
  margin-right: 10px;
}

.signup-form {
  .ant-select {
    .ant-select-selector {
      color: #222020;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      height: 45px;
      box-shadow: 0px 1px 2px #c0cee24f;
      border: 0.5px solid #7e97b8;
      border-radius: 8px;
    }
    .ant-select-selector{
        line-height: 23px;
        letter-spacing: 1.44px;
        color: #abb4bf;
        font-family: "AirbnbCereal-Book", sans-serif;
        font-weight: 400;
        color: #000000;
        font-size: 16px;
    }
    
    span.ant-select-selection-placeholder {
        color: #8d8d8d !important;
        font-family: "AirbnbCereal-Book", sans-serif;
    }
  }
}



@media (min-width: 320px) {
  .onBoard-container {
    .form-container {
      .orgType {
        .orgTypeBtns {
          overflow-x: auto !important;
        }
      }
    }
  }

  onBoard-container {
    .ant-radio-group {
      overflow-x: scroll !important;
      display: flex;
    }
  }

  .row-gap {
    margin-bottom: 5px;
  }

  .switch-item {
    .ant-form-item-label {
      label {
        @include form-radiobuttons-font-320;
        color: #000000;
        margin-bottom: 5px;
        font-size: 14px;
      }
    }
  }

  .onboarding-background-container {
    width: 100%;
    @include main-body-container-320;
  }

  .onBoard-container {
    input {
      @include form-radiobuttons-font-320;
      @include airbnbCereal-book;
      color: #000000;
      font-size: 14px;
    }

    .ant-input::placeholder {
      @include form-radiobuttons-font-320;
      @include airbnbCereal-book;
      color: #7e7e7e !important;
      font-size: 12px;
    }
  }

  .custom-placeholder {
    border: 1px solid #7e97b8;
    font-size: 14px;
    height: 40px !important;
    line-height: 10px;
    resize: none;
    display: flex;
    align-items: center;
    //  padding-top: 6px;
    overflow-y: hidden;
    @include airbnbCereal-book;
  }

  .onBoard-container {
    width: 100%;

    .form-container {
      // margin-top: 5px;
      width: 90%;

      .signup-form {
        display: flex;
        flex-direction: column;
      }

      .ant-form-item-row {
        // display: flex;
        flex-direction: column;
        margin-top: 1vh;
        // gap: 2vw;

        .ant-form-item-label {
          display: flex;
          font-weight: 400 !important;
        }
      }
      
      .signup-form:first-child {
        margin-top: 0px !important;
      }

      .memberCondition {
        display: none;
      }

      .condition {
        @include form-radiobuttons-font-320;
        color: #7e7e7e;
        margin-top: 0px;
        margin-left: 70px;
        font-size: 12px;
      }

      .switch-item {
        // margin-top: 25px;
        margin-bottom: 0px;

        .memberSwitch {
          margin-right: 10px;
          position: absolute;
          left: 5px;
          top: -3px;
        }
      }

      .nameItem {
        margin-top: -5px;
      }

      .addrAlert {
        margin-top: -10px;
        // margin-bottom: 0px !important;
      }

      .form-item {
        height: 70px !important;
        margin-bottom: 1px;

        .ant-form-item-label {
          label {
            @include form-font-book-320;
            margin-bottom: 5px;
            font-size: 14px;
          }
        }
      }

      .memberFormItem {
        padding-top: 10px;
      }

      .orgType {
        width: auto;
        margin-top: 0px;
        margin-bottom: 28px;
        // height: auto !important;

        .orgTypeBtns {
          scrollbar-width: none !important;
          gap: 0px;
          padding-left: 15px;
          display: flex;
          overflow-x: scroll;
          overflow-y: hidden;
          width: 95%;

          // margin-top: 10px;
          span {
            font-size: 12px;
            white-space: nowrap;
          }

          .ant-radio-button-wrapper {
            width: 30vw !important;
            height: 35px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            // margin-left: 0px;
            border: 1px solid #7e97b8;
            box-shadow: 0px 1px 2px #c0cee24f;
            border-radius: 8px;
            @include form-radiobuttons-font-320;
            color: #7e7e7e;
            font-size: 10px;
          }

          :first-child {
            margin-left: 0px;
          }

          .pub-sec {
            min-width: 110px;
            justify-content: center;
            align-items: center;

            .label-pub {
              width: 110px;
            }
          }
        }
      }

      .orgSize {
        height: 35px !important;

        .orgSizeBtns {
          gap: 0px;
          margin-top: 50px;
          flex-wrap: wrap;

          .ant-radio-button-wrapper {
            @include form-radiobuttons-font-320;
            // width: 199px !important;
            height: 35px !important;
            border: 1px solid #7e97b8 !important;
            white-space: nowrap;
            // height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 1px 2px #c0cee24f;
            border-radius: 8px;
            font-size: 12px;
            color: #7e7e7e;
          }
        }
      }

      .org-name {
        margin-top: -5px;
      }

      .adrField {
        // height: 55px !important;

        .adrInfo {
          color: red;
        }
      }

      .cover {
        padding: 0px 30px;
        position: relative;
      }

      .left {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .right {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .terms-text {
      border: 1px solid rgb(205, 205, 205);
      border-radius: 10px;
      margin: 0px auto;
      margin-bottom: 20px;
      height: 500px;
      overflow: scroll;
      overflow-x: hidden;
      width: 90%;

      .terms-content {
        padding: 20px;
      }
    }

    .check-submit {
      display: flex;
      justify-content: center;
    }

    .submitbtn {
      display: flex;
      justify-content: center;
    }
  }

  .header {
    // height: 52px;
    width: 100%;
    display: flex;

    .backBtn {
      padding-bottom: 0%;
      padding-left: 2%;
      cursor: pointer;
      display: flex;
      justify-content: center;

      img {
        width: 20px;
      }
    }
  }

  .onBoarding-header {
    background: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    img {
      cursor: pointer;
      position: relative;
      left: 7px;
      display: none;
    }

    h2 {
      @include form-radiobuttons-font-320;
      color: black;
      font-weight: 900 !important;
      // margin: 10%;
      margin-left: 30px;
    }
  }

  .container {
    .apply-header {
      display: flex;
      justify-content: center;
      margin: 20px 0px;

      h2 {
        font-size: 18px;
        font-weight: 500 !important;
        margin: 0px !important;
      }
    }

    .terms-text {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid #d2d5df;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
      overflow-y: scroll;
      height: 420px;
      margin-bottom: 20px;

      .terms-content {
        padding: 20px;
      }
    }

    .check-submit {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    .submitbtn {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
    }
  }

  .submitBtn {
    // margin: 10px 0px;
    margin-bottom: 10px;

    display: flex;
    justify-content: center;
  }

  .ant-radio-group {
    overflow-x: scroll;
    display: -webkit-box;
  }
}
@media (min-width: 320px) and (max-width: 759px) {
  .signup-form {
    .ant-select {
      .ant-select-selector {
        font-size: 12px;
        height: 40px;
        letter-spacing: 1px;
      }
    }
    // .Organisation-type{
    //     margin-top: -20px;
    // }
  }
  .onboarding-background-container {
  
    .onboarding-header {
          gap: 10px;
       
          font-size: 16px;
      
        margin: 10px auto;
        margin-bottom: 0;

        .back-arrow {
            display: none;
        }
        .join-name {
            display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
        }
    }
}
  
  .onBoard-container .form-container .signup-form:nth-child(2) {
    margin-top: 100px !important;
  }

  // .onBoard-container .form-container > div > .ant-form-item-row:first-of-type {
  //     margin: 200px;
  // }
}

@media (min-width: 760px) {
  .custom-placeholder {
    @include form-radiobuttons-font-1920;
    color: #000000;
  }

  .onboarding-background-container {
    width: 100%;
    height: 100%;
    @include main-body-container-768;
   .onboarding-header {
    margin: 25px 0px 10px 25px;
    font-size: 14px;
   }
  }

  .next_button {
    top: -50px;
    right: 10vw;
  }

  .onBoard-container {
    .ant-radio-group {
      overflow-x: hidden;
      display: flex;
    }
  }

  .adrField {
    margin-bottom: 0px;

    .adrInfo {
      color: red;
    }
  }

  .onBoarding-header {
    img {
      display: block;
    }

    h2 {
      @include form-organization-font-1920;
    }
  }

  .onBoarding-header {
    background: #fff;

    h2 {
      font-weight: 1000 !important;
      font-size: 20px;
      color: black;
      margin: 4%;
    }
  }

  .onBoard-container {
    background-color: white;
    border-radius: 10px;
    width: 100%;

    .form-container {
      margin-top: 20px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;

      .ant-form-item-row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .memberFormItem {
        margin-top: 10px;
      }

      .switch-item {
        margin-top: -15px;
        margin-bottom: 0px;
        flex-direction: row;

        .memberSwitch {
          transform: scale(1.2);
          position: relative;
          right: 0px;
          top: 0px;
          margin-right: 20px;
        }

        .flexSwitch {
          margin: auto 0.5vw;
          display: flex;
          flex-direction: row;
          align-items: center;

          .memberCondition {
            color: #7e7e7e;
            display: contents;
            margin-left: 20px !important;
          }
        }
      }

      .condition {
        margin-bottom: 30px !important;
        display: none !important;
        visibility: hidden !important;
      }

      .nameItem {
        margin-top: 20px;
      }

      .form-item {
        height: 40px !important;

        .ant-form-item-label {
          white-space: normal;
          display: flex;

          label {
            @include form-font-book-800;
          }
        }

        .addressBox {
          margin-top: 0px;
        }
      }

      .orgType {
        height: auto !important;
        margin-bottom: 0px;

        .orgTypeBtns {
          gap: 36px;
          display: flex;
          overflow-x: hidden;
          height: 100%;

          .ant-radio-button-wrapper {
            width: 18vw !important;
            height: 55px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0px;
            border: 1px solid #7e97b8;
            box-shadow: 0px 1px 2px #c0cee24f;
            border-radius: 8px;
            @include form-radiobuttons-font-1920;
            color: #7e7e7e;
          }
        }
      }

      .orgSize {
        margin-top: -5px;
        margin-bottom: 0px;
        height: 50px !important;

        .orgSizeBtns {
          gap: 15px;
          margin-top: 0px;

          .ant-radio-button-wrapper {
            @include form-radiobuttons-font-1920;
            // width: 199px !important;
            height: 55px !important;
            border: 1px solid #7e97b8 !important;
            white-space: nowrap;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 1px 2px #c0cee24f;
            border-radius: 8px;
            color: #7e7e7e;
          }
        }
      }
    }
  }

  .container {
    width: 70%;
    border: 1px solid #d2d5df;
    border-radius: 8px;
    margin: 10px auto 0px auto;
    margin-bottom: 10px;

    .terms-text {
      width: 80%;
      height: 500px;
    }
  }

  .signup-form {
    .ant-select {
      .ant-select-selector {
        font-size: 12px;
        height: 30px;
        letter-spacing: 1px;
      }
    }
  }
}

@media (min-width: 768px) {
  .row-gap {
    margin-bottom: 2px;
  }

  .switch-item {
    .ant-form-item-label {
      label {
        @include form-organization-font-800;
      }
    }
  }

  .onBoard-container {
    input {
      @include form-radiobuttons-font-800;
      @include airbnbCereal-book;
      // color: #7E7E7E;
      color: #000000;
      font-size: 14px;
    }

    .ant-input::placeholder {
      @include form-radiobuttons-font-800;
      @include airbnbCereal-book;
      color: #7e7e7e !important;
      font-size: 12px;
    }
  }

  .custom-placeholder {
    border: 1px solid #7e97b8;
    font-size: 14px;
    height: 30px !important;
    line-height: 10px;
    resize: none;
    display: flex;
    align-items: center;
    //  padding-top: 6px;
    overflow-y: hidden;
  }

  .onboarding-background-container {
    width: 100%;
    height: 100%;
    @include main-body-container-768;
  }

  .submitBtn {
    margin-top: 20px;
    margin-bottom: 10vh;
    // right: -30vw;
    position: relative;
    margin-right: -20px;
  }

  .next_button {
    top: -10px;
    right: 0vw;
  }

  .onBoard-container {
    width: 90%;

    .form-container {
      margin-top: 10px;
      width: 100%;
      margin-left: 20px;
      margin-right: auto;

      .ant-form-item-row {
        margin-top: 01vh;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .memberFormItem {
        margin-top: 5px;
      }

      .switch-item {
        margin-top: -25px;
        margin-bottom: -15px;
        flex-direction: row;

        .memberSwitch {
          transform: scale(1);
          position: relative;
          right: 0px;
          top: 0px;
          margin-right: 10px;
        }

        .flexSwitch {
          margin: auto 0.5vw;
          display: flex;
          flex-direction: row;
          align-items: center;

          .memberCondition {
            color: #7e7e7e;
            display: contents;
            margin-left: 10px !important;
          }
        }
      }

      .condition {
        margin-bottom: 20px !important;
        display: none !important;
        visibility: hidden !important;
      }

      .nameItem {
        margin-top: 20px;
      }

      .form-item {
        height: 40px !important;

        .ant-form-item-label {
          white-space: normal;
          display: flex;

          label {
            @include form-font-book-800;
            font-weight: 700;
            font-size: 14px;
          }
        }

        .addressBox {
          margin-top: 0px;
          padding-top: 10px !important;
        }
      }

      .orgType {
        height: auto !important;
        margin-bottom: 0px;

        .orgTypeBtns {
          width: 99%;
          padding-left: 0;
          gap: 20px;
          display: flex;
          overflow-x: hidden;
          height: 100%;

          span {
            font-size: 12px;
            white-space: nowrap;
          }

          .ant-radio-button-wrapper {
            width: 18vw !important;
            height: 30px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0px;
            border: 1px solid #7e97b8;
            box-shadow: 0px 1px 2px #c0cee24f;
            border-radius: 8px;
            @include form-radiobuttons-font-800;
            color: #7e7e7e;
          }
        }
      }

      .orgSize {
        margin-top: -5px;
        margin-bottom: 0px;
        height: 35px !important;

        .orgSizeBtns {
          gap: 12px;
          margin-top: 0px;
          margin-bottom: -24px;

          .ant-radio-button-wrapper {
            @include form-radiobuttons-font-800;
            // width: 199px !important;
            height: 30px !important;
            border: 1px solid #7e97b8 !important;
            white-space: nowrap;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 1px 2px #c0cee24f;
            border-radius: 8px;
            font-size: 11px;
            color: #7e7e7e;
          }
        }
      }
    }
  }

  .onBoarding-header {
    background: #fff;

    h2 {
      font-weight: 1000 !important;
      font-size: 14px;
      color: black;
      margin: 4%;
      margin-left: 0px;
    }
  }

  .switch-item {
    .ant-form-item-label {
      label {
        @include form-organization-font-800;
        @include airbnbCereal-book;
        font-size: 14px;
        // @include form-font-book-800;
        font-weight: 700;
      }
    }
  }

  .form-item {
    .ant-form-item-label {
      label {
        @include form-organization-font-800;
      }
    }
  }

  .onboarding-background-container {
    width: 100%;
    height: 100%;
    @include main-body-container-768;
  }

  .next_button {
    right: 2vw;
    padding: 2cqh 40px;
    background: #cc0e2d;
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    font-size: 14px;
  }
}

@media (min-width: 800px) {
  .row-gap {
    margin-bottom: 2px;
  }

  .switch-item {
    .ant-form-item-label {
      label {
        @include form-organization-font-800;
      }
    }
  }

  .onBoard-container {
    input {
      @include form-radiobuttons-font-800;
      @include airbnbCereal-book;
      color: #000000;
      font-size: 14px;
    }

    .ant-input::placeholder {
      @include form-radiobuttons-font-800;
      @include airbnbCereal-book;
      color: #7e7e7e !important;
      font-size: 12px;
    }
  }

  .custom-placeholder {
    border: 1px solid #7e97b8;
    font-size: 12px;
    height: 30px !important;
    line-height: 10px;
    resize: none;
    display: flex;
    align-items: center;
    //  padding-top: 6px;
    overflow-y: hidden;
  }

  .onboarding-background-container {
    width: 100%;
    height: 100%;
    @include main-body-container-768;
    .onboarding-header {
        font-size: 16px;
    }
  }

  .submitBtn {
    margin-bottom: 10vh;
    // right: -30vw;
    position: relative;
  }

  .next_button {
    top: -10px;
    right: 0vw;
  }

  .onBoard-container {
    width: 90%;

    .form-container {
      margin-top: 10px;
      width: 100% !important;
      margin-left: 20px;
      margin-right: auto;

      .ant-form-item-row {
        margin-top: 01vh;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      .memberFormItem {
        margin-top: 5px;
      }

      .switch-item {
        margin-top: -25px;
        margin-bottom: -15px;
        flex-direction: row;

        .memberSwitch {
          transform: scale(1);
          position: relative;
          right: 0px;
          top: 0px;
          margin-right: 10px;
        }

        .flexSwitch {
          margin: auto 0.5vw;
          display: flex;
          flex-direction: row;
          align-items: center;

          .memberCondition {
            color: #7e7e7e;
            display: contents;
            margin-left: 10px !important;
          }
        }
      }

      .condition {
        margin-bottom: 20px !important;
        display: none !important;
        visibility: hidden !important;
      }

      .nameItem {
        margin-top: 20px;
      }

      .form-item {
        height: 40px;

        .ant-form-item-label {
          white-space: normal;
          display: flex;

          label {
            @include form-font-book-800;
            font-weight: 700;
            font-size: 14px;
          }
        }

        .addressBox {
          margin-top: 0px;
          padding-top: 6px !important;
        }
      }

      .orgType {
        height: auto !important;
        margin-bottom: 0px;

        .orgTypeBtns {
          gap: 20px;
          display: flex;
          overflow-x: hidden;
          height: 100%;

          .ant-radio-button-wrapper {
            width: 18vw !important;
            height: 30px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0px;
            border: 1px solid #7e97b8;
            box-shadow: 0px 1px 2px #c0cee24f;
            border-radius: 8px;
            @include form-radiobuttons-font-800;
            color: #7e7e7e;
          }
        }
      }

      .orgSize {
        margin-top: -5px;
        margin-bottom: 0px;
        height: 35px !important;

        .orgSizeBtns {
          gap: 18px;
          margin-top: 0px;
          margin-bottom: -20px;

          .ant-radio-button-wrapper {
            @include form-radiobuttons-font-800;
            // width: 199px !important;
            height: 30px !important;
            border: 1px solid #7e97b8 !important;
            white-space: nowrap;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 1px 2px #c0cee24f;
            border-radius: 8px;
            font-size: 11px;
            color: #7e7e7e;
          }
        }
      }
    }
  }

  .onBoarding-header {
    background: #fff;

    h2 {
      font-weight: 1000 !important;
      font-size: 14px;
      color: black;
      margin: 4%;
      margin-left: 0px;
    }
  }

  .switch-item {
    .ant-form-item-label {
      label {
        @include form-font-book-800;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  .form-item {
    .ant-form-item-label {
      label {
        @include form-organization-font-800;
      }
    }
  }

  .onboarding-background-container {
    width: 100%;
    height: 100%;
    @include main-body-container-768;
  }

  .next_button {
    right: 2vw;
    padding: 2cqh 40px;
    background: #cc0e2d;
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    font-size: 14px;
  }
}

@media (min-width: 1024px) {
  .row-gap {
    margin-bottom: 15px;
  }

  .switch-item {
    .ant-form-item-label {
      label {
        @include form-organization-font-1024;
      }
    }
  }

  .onBoard-container {
    input {
      @include form-radiobuttons-font-1024;
      @include airbnbCereal-book;
      color: #000000;
      font-size: 14px;
    }

    .ant-input::placeholder {
      @include form-radiobuttons-font-1024;
      @include airbnbCereal-book;
      color: #7e7e7e !important;
      font-size: 14px;
    }
  }

  .custom-placeholder {
    border: 1px solid #7e97b8;
    font-size: 14px;
    height: 35px !important;
    line-height: 20px;
    resize: none;
    display: flex;
    align-items: center;
    //  padding-top: 6px;
    overflow-y: hidden;
  }

  .onboarding-background-container {
    width: 100%;
    height: 100%;
    @include main-body-container-1024;
    .onboarding-header {
        font-size: 19px;
    }
  }

  .submitBtn {
    margin-bottom: 2vh;
    // right: -30vw;
    position: relative;
  }

  // .next_button {
  //     top: -10px;
  //     right: 0vw;
  // }

  .next_button {
    position: relative;
    // top: -10px;

    right: -50vw;
  }

  .onBoard-container {
    width: 90%;

    .form-container {
      margin-top: 20px;
      width: 108%;
      margin-left: 40px;
      margin-right: auto;

      .ant-form-item-row {
        margin-top: 01vh;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      .memberFormItem {
        margin-top: 5px;
      }

      .switch-item {
        margin-top: -15px;
        margin-bottom: 0px;
        flex-direction: row;

        .memberSwitch {
          transform: scale(1.1);
          position: relative;
          right: 0px;
          top: 0px;
          margin-right: 10px;
        }

        .flexSwitch {
          margin: 17px 0.5vw;
          display: flex;
          flex-direction: row;
          align-items: center;

          .memberCondition {
            color: #7e7e7e;
            display: contents;
            margin-left: 10px !important;
          }
        }
      }

      .condition {
        margin-bottom: 20px !important;
        display: none !important;
        visibility: hidden !important;
      }

      .nameItem {
        margin-top: 20px;
      }

      .form-item {
        height: 40px;

        .ant-form-item-label {
          white-space: normal;
          display: flex;

          label {
            @include form-font-book-1024;
            font-weight: 700;
          }
        }

        .addressBox {
          margin-top: 0px;
        }
      }

      .orgType {
        height: auto !important;
        margin-bottom: 0px;

        .orgTypeBtns {
          gap: 26px;
          display: flex;
          overflow-x: hidden;
          height: 100%;

          span {
            font-size: 14px;
            white-space: nowrap;
          }

          .ant-radio-button-wrapper {
            width: 19vw !important;
            height: 35px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0px;
            border: 1px solid #7e97b8;
            box-shadow: 0px 1px 2px #c0cee24f;
            border-radius: 8px;
            @include form-radiobuttons-font-1024;
            color: #7e7e7e;
          }
        }
      }

      .orgSize {
        margin-top: -5px;
        margin-bottom: 0px;
        height: 35px !important;

        .orgSizeBtns {
          gap: 25px;
          margin-top: 0px;
          margin-bottom: -20px;

          .ant-radio-button-wrapper {
            @include form-radiobuttons-font-1024;
            // width: 199px !important;
            height: 35px !important;
            border: 1px solid #7e97b8 !important;
            white-space: nowrap;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 1px 2px #c0cee24f;
            border-radius: 8px;
            // font-size: 13px;
            color: #7e7e7e;
          }
        }
      }
    }
  }

  .onBoarding-header {
    background: #fff;

    h2 {
      font-weight: 1000 !important;
      font-size: 14px;
      color: black;
      margin: 4%;
      margin-left: 0px;
    }
  }

  .switch-item {
    .ant-form-item-label {
      label {
        // @include form-organization-font-1024;
        @include form-font-book-1024;
        font-weight: 700;
      }
    }
  }

  .form-item {
    .ant-form-item-label {
      label {
        @include form-organization-font-1024;
      }
    }
  }

  .onboarding-background-container {
    width: 100%;
    height: 100%;
    @include main-body-container-1024;
  }

  .next_button {
    right: -39vw;
    padding: 20px 40px;
    background: #cc0e2d;
    display: flex;
    align-items: center;
    // position: absolute;
    justify-content: center;
    font-size: 14px;
  }
  .signup-form {
    .ant-select {
      .ant-select-selector {
        font-size: 14px;
        height: 40px;

      }
    }
  }
}

@media (min-width: 1280px) {
  .onBoard-container {
    width: 74% !important;
  }
}

@media (min-width: 1366px) {
  .row-gap {
    margin-bottom: 10px;
  }

  .onBoard-container {
    input {
      @include form-radiobuttons-font-1920;
      @include airbnbCereal-book;
      color: #000000;
      font-size: 14px;
    }

    .ant-input::placeholder {
      @include form-radiobuttons-font-1920;
      @include airbnbCereal-book;
      color: #7e7e7e !important;
      font-size: 14px;
    }
  }

  .onBoard-container {
    @include form-font-1366;
    width: 90% !important;

    .form-container {
      margin-top: 0px !important;
      width: 90%;
      margin-left: 60px;
      margin-right: auto;

      .ant-form-item-row {
        margin-bottom: 0vh;
        margin-top: 2vh;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      .memberFormItem {
        margin-top: 10px;
      }

      .switch-item {
        margin-top: -25px;
        margin-bottom: 0px;
        flex-direction: row;

        .memberSwitch {
          transform: scale(1.2);
          position: relative;
          right: 0px;
          top: 0px;
          margin-right: 20px;
        }

        .flexSwitch {
          margin: 15px 0.5vw;
          display: flex;
          flex-direction: row;
          align-items: center;

          .memberCondition {
            color: #7e7e7e;
            display: contents;
            margin-left: 20px !important;
          }
        }
      }

      .condition {
        margin-bottom: 30px !important;
        display: none !important;
        visibility: hidden !important;
      }

      .nameItem {
        margin-top: 20px;
      }

      .form-item {
        height: 40px;

        .ant-form-item-label {
          white-space: normal;
          display: flex;

          label {
            @include form-font-book-1366;
            font-weight: 700;
          }
        }

        .addressBox {
          margin-top: 0px;
        }
      }

      .orgType {
        height: auto !important;
        margin-bottom: 5px;

        .orgTypeBtns {
          gap: 3vw;
          display: flex;
          overflow-x: hidden;
          height: 100%;

          span {
            font-size: 14px;
            white-space: nowrap;
          }

          .ant-radio-button-wrapper {
            @include form-font-book-1366;
            color: #7e7e7e;
            width: 180px !important;
            height: 40px !important;
          }
        }
      }

      .orgSize {
        margin-top: -5px;
        margin-bottom: 0px;
        height: 55px !important;

        .orgSizeBtns {
          gap: 15px;
          margin-top: 0px;

          .ant-radio-button-wrapper {
            // width: 199px !important;
            height: 40px !important;
            border: 1px solid #7e97b8 !important;
            white-space: nowrap;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 1px 2px #c0cee24f;
            border-radius: 8px;
            font-size: 14px;
            color: #7e7e7e;
          }
        }
      }
    }
  }

  .onBoard-container {
    .ant-form-item-control-input {
      width: 70%;
    }
  }

  .form-item {
    .ant-form-item-label {
      label {
        @include form-font-1366;
        font-size: 1rem;
      }
    }
  }

  .switch-item {
    .ant-form-item-label {
      label {
        @include form-font-book-1366;
        font-weight: 700;
      }
    }
  }

  .onBoarding-header {
    background: #fff;

    h2 {
      font-weight: 1000 !important;
      font-size: 16px;
      color: black;
      margin: 4%;
      margin-left: 0px;
    }
  }

  .custom-placeholder {
    @include form-radiobuttons-font-1920;
    font-size: 16px;
    height: 40px !important;
    border: 1px solid #7e97b8;
    resize: none;
    color: #000000;
  }

  .next_button {
    right: -20vw;
    padding: 20px 40px;
    background: #cc0e2d;
    display: flex;
    align-items: center;
    // position: absolute;
  }

  .signup-form .ant-select .ant-select-selector {
    font-size: 14px;
    height: 40px;
    letter-spacing: 1px;
}
}

@media (min-width: 1680px) {
  .onBoard-container {
    .form-container {
      .orgType {
        .orgTypeBtns {
          gap: 2vw !important;

          .ant-radio-button-wrapper {
            width: 210px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  .onboarding-background-container {
    width: 100%;
    height: 100%;
    @include main-body-container-1440;
  }
  .signup-form {
    .ant-select {
      .ant-select-selector {
        font-size: 14px;
        height: 40px;
        letter-spacing: 1.44px;
      }
    }
  }
}

@media (min-width: 1920px) {
    .signup-form {
        .ant-select {
          .ant-select-selector {
            font-size: 16px;
            height: 45px;
            letter-spacing: 1.44px;
          }
        }
      }
  .row-gap {
    margin-bottom: 20px;
  }

  .submitBtn {
    margin-top: 0px;
    margin-bottom: 2vh;
    // right: -30vw;
    position: relative;
  }

  .onBoard-container {
    input {
      @include form-radiobuttons-font-1920;
      @include airbnbCereal-book;
      color: #000000;
      font-size: 16px;
    }

    .ant-input::placeholder {
      @include form-radiobuttons-font-1920;
      @include airbnbCereal-book;
      color: #7e7e7e !important;
      font-size: 16px;
    }
  }

  .custom-placeholder {
    font-size: 16px;
    border: 1px solid #7e97b8;
    height: 45px !important;
    line-height: 25px;
    resize: none;
    display: flex;
    align-items: center;
    //  padding-top: 15px
  }

  .onBoard-container {
    width: 70% !important;

    .form-container {
      margin-top: 20px;
      width: 100%;
    //   margin-left: 85px;
      margin-left: 40px;

      margin-right: auto;

      .ant-form-item-row {
        // margin-bottom: -5px;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      .memberFormItem {
        margin-top: 10px;
      }

      .switch-item {
        margin-top: -13px;
        margin-bottom: 5px;
        flex-direction: row;

        .memberSwitch {
          transform: scale(1.2);
          position: relative;
          right: 0px;
          top: 0px;
          margin-right: 20px;
        }

        .flexSwitch {
          margin: 15px 0.5vw;
          display: flex;
          flex-direction: row;
          align-items: center;

          .memberCondition {
            color: #7e7e7e;
            display: contents;
            margin-left: 20px !important;
          }
        }
      }

      .condition {
        margin-bottom: 30px !important;
        display: none !important;
        visibility: hidden !important;
      }

      .nameItem {
        margin-top: 20px;
      }

      .form-item {
        height: 40px;

        .ant-form-item-label {
          white-space: normal;
          display: flex;

          label {
            @include form-organization-font-1920;
            font-size: 18px;
          }
        }

        .addressBox {
          margin-top: 0px;
        }
      }

      .orgType {
        height: auto !important;
        margin-bottom: 0px;

        .orgTypeBtns {
          scrollbar-width: none !important;
          gap: 36px !important;
          display: flex;
          overflow-x: hidden;
          height: 100%;

          span {
            font-size: 16px;
            white-space: nowrap;
          }

          .ant-radio-button-wrapper {
            width: 18vw !important;
            height: 45px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0px;
            border: 1px solid #7e97b8;
            box-shadow: 0px 1px 2px #c0cee24f;
            border-radius: 8px;
            @include form-radiobuttons-font-1920;
            color: #7e7e7e;
          }
        }
      }

      .orgSize {
        margin-top: -5px;
        margin-bottom: 0px;
        height: 50px !important;

        .orgSizeBtns {
          gap: 15px;
          margin-top: 0px;

          .ant-radio-button-wrapper {
            @include form-radiobuttons-font-1920;
            // width: 199px !important;
            height: 45px !important;
            border: 1px solid #7e97b8 !important;
            white-space: nowrap;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 1px 2px #c0cee24f;
            border-radius: 8px;
            font-size: 16px;
            color: #7e7e7e;
          }
        }
      }
    }
  }

  .onBoarding-header {
    background: #fff;

    h2 {
      font-weight: 1000 !important;
      font-size: 18px;
      color: black;
      margin: 4%;
      margin-left: 0px;
    }
  }

  .switch-item {
    .ant-form-item-label {
      label {
        @include form-organization-font-1920;
        font-size: 18px;
      }
    }
  }

  .form-item {
    .ant-form-item-label {
      label {
        @include form-organization-font-1920;
      }
    }
  }

  .onboarding-background-container {
    width: 100%;
    height: 100%;
    @include main-body-container-1920;
  }

  .next_button {
    right: -16.5vw;
    padding: 25px 50px;
    background: #cc0e2d;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    font-size: 18px;
  }
}

.viewMicrositeModal {
  top: 20px !important;
  width: 900px;

  .approve-container {
    .ant-col-10 {
      margin: 5px;
    }

    .ant-col-12 {
      margin: 5px;
    }
  }
}

.submitbtnend {
  display: flex;
  justify-content: center;
  align-items: center;
}
