@import "../../styles/common.scss";

@media (min-width: 320px) {
    .shared-navbar {
        height: $nav-height-320;
        min-height: $nav-height-320;
        max-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        .navbar-brand {
            margin: 0 14px;
            img {
                max-height: 40px;
                height: 90%;
            }
        }
    }
}
@media (min-width: 768px) {
    .shared-navbar {
        position: sticky;
        z-index: 999;
        height: $nav-height-768;
        min-height: $nav-height-768;
        max-height: 100px;
        top: 0;
        left: 0;
        padding: 0;
        border: 0.5px solid $gray-disabled;
        box-shadow: 0px 3px 4px #7e97b830;
        background-color: $white-primary;
        display: flex;
        align-items: center;
        justify-content: center;
        .navbar-brand {
            margin: 0 14px;
            img {
                max-height: unset;
            }
        }
    }
}
@media (min-width: 1024px) {
    .shared-navbar {
        max-height: 100px;
        height: $nav-height-1024;
        min-height: $nav-height-1024;
        .navbar-brand img {
            margin: 0 10px;
        }
    }
}
@media (min-width: 1366px) {
    .shared-navbar {
        max-height: 100px;
        height: $nav-height-1366;
        min-height: $nav-height-1366;
        .navbar-brand img {
            width: 50px;
        }
    }
}
@media (min-width: 1440px) {
    .shared-navbar {
        max-height: 100px;
        height: $nav-height-1440;
        min-height: $nav-height-1440;
        .navbar-brand img {
            width: 60px;
            height: 76px;
        }
    }
}
@media (min-width: 1920px) {
    .shared-navbar {
        max-height: 100px;
        height: $nav-height-1920;
        min-height: $nav-height-1920;
    }
}
