@import "../../styles//main.scss";

.custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // width: 80vh;
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 8px;
}

.pdf-viewer {
    width: 100%;
    height: 70vh;
    position: relative;
    border: none;
}


.custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.custom-modal-title {
    font-size: 20px;
    font-weight: bold;
}

.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.custom-close-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 250px !important;
    height: 100% !important;
}

.react-pdf__Page__canvas {
    margin: 0;
}

.react-pdf__Page__annotations {
    display: none;
}

.fade-in {
    animation: fadeIn 500ms ease-in-out;
}

.brochure-buttons{
    margin-top: 20px;
    button{
        @include airbnbCereal-book;
        width: 110px;
        height: 50px;
    }
}

.brochured-header-text{
    @include airbnbCereal-bold;
}

.brochure-card {
    // width: 58.5%;
    // border: none;
    .ant-card-body {
        padding-left: 0px;
        background-color: white !important;
    }}

@media(min-width:320px) {
    .brochure {
        width: auto;
    }

    .brochure-buttons{
        margin-top: 20px;
        button{
            @include airbnbCereal-book;
            width: 40% !important;
            height: 40% !important;
        }
    }

    .brochure-card {
        width: auto;
        border: none;
        background: none;

        .ant-card-body {
            padding: 20px !important;
            padding-top: 0px !important;
            // border:1px solid #67676751;
            // background: #F0F2F6;
            border-radius: 5px;
        }
    }

    .pdf-file-name {
        @include airbnbCereal-book;
    }

    .brochured-header-text{
        font-size: 14px;
        padding-top: 10px;
    }
}

@media(min-width:768px) {

    .brochure-buttons{
        margin-top: 20px;
        button{
            @include airbnbCereal-book;
            width: 110px !important;
            height: 50px !important;
        }
    }

    .brochure-card {
        width: 74%;
        border: none;
        .ant-card-body {
            padding: 10px !important;
            padding-top: 0px !important;
            background: #F0F2F6;
            padding-left: 3vw !important;
            div{
                div{
                    border: none !important;
                }
            }
        }
    }
    .brochured-header-text{
        font-size: 14px;
        margin-left: -1px !important;
    }
}

@media(min-width:1024px) {

    .brochure-card {
        width: 57%;
        border: none;
        .ant-card-body {
            padding: 10px !important;
            padding-top: 0px !important;
        }
    }

    .brochured-header-text{
        font-size: 16px;
        //  padding-left: -10px;
        margin-left: -15px !important;
    }
}

@media(min-width:1366px) {
    .brochure-card {
        width: 58%;
        border: none;
        .ant-card-body {
        }
    }

    .brochure-buttons{
        button{
            padding: 0 10px;
            font-size: 16px;
        }
    }

    .brochured-header-text{
        font-size: 16px;
    }
}

@media(min-width:1920px) {

    .brochure-buttons{
        button{
            padding: 0 10px;
            font-size: 18px;
        }
    }

    .brochure-card {
        width: 58.5%;
        border: none;
        .ant-card-body {
            background-color: white;
            div{
                div{
                    // padding-left: 0.5vw;
                }
            }
        }
    }

    .brochured-header-text{
        font-size: 18px;
    }
}



@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}