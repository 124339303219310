// spacing

// borders
$base-border-radius-400: 8px;
$base-border-radius-1920: 10px;

$base-font-size: 16px;

// navbar heights
$nav-height-320: 54px;
$nav-height-768: 65px;
$nav-height-1024: 65px;
$nav-height-1366: 80px;
$nav-height-1440: 80px;
$nav-height-1920: 80px;

// footer heights
$footer-height-320: 70px;
$footer-height-768: 75px;
$footer-height-1024: 75px;
$footer-height-1366: 75px;
$footer-height-1440: 75px;
$footer-height-1920: 74px;

//
$base-font-size-1920: 20px;
$base-font-size-1440: 18px;
$base-font-size-1366: 16px;
$base-font-size-1024: 14px;
$base-font-size-768: 14px;
$base-font-size-320: 14px;
