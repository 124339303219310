@import "../../styles/main.scss";

.submitBtn-feedback {
    display: flex;
    justify-content: end;
}

@media (min-width: 760px) {
    .feedback-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    .feedback-form {
        width: 80%;
    }

    .form-item-feedback label {
        display: contents !important;
    }

    .feedback-form .ant-form-item-label {
        width: 25%;
    }

    .form-item-feedback {
        margin-left: 10px;
        // margin-right: 20px;
    }
}

@media (min-width: 1024px) {
    .feedback-form .ant-form-item-label {
        max-width: 1100px;
    }
}

.connectsu-primary-btn {
    background-color: var(--bs-danger) !important;
    border: 1px solid var(--bs-danger) !important;
    border-radius: 5px !important;
    color: white !important;
    cursor: pointer;
}
.feedback-form-upload-text {
    color: $border-gray;
    padding: 0;
    margin: 0;
}

@media (min-width: 320px) {
    .feedback-wrapper {
        @include main-body-container-320;
        height: 100%;
        padding-bottom: $footer-height-320;
        .feedback-container {
            padding: 20px;
            overflow-y: scroll;
            overflow-x: hidden;
            width: 100%;
            display: flex;
            .files-feedback {
                .file-list {
                    margin-top: 10px;

                    .file-list-item {
                        color: #bac4d2;
                        .doc-name {
                            color: black;
                        }
                    }
                }
            }
        }

        .feedback-form {
            width: 100%;
            label {
                @include airbnbCereal-book;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 1.6px;
                line-height: 21px;
            }
            input,
            textarea,
            .ant-select-selector {
                @include airbnbCereal-book;

                min-height: 45px;
                font-size: 18px;

                box-shadow: 0px 1px 2px #c0cee24f;
                border: 0.5px solid #7e97b8;
                border-radius: 8px;
            }
            .ant-upload {
                box-shadow: 0px 1px 2px #c0cee24f;
                border: 0.5px solid #7e97b8;
                border-radius: 8px;
            }
            .ant-upload-drag {
                border: none;
            }
            .ant-select-selector {
                display: inline-flex;
                align-items: center;
            }
            .ant-input::placeholder,
            .ant-select-selection-placeholder {
                @include airbnbCereal-book;

                font-weight: 400;
                font-size: 16px;
                letter-spacing: 1.6px;
                color: #abb4bf;
                display: inline-flex;
                align-items: center;
            }
            textarea::placeholder {
                margin-top: 15px;
            }
            .ant-row {
                align-items: flex-start;
            }
            .submit-feedback-btn {
                @include airbnbCereal-book;

                font-size: 12px;
                letter-spacing: 0.96px;
                line-height: 16px;
                color: $white-primary;

                width: 90px;
                height: 34px;

                border: 0.5px solid $blue-secondary;
                box-shadow: 0px 1px 2px #c0cee24f;
                border-radius: $base-border-radius-400;
            }
        }

        .form-item-feedback {
            display: flex;
            flex-direction: column; /* Stack the label and input vertically */
        }

        .ant-form-item-label {
            margin-bottom: 8px; /* Add some spacing between label and input */
        }

        .ant-upload {
            background-color: $white-primary;
            .feedback-form-upload-text {
                @include airbnbCereal-book;
                font-size: 0.8rem;
                letter-spacing: 0.072rem;
                line-height: 1.15rem;
            }
        }
    }
}

@media (min-width: 768px) {
    .feedback-wrapper {
        // remove nav bar and footer height
        @include main-body-container-768;
        padding-bottom: unset;
        height: 100%;
        .feedback-container {
            .feedback-form {
                width: 100%;
                max-width: 1100px;
                label {
                    @include airbnbCereal-book;
                    font-size: 1rem;
                    letter-spacing: 0.08rem;
                    line-height: 1.3rem;
                    color: $black-primary;
                }
                input,
                textarea,
                .ant-select-selector {
                    @include secondary-input-placeholder-font-1920;
                    color: $black;
                    height: 2.75rem;
                    font-size: 0.9rem;
                    display: inline-flex;
                    align-items: center;
                }
                textarea {
                    padding-top: 0.7rem;
                    min-height: 5.4rem;
                }
                .ant-input::placeholder,
                .ant-select-selection-placeholder {
                    @include secondary-input-placeholder-font-1920;
                    font-size: 0.9rem;
                    display: inline-flex;
                    align-items: center;
                }
                .ant-row {
                    align-items: center;
                }
                .submit-feedback-btn {
                    @include airbnbCereal-book;

                    font-size: 0.95rem;
                    letter-spacing: 0.095rem;
                    line-height: 1.25rem;
                    margin: 0 11px;
                    border-radius: $base-border-radius-1920;

                    width: 9.3rem;
                    height: 3.1rem;

                    border-radius: 10px;
                }
            }
        }
        .ant-upload {
            .feedback-form-upload-text {
                @include airbnbCereal-book;
                font-size: 0.8rem;
                letter-spacing: 0.072rem;
                line-height: 1.15rem;
            }
        }
    }
}

@media (min-width: 1024px) {
    .feedback-wrapper {
        @include main-body-container-1024;
        .feedback-container {
            .feedback-form {
                width: 75%;
            }
        }
    }
}

@media (min-width: 1440px) {
    .feedback-wrapper {
        @include main-body-container-1440;
    }
}

@media (min-width: 1920px) {
    .feedback-wrapper {
        @include main-body-container-1920;
        .feedback-container {
            .feedback-form {
                label {
                    @include airbnbCereal-book;
                    font-size: 20px;
                    letter-spacing: 1.6px;
                    line-height: 26px;
                    color: $black-primary;
                }
                input,
                textarea,
                .ant-select-selector {
                    @include secondary-input-placeholder-font-1920;
                    color: $black;
                    height: 50px;
                    font-size: 18px;
                    letter-spacing: 1.44px;
                    line-height: 23px;
                    display: inline-flex;
                    align-items: center;
                }
                textarea {
                    padding-top: 14px;
                    min-height: 100px;
                }
                .ant-input::placeholder,
                .ant-select-selection-placeholder {
                    @include secondary-input-placeholder-font-1920;
                    font-size: 18px;
                    display: inline-flex;
                    align-items: center;
                }
            }
        }
    }
}
