$green: #59a638;

$gray-light: #bfbfbf;
$gray-medium: #abb4bf;
$gray-dark: #7e7e7e;
$gray-disabled: #bac4d2;

$red-primary: #c32c2c;
$red-secondary: #cc0e2d;

$blue: #3067ae;
$blue-secondary: #7e97b8;
$blue-light: #f0f2f6;

$white-primary: #fff;
$white-secondary: #f6f6f6;

$black-primary: #000;
$black-secondary: #222020;
$black-light: #707070;
