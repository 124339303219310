@media (min-width: 320px) {
    .icon-image {
        width: 18px;
        height: 18px;
    }
    .nav-bar-menu {
        width: 23px;
        height: 17px;
    }
    .nav-bar-user {
        width: 34px;
        height: 34px;
    }
    .footer-home {
        width: 23px;
        height: 23px;
    }
    .footer-share {
        width: 20px;
        height: 20px;
    }
    .footer-help {
        width: 12px;
        height: 21px;
    }
    .footer-support {
        width: 21px;
        height: 21px;
    }
    .black-back-btn {
        height: 13px;
        width: 20px;
    }
    .tab-arrows {
        width: 5px;
        height: 9px;
    }
}
@media (min-width: 768px) {
    .previous-arrow,
    .next-arrow {
        width: 31px;
        height: 40px;
    }
    .black-back-btn {
        height: 13px;
        width: 20px;
    }
    .tab-arrows {
        width: 7px;
        height: 10px;
    }
}
@media (min-width: 1024px) {
    .previous-arrow,
    .next-arrow {
        width: 31px;
        height: 40px;
    }
    .close-active {
        width: 20px;
        height: 20px;
    }
    .black-back-btn {
        height: 13px;
        width: 20px;
    }
}
@media (min-width: 1336px) {
    .close-active {
        width: 20px;
        height: 20px;
    }
}
@media (min-width: 1440px) {
    .previous-arrow,
    .next-arrow {
        width: 41px;
        height: 50px;
    }
    .black-back-btn {
        height: 15px;
        width: 30px;
    }
    .tab-arrows {
        width: 8px;
        height: 11px;
    }
    .close-active {
        width: 29px;
        height: 29px;
    }
    .filter-slider {
        width: 22px;
        height: 22px;
    }
}
@media (min-width: 1920px) {
    .previous-arrow,
    .next-arrow {
        width: 46px;
        height: 55px;
    }
    .filter {
        width: 23px;
        height: 23px;
    }
    .close-active {
        width: 29px;
        height: 29px;
    }
    .filter-slider {
        width: 25px;
        height: 25px;
    }
    .black-back-btn {
        height: 15px;
        width: 30px;
    }
}
