@import "../../styles//main.scss";

@media (min-width:320px) {
    .background-container {
        .site-card-border-less-wrapper {
            @include main-body-container-320;
            .ant-card-head {
                font-size: 20px;
                word-wrap: break-word;
                font-weight: bolder;

                .ant-card-head-title {
                    padding-bottom: 0px !important;
                }
            }

            .ant-card-body {
                p {
                    margin-top: 0px;

                    pre {
                        color: #242424;
                        margin-top: 0px;
                        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                            sans-serif;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        white-space: pre-wrap;
                        word-wrap: break-word;
                        text-align: justify;
                    }
                }

                .type {
                    color: #3e3e3e;

                    .pointer-headers {
                        // color: #3e3e3e;
                        font-weight: 700;
                    }

                    display: flex;
                    align-items: center;
                    // margin: 1rem 0px;
                }

                .pointers {
                    p {
                        margin-bottom: 5px;
                        margin-top: 5px;
                    }
                }

                .skills {
                    // align-items: flex-start;
                    display: block;
                    // margin-bottom: 10px;

                    // .skillsLabel {
                    // margin-top: 5px;
                    // margin-bottom: 5px;
                    // }

                    .skillTags {
                        display: block;
                        padding-left: 5px;

                        span {
                            margin-bottom: 5px;
                        }
                    }
                }

                .apply-btn {
                    display: flex;
                    // justify-content: end;

                    .applyButton {
                        display: flex;
                        height: 35px;
                        width: 60px;
                        border: 0px;
                        padding: 15px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px;
                        background: #569dfa;
                        color: white;
                        cursor: pointer;
                    }
                }

                .phone-icon {
                    display: flex;
                    justify-content: end;
                    margin-left: 10px;

                    .call-btn {
                        display: flex;
                        height: 35px;
                        width: 35px;
                        border: 0px;
                        padding: 15px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px;
                        background: #569dfa;
                        color: white;
                        cursor: pointer;
                    }
                }
            }

            .microsite-header {
                .microsite-drop-btn {
                    float: right;
                    border: 0px;
                    padding: 10px 10px;
                    margin: 15px;
                    border-radius: 5px;
                    background: #569dfa;
                    color: white;
                    cursor: pointer;

                    &:disabled {
                        background-color: #ccc;
                        cursor: not-allowed;
                    }
                }
            }

            .carousel-video {
                display: flex;
                justify-content: center;
                height: 30vh;
                max-height: 200px;
                margin-bottom: 15px;
                border-radius: 20px;
                width: 95%;
                margin-left: auto;
                margin-right: auto;
            }

            .carousel-img {
                // height: 35vh;
                width: 95%;
                margin-left: auto;
                margin-right: auto;
                // border-radius: 20px;


                img {
                    object-fit: contain;
                    height: 30vh;
                }
            }

        }

        .carousel-vimeo-video {
            text-align: center;
        }
    }

    // iframe {
    //     height: 15vh;
    //     width: 100%;
    // }
}

@media (min-width:768px) {
    .background-container {
        .site-card-border-less-wrapper {
    @include main-body-container-768;
            .carousel-video,
            .carousel-vimeo-video {
                width: 50%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;
                height: 35vh;
                max-height: 215px;

                // img {
                //     // border-radius: 20px;
                // }
            }

            .carousel-img {
                width: 80%;

                img {
                    height: 35vh;
                }
            }

            .ant-card {
                margin-left: auto;
                margin-right: auto;
                width: 80%
            }
        }
    }
}

@media (min-width:1024px) {
    .background-container {
        // @include main-body-container-1024;
        .site-card-border-less-wrapper {
            // .ant-card-head-title {
            //     font-size: larger;
            // }

            // .ant-card-body {
            //     p {
            //         font-size: large;
            //     }
            // }

            .ant-card {
                width: 60%;

                // border: 2px solid rgba(234, 232, 232, 0.773);
                // box-shadow: 0px 0px 1px;
                .ant-card-head {
                    font-size: 25px;
                }
            }

            .carousel-video {
                width: 46%;
                height: 45vh;
                max-height: 240px;
            }


            .carousel-img {
                width: 40%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;
                height: 45vh;
                max-height: 240px;
                // border-radius: 8px;

                iframe {
                    display: flex;
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;
                }

                img {
                    height: 40vh;
                }
            }
        }
    }
}

@media (min-width:1440px) {
    .background-container {
        .site-card-border-less-wrapper {
            @include main-body-container-1440;
            .ant-card-head-title {
                font-size: larger;
            }

            .ant-card-body {
                p {
                    font-size: large;
                }
            }

            .carousel-video {
                height: 40vh;
                max-height: 280px;
                width: 37%;
            }


            .carousel-img {
                width: 50%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;
                height: 40vh;
                max-height: 280px;




                iframe {
                    display: flex;
                    margin-left: auto;
                    margin-right: auto;
                    width: 95%;
                }
            }
        }
    }
}

@media (min-width:2140px) {
    .background-container {
        .site-card-border-less-wrapper {

            .carousel-video {
                height: 40vh !important;
            }

            .carousel-video,
            .carousel-img {
                // width: 80%;
                // margin-left: auto;
                // margin-right: auto;
                margin-bottom: 15px;
                height: 20vh;

                iframe {
                    // display: flex;
                    // margin-left: auto;
                    // margin-right: auto;
                    width: 100%;
                }
            }
        }
    }
}