@import "../../styles/main.scss";


@media(min-width:320px) {

    .viewMicrositeModal {
        margin-top: 10vh;
    }

    .background-container-terms {
        @include main-body-container-768;
        padding-bottom: 60px;

        .checkbox-condition {
            @include airbnbCereal-book;
            font-size: 14px;
        }

        .submitbtn {
            button {
                @include airbnbCereal-book;
                display: flex;
                align-items: center;
                font-size: 14px;
            }
        }

        .container {
            .apply-header {
                h2 {
                    @include airbnbCereal-book;
                    font-size: 20px;
                }
            }
        }
    }

    .approve-container {
        .review-title {
            @include airbnbCereal-book;
        }

        .rowBody {
            div {
                @include airbnbCereal-book;
                font-size: 14px;
            }
        }
    }

    .submitbtnend {
        margin-top: 10px;
        button{
            @include airbnbCereal-book;
            display: flex;
            align-items: center;
            font-size: 14px;
        }
    }

    .signup-confirm-modal{
        margin-top: 20vh;
        .signup-text{
            @include airbnbCereal-bold;
            font-size: 14px;
        }
    }
 
}


@media(min-width:768px) {
    .signup-confirm-modal{
        .signup-text{
            padding: 10px;
        }
    }
    .approve-content{
        div{
            margin-left: 4vw !important;
            margin-right: -1vw !important;
            div{
                margin: 10px !important;
            }
        }
    }
}

@media(min-width:1024px) {}

@media(min-width:1366px) {
    .background-container-terms {
        .checkbox-condition {
            font-size: 16px;
        }

        .container {
            .apply-header {
                h2 {
                    font-size: 25px;
                }
            }
        }
    }

    .submitbtn {
        button {
            font-size: 16px;
        }
    }

    .approve-container {
        .review-title {
            @include airbnbCereal-book;
        }

        .rowBody {
            div {
                @include airbnbCereal-book;
                font-size: 16px;
            }
        }
    }

    .submitbtnend {
        button{
            font-size: 16px;
        }
    }

    .signup-confirm-modal{
        .signup-text{
            font-size: 18px;
            padding: 30px;
            letter-spacing: 0.5px;
        }
    }

    .approve-content{
        div{
            margin-left: 4vw !important;
        }
    }

}


@media(min-width:1920px) {
    .background-container-terms {
        .checkbox-condition {
            font-size: 18px;
        }

        .submitbtn {
            button {
                font-size: 18px;
                padding: 20px;
            }
        }
    }

    .approve-container {
        .rowBody {
            div {
                font-size: 18px;
            }
        }
    }

    .submitbtnend {
        button{
            padding: 20px;
            font-size: 18px;
        }
    }

    .approve-content{
        div{
            margin-left: 2vw !important;
        }
    }
}