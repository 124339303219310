@import "./variables";
@import "./colors";
@import "./fonts";

@mixin button($bg-color, $text-color, $border-radius: $base-border-radius-400, $padding: null) {
    background-color: $bg-color;
    color: $text-color;
    border: none;
    border-radius: $border-radius;

    // Use the provided padding or a default value if not set
    padding: if($padding == null, 10px 15px, $padding);

    font-family: "AirbnbCereal-Bold"; // Font family
    font-size: $base-font-size; // Font size

    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($bg-color, 10%);
    }
}

.button-red-primary-1920 {
    @include button($red-primary, $white-primary, $base-border-radius-1920, null);
    @include airbnbCereal-book;
    font-size: 19px;
    letter-spacing: 1.9px;
    line-height: 25px;
}

.button-secondary-red-1920 {
    @include button($red-primary, $white-primary, $base-border-radius-1920, null);
    @include airbnbCereal-book;
    font-size: 18px;
    letter-spacing: 1.8px;
    line-height: 23px;
}
