.videoOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.video-preview-image {
    width: 100%;
    object-fit: cover;
}

.preview-img {
    width: 100%;
    object-fit: cover;
}
.height-auto-important {
    height: auto !important;
}
.card-container {
    padding: 10px;
}
