@import "../../styles/common.scss";

@media (min-width: 320px) {
    .title-bar-wrapper {
        margin: 0px 10px;
        height: 45px;
        .back-button {
            display: none;
        }
        .microsite-logo-container {
            display: none;
        }
        .title {
            @include airbnbCereal-bold;
            font-size: 15px;
            letter-spacing: 1.6px;
            line-height: 26px;
            color: #000000;
            padding-left: 7px;
        }
        .title-bar-search-wrapper {
            width: 200px;
            .ant-input-affix-wrapper {
                box-shadow: 0px 1px 2px #c0cee24f;
                border: 1px solid #7e97b8;
                border-radius: $base-border-radius-400;
            }
            input {
                height: 22px;
                font-size: 16px;
            }
            input::placeholder {
                font-size: 16px;
                line-height: 21px;
                letter-spacing: 1.6px;
                color: #abb4bf;
            }
        }
    }
}
@media (min-width: 768px) {
    .title-bar-wrapper {
        margin: 15px 30px;
        height: 55px;
        position: relative;
        .back-button {
            cursor: pointer;
            padding: 0 15px 0 0;
            display: flex;
            align-items: center;
        }
        .title {
            font-size: 16px;
            padding: 0;
            margin: 0;
        }
        .microsite-logo-container {
            display: inline-block;
            position: absolute;
            // max-width: 40px;
            height: 90%;
            max-height: 50px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .title-bar-search-wrapper {
            min-width: 300px;
            width: 40%;
            max-width: 400px;
            display: inline-flex;
            align-items: center;
            border-radius: $base-border-radius-1920;

            input {
                @include airbnbCereal-book;
                height: 20px;
                font-size: 14px;
                display: inline-flex;
                align-items: center;
            }
            input::placeholder {
                @include airbnbCereal-book;

                font-size: 14px;
                line-height: 23px;
                letter-spacing: 1.44px;
                color: #7e7e7e;
                display: inline-flex;
                align-items: center;
            }
            .anticon-search {
                font-size: 15px;
            }
        }
    }
}
@media (min-width: 1024px) {
    .title-bar-wrapper {
        .title {
            font-size: 17px;
        }
        .microsite-logo-container {
            max-height: 60px;
        }
        .title-bar-search-wrapper {
            width: 30%;
            input {
                height: 25px;
                font-size: 15px;
            }
            input::placeholder {
                font-size: 15px;
            }
            .anticon-search {
                font-size: 16px;
            }
        }
    }
}
@media (min-width: 1366px) {
    .title-bar-wrapper {
        .title-bar-search-wrapper {
            width: 25%;
            input {
                height: 30px;
                font-size: 16px;
            }
            input::placeholder {
                font-size: 16px;
            }
        }
        .microsite-logo-container {
            max-height: 70px;
        }
    }
}
@media (min-width: 1440px) {
    .title-bar-wrapper {
        .title {
            font-size: 19px;
        }
        .microsite-logo-container {
            max-height: 75px;
        }
        .title-bar-search-wrapper {
            input {
                height: 30px;
                font-size: 18px;
            }
            input::placeholder {
                font-size: 18px;
            }
        }
    }
}
@media (min-width: 1920px) {
    .title-bar-wrapper {
        .title-bar-search-wrapper {
            input {
                height: 40px;
                font-size: 18px;
            }
            input::placeholder {
                font-size: 18px;
            }
            .anticon-search {
                font-size: 18px;
            }
        }
        .microsite-logo-container {
            // width: 50px;
            height: 90%;
            max-height: 80px;
        }
    }
}
