@import "../../styles/common.scss";

.position-relative {
    position: relative;
}

.profile-options {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    overflow: hidden;
    // width: 150px;
    z-index: 1000;
}

.profile-option {
    @include airbnbCereal-book;
    cursor: pointer;
    color: #3067ae;
    font-weight: 500;
    letter-spacing: 1px;
    transition: background-color 0.3s;
}

.profile-option:hover {
    background-color: #f5f5f5;
}

.navbar-desktop {
    .partner-info {
        height: 100%;
        .partner-with-logo {
            display: flex;
            height: 100%;
            width: 15%;
            align-items: center;
            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}

.navbar-desktop {
    .partner-info {
        height: 100%;
        .partner-with-logo {
            display: flex;
            height: 100%;
            width: 15%;
            align-items: center;
            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}

@media (min-width: 320px) {
    .navbar-mobile {
        position: relative;
        z-index: 999;
        height: $nav-height-320;
        min-height: $nav-height-320;
        top: 0;
        left: 0;
        padding: 0 1vh;
        display: flex;
        // overflow-x: hidden;
        background-color: $white-primary;

        .nav-partner-menu {
            width: calc(50% - 60px);
            padding-left: 10px;
        }

        .nav-partner-logo {
            max-width: 100px;
            display: inline-flex;
            justify-content: center;
            position: relative;

            img {
                max-width: 38px;
            }
            .nav-microsite-logo {
                position: absolute;
                max-height: 40px;
                top: 50%;
                left: 50%;
                max-width: unset;
                transform: translate(-50%, -50%);
            }
        }
        .nav-partner-with-logo {
            // max-width: none;
            height: 100%;
            width: 24%;
            display: flex;
            align-items: center;
            img {
                max-width: none;
                width: 100%;
                object-fit: contain;
                height: 82%;
            }
        }

        .navbar-nav {
            width: calc(50% - 60px);

            .nav-item {
                &:first-child {
                    margin: 0 auto;
                }

                .add-organization {
                    width: 94px;
                    height: 25px;
                    box-shadow: 0px 3px 6px $gray-disabled;
                    border: 1px solid $red-secondary;
                    border-radius: 27px;
                    font-size: 9px;
                    font-weight: bold;
                    letter-spacing: 0.72px;
                    color: $red-secondary;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 40px;
                    margin: 0 auto;
                    -webkit-font-smoothing: antialiased;
                    text-rendering: optimizeLegibility;
                    font-synthesis: none;

                    .add-organization-plus {
                        font-size: 17px;
                    }
                }

                &:first-child {
                    margin: 0 auto;
                }
            }

            .nav-link {
                @include nav-links-font-320;
            }
        }
    }

    .fw-100 {
        font-weight: 100;
    }

    .w-55 {
        width: 55%;
    }

    .navbar-brand img {
        max-width: 100%;
        /* Ensures it doesn't exceed the container */
        height: auto;
        /* Keeps the aspect ratio */
        object-fit: contain;
        /* Ensures the image is scaled correctly */
    }

    .user-icon {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid rgba(255, 99, 71, 0.586);
        padding: 1px;
        margin: 4px;
    }

    .profile-options {
        top: 84%;
        right: 10px;
        max-width: 110px !important;
    }

    .profile-option {
        padding: 8px 12px;
        font-size: 12px;
    }
}

@media (min-width: 768px) {
    .navbar-desktop {
        position: sticky;
        z-index: 999;
        height: $nav-height-768;
        min-height: $nav-height-768;
        max-height: 100px;
        top: 0;
        left: 0;
        padding: 0;
        border: 0.5px solid $gray-disabled;
        box-shadow: 0px 3px 4px #7e97b830;
        background-color: $white-primary;
        .partner-info {
            .partner-with-logo {
                width: 35%;
            }
        }
        .navbar-nav {
            .nav-link {
                @include nav-links-font-768;
                padding: 0 5px;
            }

            .nav-item {
                @include nav-links-font-768;

                .onboarding {
                    border: 2px solid $blue;
                    border-radius: 27px;
                    padding: 3px 8px;
                }
                .active.onboarding {
                    border: 2px solid $red-secondary;
                }
            }
        }
        .microsite-brand-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .microsite-profile-wrapper {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: calc(100% - 65px);
            overflow: hidden;
            
        }
        .microsite-back-btn {
            cursor: pointer;
            display: block;
            position: fixed;
            top: 21px;
            left: 2%;
        }
    }

    .navbar-brand img {
        width: 50px;
    }

    .navbar-brand-title {
        @include h1-font-768;
    }

    .profile-options {
        min-width: 110px !important;
        white-space: nowrap;
    }

    .profile-option {
        padding: 10px 15px;
        font-size: 14px;
    }

    .user-icon {
        width: 40px;
        height: 40px;
        padding: 1px;
    }
}

@media (min-width: 1024px) {
    .navbar-desktop {
        max-height: 100px;
        height: $nav-height-1024;
        min-height: $nav-height-1024;
        .partner-info {
            .partner-with-logo {
                width: 30%;
            }
        }

        .navbar-brand img {
            width: 50px;
            margin: 0 10px;
        }

        .nav-item .rounded-pill {
            font-size: 16px;
            font-weight: 400;
        }

        .navbar-nav {
            .nav-link {
                @include nav-links-font-1024;
            }

            .nav-item {
                @include nav-links-font-1024;

                .onboarding {
                    padding: 5px 14px;
                    margin: 0 5px;
                    border-radius: 27px;
                }
            }
        }
        .microsite-back-btn {
            top: 21px;
            left: 3%;
        }
    }

    .navbar-brand-title {
        @include h1-font-1024;
    }

    .profile-options {
        min-width: 110px !important;
        white-space: nowrap;
    }

    .profile-option {
        padding: 10px 15px;
        font-size: 14px;
    }
}

@media (min-width: 1366px) {
    .navbar-desktop {
        max-height: 100px;
        height: $nav-height-1366;
        min-height: $nav-height-1366;
        .partner-info {
            .partner-with-logo {
                width: 22%;
            }
        }
        .navbar-nav {
            max-width: 43.3vw;
        }

        .navbar-nav {
            .nav-link,
            .nav-item {
                @include nav-links-font-1366;
            }
        }
        .microsite-back-btn {
            top: 27px;
            left: 2.5%;
        }
    }

    .navbar-brand-title {
        @include h1-font-1366;
    }

    .profile-options {
        max-width: 150px !important;
    }

    .profile-option {
        padding: 10px 15px;
        font-size: 16px;
    }
}

@media (min-width: 1440px) {
    .navbar-desktop {
        max-height: 100px;
        height: $nav-height-1440;
        min-height: $nav-height-1440;
        .partner-info {
            .partner-with-logo {
                width: 20%;
            }
        }

        .navbar-brand img {
            width: 60px;
            height: 76px;
            margin: 0 10px;
        }

        .navbar-brand-title {
            font-size: 20px;
        }

        .navbar-nav {
            .nav-link,
            .nav-item {
                @include nav-links-font-1440;
            }
        }
        .microsite-back-btn {
            top: 27px;
            left: 2.5%;
        }
    }

    .navbar-brand-title {
        @include h1-font-1440;
    }

    .profile-options {
        width: 150px !important;
    }

    .profile-option {
        padding: 10px 15px;
        font-size: 18px;
    }
}

@media (min-width: 1920px) {
    .navbar-desktop {
        max-height: 100px;
        height: $nav-height-1920;
        min-height: $nav-height-1920;
        .partner-info {
            .partner-with-logo {
                width: 15%;
            }
        }
        .navbar-nav {
            .nav-link {
                @include nav-links-font-1920;
            }

            .nav-item {
                @include nav-links-font-1920;
            }
        }
        .microsite-back-btn {
            top: 25px;
            left: 2%;
        }
    }

    .navbar-brand-title {
        @include h1-font-1920;
    }

    .profile-options {
        max-width: 180px !important;
    }

    .profile-option {
        padding: 10px 15px;
        font-size: 18px;
    }
}
