@import "../../../styles/main.scss";

// .microsite-details-container {
//     margin: 2vh 2vw;
//     max-width: 110vw;
//     // border: 1px solid #67676751;
//     max-height: 83vh;
//     border-radius: 8px;
//     overflow: auto;
//     scrollbar-width: none;
//     box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
// }

// .microsite-details-container::-webkit-scrollbar {
//     display: none;
// }

.microsite-details-container {
    .microsite-details-body-container {
        overflow: unset;
        margin: 10px 20px;
        padding: 20px 20px;
        padding-top: 10px;
        box-shadow: 0px 6px 36px #2e3a4e3a;
        border-radius: 15px;
        flex-grow: 1;
        .micro-detail-wrapper {
            .micro-detail-container {
                flex-direction: row-reverse;
                .media-container {
                    margin-top: 20px;
                }
                display: flex;
                .media-container {
                    flex: 1;
                }

                .info-wrapper {
                    flex: 2;
                    width: 100%;
                    margin-bottom: 40px;
                    .micro-brochure {
                        display: flex;
                        justify-content: space-between;
                        overflow: hidden;
                        margin-left: 70px;

                        .micro-icons {
                            display: flex;
                            gap: 20px;
                            align-items: flex-end;

                            .phone-icon {
                                .call-btn {
                                    width: 80px;
                                    height: 60px;
                                    border-radius: 10px;
                                    img {
                                        width: 70%;
                                        height: 85%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .microsite-title-bar-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            position: relative;
            margin-bottom: 10px;
            min-height: 60px;
            .microsite-back-btn {
                // display: none;
                cursor: pointer;
                z-index: 1000;
            }
            .microsite-bookmark {
                z-index: 1000;
            }
            .microsite-navbar {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                .microsite-brand-title {
                    margin-left: 20px;
                }
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 759px) {
    .microsite-details-container {
        height: calc(100dvh - 75px);
        padding-top: 7px;

        .microsite-details-body-container {
            margin: 0 15px;
            overflow: hidden;
            box-shadow: none;
            padding: 20px 0px;
            .micro-detail-wrapper {
                .micro-detail-container {
                    flex-direction: column;
                    .info-wrapper {
                        .micro-brochure {
                            flex-direction: column;
                            .brochure {
                                margin-bottom: 10px;
                            }
                            .micro-icons {
                                padding-bottom: 20px;
                                margin-bottom: 20px;
                                justify-content: center;
                                .phone-icon {
                                    .call-btn {
                                        width: unset !important;
                                        height: 45px !important;
                                        border-radius: 10px;
                                        img {
                                            width: 70%;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .microsite-title-bar-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                position: relative;
                margin-bottom: 10px;
                min-height: 60px;
                .microsite-back-btn {
                    // display: none;
                    cursor: pointer;
                    z-index: 1000;
                }
                .microsite-navbar {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-height: 60px;
                    .microsite-brand-title {
                        display: none;
                    }
                    .microsite-brand {
                        height: 50px;
                        max-height: 55px;
                        width: auto;
                        display: block;
                        img {
                            max-height: 100%;
                            max-width: 100%;
                            object-fit: contain;
                            height: 100%;
                            width: auto;
                        }
                    }
                }
            }
        }

        .react-pdf__Page {
            div {
                height: auto !important;
            }
        }
    }
}
@media (min-width: 760px) and (max-width: 1023px) {
    .microsite-details-container {
        min-height: calc(100dvh - $footer-height-768);
        height: unset;
        position: relative;
        display: flex;
        flex-direction: column;
        .microsite-details-body-container {
            overflow: unset;
            margin: 10px 20px;
            padding: 20px 20px;
            padding-top: 10px;
            box-shadow: 0px 6px 36px #2e3a4e3a;
            border-radius: 15px;
            flex-grow: 1;

            .micro-detail-wrapper {
                .micro-detail-container {
                    flex-direction: column;
                }
            }

            .microsite-title-bar-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                position: relative;
                margin-bottom: 10px;
                min-height: 60px;
                .microsite-back-btn {
                    // display: none;
                    cursor: pointer;
                    z-index: 1000;
                }
                .microsite-bookmark {
                    z-index: 1000;
                }
                .microsite-navbar {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-height: 60px;
                    display: flex;
                    align-items: center;
                    .microsite-brand-title {
                        display: flex;
                    }
                    .microsite-brand {
                        height: 40px;
                        max-height: 40px;
                        width: auto;
                        display: block;
                        img {
                            max-height: 100%;
                            max-width: 100%;
                            object-fit: contain;
                            height: 100%;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1024px) and (max-width: 1365px) {
    .microsite-details-container {
        min-height: calc(100dvh - $footer-height-1024);
        height: unset;
        position: relative;
        display: flex;
        flex-direction: column;
        .microsite-details-body-container {
            overflow: unset;
            padding-top: 10px;
            box-shadow: 0px 6px 36px #2e3a4e3a;
            border-radius: 15px;
            flex-grow: 1;
            max-width: 955px;
            margin: 20px auto;
            padding: 20px 50px;
            width: 100%;

            .micro-detail-wrapper {
                .micro-detail-container {
                    flex-direction: column;
                }
            }
            .microsite-title-bar-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                position: relative;
                margin-bottom: 10px;
                min-height: 60px;
                .microsite-back-btn {
                    // display: none;
                    cursor: pointer;
                    z-index: 1000;
                }
                .microsite-bookmark {
                    z-index: 1000;
                }
                .microsite-navbar {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-height: 60px;
                    display: flex;
                    align-items: center;
                    .microsite-brand-title {
                        display: flex;
                    }
                    .microsite-brand {
                        height: 40px;
                        max-height: 40px;
                        width: auto;
                        display: block;
                        img {
                            max-height: 100%;
                            max-width: 100%;
                            object-fit: contain;
                            height: 100%;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1366px) and (max-width: 1439px) {
    .microsite-details-container {
        min-height: calc(100dvh - $footer-height-1366);
        height: unset;
        position: relative;
        display: flex;
        flex-direction: column;
        .microsite-details-body-container {
            overflow: unset;
            padding-top: 10px;
            box-shadow: 0px 6px 36px #2e3a4e3a;
            border-radius: 15px;
            flex-grow: 1;
            max-width: 1170px;
            margin: 10px auto;
            padding: 10px 50px;
            width: 100%;
            .micro-detail-wrapper {
                .micro-detail-container {
                    flex-direction: column;
                }
            }
            .microsite-title-bar-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                position: relative;
                margin-bottom: 10px;
                min-height: 60px;
                .microsite-back-btn {
                    // display: none;
                    cursor: pointer;
                    z-index: 1000;
                }
                .microsite-bookmark {
                    z-index: 1000;
                }
                .microsite-navbar {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-height: 60px;
                    display: flex;
                    align-items: center;
                    .microsite-brand-title {
                        display: flex;
                    }
                    .microsite-brand {
                        height: 45px;
                        max-height: 45px;
                        width: auto;
                        display: block;
                        img {
                            max-height: 100%;
                            max-width: 100%;
                            object-fit: contain;
                            height: 100%;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1440px) {
    .microsite-details-container {
        min-height: calc(100dvh - $footer-height-1440);
        height: unset;
        position: relative;
        display: flex;
        flex-direction: column;
        .microsite-details-body-container {
            overflow: unset;
            padding: 20px 20px;
            padding-top: 10px;
            box-shadow: 0px 6px 36px #2e3a4e3a;
            border-radius: 15px;
            flex-grow: 1;
            max-width: 1310px;
            padding: 10px 50px;
            margin: 10px auto;
            width: 100%;
            .microsite-title-bar-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                position: relative;
                margin-bottom: 10px;
                min-height: 60px;
                .microsite-back-btn {
                    // display: none;
                    cursor: pointer;
                    z-index: 1000;
                }
                .microsite-bookmark {
                    z-index: 1000;
                }
                .microsite-navbar {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-height: 60px;
                    display: flex;
                    align-items: center;
                    .microsite-brand-title {
                        display: flex;
                    }
                    .microsite-brand {
                        height: 50px;
                        max-height: 55px;
                        width: auto;
                        display: block;
                        img {
                            max-height: 100%;
                            max-width: 100%;
                            object-fit: contain;
                            height: 100%;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1920px) {
    .microsite-details-container {
        min-height: calc(100dvh - $footer-height-1920);
        height: unset;
        position: relative;
        display: flex;
        flex-direction: column;
        .microsite-details-body-container {
            overflow: unset;
            padding: 10px 50px 30px 50px;
            padding-top: 10px;
            box-shadow: 0px 6px 36px #2e3a4e3a;
            border-radius: 15px;
            flex-grow: 1;
            max-width: 1640px;
            margin: 25px auto;
            .microsite-title-bar-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                position: relative;
                margin-bottom: 10px;
                min-height: 60px;
                .microsite-back-btn {
                    // display: none;
                    cursor: pointer;
                    z-index: 1000;
                }
                .microsite-bookmark {
                    z-index: 1000;
                }
                .microsite-navbar {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-height: 60px;
                    display: flex;
                    align-items: center;
                    .microsite-brand-title {
                        display: flex;
                    }
                    .microsite-brand {
                        height: 50px;
                        max-height: 55px;
                        width: auto;
                        display: block;
                        img {
                            max-height: 100%;
                            max-width: 100%;
                            object-fit: contain;
                            height: 100%;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}
