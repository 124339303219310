@media (min-width:360px) {
    .custom-slider-container {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
        // width: 400px;
        height: 100%;
        // transform: scale(0.8);

        .slick-slider {
            height: 100%;

            .slick-current {
                margin-bottom: 0px;
                padding-bottom: 10px;
            }

            .slick-prev:before,
            .slick-next:before {
                color: gray;
            }

            .slick-list {
                height: 100%;

                .slick-track {
                    height: 100%;
                    // width: 100%  ;
                }
            }
        }

        .slide {
            max-height: 20vh;

            iframe {
                height: 165px;

                // height: 100%;
            }

            .img-slide {
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                // .carousel-image {
                //     max-height: 100%;

                img {
                    height: 165px;
                    // max-width: 100%;
                    border-radius: 8px;
                    // max-height: 370px;
                    //     padding: 10px;
                    flex-shrink: 0;
                    // min-width: 100%;
                    // min-height: 100%;

                    // height: auto;
                    width: 100%;
                    //     min-width: 100%;
                    // // max-height: 100%;
                    // border-radius: 8px;
                    // flex-shrink: 0;
                    // min-height: 100%;
                    // overflow: hidden;

                    object-fit: cover;
                    // object-fit: contain;
                }

                // }
            }
        }

        .slider-img {
            width: auto;
            // max-width: 300px;
            height: auto;
            // min-height: 250px;
            background-color: antiquewhite;
        }

        .slider-label {
            margin-left: auto;
            margin-right: auto;
            font-size: small;
            font-weight: bold;
            color: #000000E0;
        }

        .slick-active {
            align-items: center;
            padding: 20px 0;
            width: 200px;

        }

        .slick-center {
            transform: scale(1.08)
        }

        .slick-slide:not(.slick-active) {
            margin: 20px 0;
            content: "";

            .slider-label {
                display: none;
            }
        }

        .child {
            width: 100%;
        }

        .slide:not(.slick-active) {
            cursor: not-allowed;
        }
    }
}

@media (min-width:760px) {
    .custom-slider-container {
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
        // width: 400px;
        height: 100%;
        // transform: scale(0.8);

        .slick-slider {
            height: 100%;

            .slick-list {
                height: 100%;

                .slick-track {
                    height: 100%;
                }
            }
        }

        .slide {
            display: flex;
            max-height: 30vh;
            justify-items: center;

            iframe {
                height: 250px;
            }

            .img-slide {
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                // .carousel-image {
                //     max-height: 100%;

                img {
                    height: 250px;
                    // max-width: 100%;
                    border-radius: 10px;
                    // max-height: 370px;
                    //     padding: 10px;
                    flex-shrink: 0;
                    min-width: 100%;
                    // min-height: 100%;

                    // height: auto;
                    //     // width: auto;
                    //     min-width: 100%;
                    // // max-height: 100%;
                    // border-radius: 8px;
                    // flex-shrink: 0;
                    // min-height: 100%;
                    // overflow: hidden;

                    object-fit: cover;
                    // object-fit: contain;

                }

                // }
            }
        }

        .slider-img {
            width: auto;
            // max-width: 300px;
            height: auto;
            // min-height: 250px;
            background-color: antiquewhite;
        }

        .slick-active {
            align-items: center;
            padding: 20px 0;
            width: 200px;

        }

        .slider-label {
            font-size: medium;
            color: gray;
            font-weight: bold;
            color: #000000E0;
        }

        .slick-center {
            transform: scale(1.08)
        }

        .slick-slide:not(.slick-active) {
            margin: 20px 0;
        }

        .child {
            width: 100%;
        }

        .slide:not(.slick-active) {
            cursor: pointer;
        }
    }
}

@media (min-width:1024px) {
    .custom-slider-container {
        width: 85%;
        margin-left: auto;
        margin-right: auto;

        .slide {
            iframe {
                // width: 100%;
                height: 250px;
            }

            .img-slide {
                img {
                    height: 250px
                }
            }
        }
    }
}

@media (min-width:1440px) {
    .custom-slider-container {
        width: 90%;

        .slide {
            iframe {
                height: 300px;
            }

            .img-slide {
                img {
                    height: 300px
                }
            }
        }
    }
}