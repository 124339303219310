.map-container {
  height: 100%;
    // height: calc(100vh - 130px);
    // overflow: hidden;

    .marker-pin {
      border: 1px solid red;
     
    }

    

    .marker-tooltip {
      background-color: white !important;
      border: 2px solid red !important;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 0px !important;
        height: 50px;
        width: 60px;
        // width: 2.4dvw;
        // min-height: 4dvh;
        // height: 10dvh;
        // position: relative;
        position: absolute;
        border-radius: 10px;
        &::before {
    display: none !important;
  }

  // Outer red arrow (border effect)
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -11px; // Move it slightly down
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid white;
  }

  // Inner white arrow (fills inside)
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -10px; // Move it slightly up
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white; // White fill
  }
    
      // Tooltip content styles
      .tooltip-content {
           display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            position: absolute;
            height: 100%;
            padding: 3px;
        
        img {
          object-fit: contain;
         
          width: 80%;
          height: 80%;
          // max-width: 80px;
          transform: scale(1.3);
        }
      }
    }
    .leaflet-tooltip::before {
      display: none !important; // Hides Leaflet's default arrow
    }

    
   
  }
  
  @media (min-width: 320px) and (max-width: 759px) {
    .map-container {
      height: 100%;
      // height: calc(100vh - 100px);
      // overflow: hidden;
    }
  }
  
  @media (min-width: 760px) and (max-width:1023px) {
    .map-container {
      // height: calc(100vh - 125px);
      // overflow: hidden;
      height: 100%;
    }
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    .map-container {
      height: 100%;
    }
  }

  .leaflet-container {
    height: 100vh;
  }
  
  .leaflet-div-icon {
    background-color: transparent !important;
    border: none !important;
  }
  
  .leaflet-popup-close-button {
    display: none;
  }
  
  .leaflet-popup-content {
    margin: 0px !important;
  }
  
  .leaflet-popup-content-wrapper {
    border: 2px solid red;
    cursor: pointer;
  }
  
  .leaflet-popup-tip {
    border-right: 2px solid red;
    border-bottom: 2px solid red;
  }
  
  // .popupCustom .leaflet-popup-tip,
  // .popupCustom .leaflet-popup-content-wrapper {
  //   background: #e0e0e0;
  //   color: #234c5e;
  //   border: 2px solid red;
  // }
  .popup-content {
    margin: 13px 5px 13px 5px;
    display: flex;
    justify-content: center;
  }
  
  .ant-layout-content {
    z-index: 1;
  }
  
  .locateBtn {
    background-color: white;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.51);
    z-index: 1000;
    margin-right: 10px !important;
    top: 80px !important;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all !important;
  
    :hover {
      cursor: pointer;
    }
  
    .locateMeImg {
      // transform: scale(15);
      width: 23px;
      height: 23px;
    }
  }
  
  // .leaflet-layer,
  // .leaflet-control-zoom-in,
  // .leaflet-control-zoom-out,
  // .leaflet-control-attribution {
  //   filter: invert(100%) hue-rotate(180deg) brightness(65%) contrast(90%);
  // }
  