@import "../../styles/common.scss";

@media (min-width: 320px) {
    .footer-mobile-layout {
        background-color: white;

        position: fixed;
        width: 100%;
        overflow-x: hidden;
        bottom: 0;
        height: $footer-height-320;
        min-height: $footer-height-320 !important;
        box-shadow: 0px 7px 25px #bfbfbfb5;

        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        padding: 5px;
        box-sizing: border-box;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 15px 15px 0px 0px;

        z-index: 10;

        .footer-links-container {
            width: 100%;
            border: 0.5px solid #7e97b8;
            display: flex;
            justify-content: center;
            border-radius: 14px;
            padding: 10px 15px;
        }
        .footer-image-container {
            width: 55px;
            height: 31px;
        }
    }
    .footer-image {
        max-height: 95%;
        object-fit: contain;
    }
}

@media (min-width: 768px) {
    .footer-desktop-layout {
        height: $footer-height-768;
        min-height: $footer-height-768 !important;
        background-color: white;
        position: relative;
        bottom: 0;
        box-shadow: 0px -3px 4px #7e97b812;
        border: 0.5px solid #bac4d2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        .footer-image-container {
            padding: 0 12px;

            .footer-image {
                max-width: 80px;
            }
        }
        .footer-links {
            width: 47vw;
            display: flex;
            flex-direction: column;
            // .nav-item {
            //     padding: 0 5px;
            // }
            .nav-link {
                @include footer-links-font-1920;
                font-size: 11.5px;
                letter-spacing: 0.91px;
                line-height: 14px;
            }
        }
    }
}

@media (min-width: 1024px) {
    .footer-desktop-layout {
        height: $footer-height-1024;
        min-height: $footer-height-1024 !important;
        a {
            .footer-image {
                width: 139px;
            }
        }
        .footer-links {
            width: 35vw;
            .nav-link {
                font-size: 11.5px;
                letter-spacing: 0.91px;
                line-height: 14px;
            }
        }
        .footer-image-container {
            padding: 0px 15px;
        }
    }
}

@media (min-width: 1366px) {
    .footer-desktop-layout {
        height: $footer-height-1366;
        min-height: $footer-height-1366 !important;
        .footer-links .nav-link {
            font-size: 13px;
            letter-spacing: 1px;
            line-height: 17px;
        }
    }
}
@media (min-width: 1440px) {
    .footer-desktop-layout {
        height: $footer-height-1440;
        min-height: $footer-height-1440 !important;
        .footer-links .nav-link {
            font-size: 14px;
            letter-spacing: 1.17px;
            line-height: 19px;
        }
    }
}
@media (min-width: 1920px) {
    .footer-desktop-layout {
        height: $footer-height-1920;
        min-height: $footer-height-1920 !important;
        .footer-links .nav-link {
            font-size: 16px;
            letter-spacing: 1.3px;
            line-height: 21px;
        }
    }
}
